import { useContext, useEffect, useState } from "react";
import UserService from "../../../services/UserService";
import { ReactReduxContext, useDispatch } from "react-redux";
import { updateFilerListUsers } from "../../../store/FilterListUsersStore";
import { INITIAL_STATE as INITIAL_STATE_USERS_LIST } from "../../../store/FilterListUsersStore";

const useUsers = () => {
    const [usersYara, setUsersYara] = useState();
    const [usersCarrier, setUsersCarrier] = useState();
    const [openFilter, serOpenFilter] = useState(false);
    const [filters, setFilters] = useState(INITIAL_STATE_USERS_LIST);
    const [load, setLoad] = useState(false);
    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();

    const handleFilterVisibility = () => {
        serOpenFilter(!openFilter);
    }

    async function handleGetUsers(filter) {
        let response;
        if (filter) {
            const statusFilter = []
            if (filter.homologationStatusCheck) statusFilter.push(1);
            if (filter.suspendedStatusCheck) statusFilter.push(2);

            response = await UserService.getUsers(filter.cnpjValue, filter.carrierName, filter.contactName,filter.contactEmail, statusFilter)
        } else {
            response = await UserService.getUsers();
        }

        return response.data;
    }

    async function handleDataMap() {
        const fil = store.getState().listUsersFilter;

        const data = await handleGetUsers(fil);
        const dataCarrier = data.filter(el => el.profile.profileId === 3 && el.userActive === true);
        const dataYara = data.filter(el => el.profile.profileId !== 3 && el.userActive === true);

        setFilters(fil)

        setUsersCarrier(dataCarrier.map((el, index) => {
            return {
                ...el,
                key: index,
                carrierCNPJ: el.carriers[0].carrierCNPJ,
                carrierName: el.carriers[0].carrierName,
                carriers: el.carriers,
                status: el.carriers[0].homologation.homologationStatus.homologationStatusId
            }
        }));

        setUsersYara(dataYara.map((el, index) => {
            return {
                ...el,
                key: index,
                profileName: el.profile.profileName
            }
        }))
    }

    useEffect(async () => {
        await handleDataMap();
    }, [])

    const handleCNPJOnChange = (e) => {
        setFilters({ ...filters, cnpjValue: e.target.value })
    }

    const handleCarrierNameOnChange = (e) => {
        setFilters({ ...filters, carrierName: e.target.value })
    }
    const handleContactNameOnChange = (e) => {
        setFilters({ ...filters, contactName: e.target.value })
    }
    const handleContactEmailOnChange = (e) => {
        setFilters({ ...filters, contactEmail: e.target.value })
    }
    const handleHomologationOnchangeFilter = () => {
        setFilters({ ...filters, homologationStatusCheck: !filters.homologationStatusCheck })
    }

    const handleSuspendedOnchangeFilter = () => {
        setFilters({ ...filters, suspendedStatusCheck: !filters.suspendedStatusCheck })
    }


    const handleApplyFilter = async () => {
        setLoad(true)
        dispatch(updateFilerListUsers({ ...filters, filtred: true }))
        await handleDataMap().finally(() => {
            setLoad(false)
        })
    }

    const handleCleanFilter = async () => {
        setLoad(true)
        setFilters(INITIAL_STATE_USERS_LIST);
        dispatch(updateFilerListUsers(INITIAL_STATE_USERS_LIST))
        await handleDataMap().finally(() => {
            setLoad(false)
        })
    }


    return {
        usersYara,
        usersCarrier,
        openFilter,
        filters,
        load,
        handleContactEmailOnChange,
        handleContactNameOnChange,
        handleDataMap,
        handleFilterVisibility,
        handleCNPJOnChange,
        handleCarrierNameOnChange,
        handleHomologationOnchangeFilter,
        handleSuspendedOnchangeFilter,
        handleApplyFilter,
        handleCleanFilter
    }

}

export default useUsers;