import axios from "../axiosInstance";

class UserService {
  constructor() {}

  async getUsers(cnpj, name, contactName, email, status) {
    return await axios.get(
      `user?${cnpj ? `CNPJ=${cnpj}&` : ""}${name ? `CarrierName=${name}&` : ""}${contactName ? `UserName=${contactName}&`: ""}${email ? `UserEmail=${email}&`: ""}${
        status ? status.map((el) => `HomologationStatusIds=${el}&`) : ""
      }`
    );
    }

  async getUsersByCNPJ(cnpj) {
      return await axios.get(`user?OnlyCarrierUsers=true&CNPJ=${cnpj}`);
  }

  async getUsersListByCarrier(idCarrier) {
    return await axios.get(`user/carrier?carrierId=${idCarrier}`);
  }

  async getUserByEmail(userEmail) {
    return await axios.get(`user/${userEmail}`);
  }

  async createUserByCarrier(
    name,
    email,
    phone,
    document,
    profiletypeid,
    carrierid,
    roleid = 1,
    profileid = 3
  ) {
    return await axios.post(
      `user/contact?${name ? `name=${name}&` : ""}${
        email ? `email=${email}&` : ""
      }${phone ? `phone=${phone}&` : ""}${
        document ? `document=${document}&` : ""
      }${profileid ? `profileid=${profileid}&` : ""}${
        profiletypeid ? `profiletypeid=${profiletypeid}&` : ""
      }${roleid ? `roleid=${roleid}&` : ""}${
        carrierid ? `carrierid=${carrierid}` : ""
      }`
    );
  }

  async updateUserByCarrier(id, name, email, phone, document) {
    return await axios.put(
      `user/contact?${id ? `userid=${id}&` : ""}${name ? `name=${name}&` : ""}${
        email ? `email=${email}&` : ""
      }${phone ? `phone=${phone}&` : ""}${
        document ? `document=${document}` : ""
      }`
    );
  }

  async deleteUserByCarrier(userId, carrierId, profileTypeId) {
    return await axios.delete(
      `user/contact?userid=${userId}&carrierid=${carrierId}&profiletypeid=${profileTypeId}`
    );
  }

  async updateUserActiveStatus(userId, status) {
    return axios.put(`user/status?userid=${userId}&status=${status}`);
  }

  async addNewYaraUser(name, email, profileId) {
    return axios.post(
      `user?name=${name}&email=${email}&profileid=${profileId}`
    );
  }

  async updateUser(name, email, userId, profileid) {
    return axios.put(
      `user?name=${name}&email=${email}&userid=${userId}${
        profileid ? `&profileid=${profileid}` : ""
      }`
    );
  }

  async inviteUser(userId, carrierId){
    return axios.get(`user/sendinvite?userId=${userId}&carrierId=${carrierId}`)
  }

  async getYaraUserByYaraID(yaraAD){
    return axios.get(`user/userad/${yaraAD}`);
  }
}

export default new UserService();
