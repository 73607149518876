//Action Types
const SELECT_SOLICITATION = "solicitation/SELECT";
// initial state
const INITIAL_STATE = {
  selectedSolicitation: null,
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_SOLICITATION:
      return {
        ...state,
        selectedSolicitation: action.payload,
      };
    default: {
      return state;
    }
  }
};

export const selectSolicitation = (solicitation) => {
  return {
    type: SELECT_SOLICITATION,
    payload: solicitation,
  };
};
