const UPDATE_ERRO_TABLE_DATA = "table/UPDATE_ERRO_TABLE_DATA";
const UPDATE_EMAIL_ERRO = "table/UPDATE_EMAIL_ERRO";

//initial state
export const INITIAL_STATE = {
  tableError: false,
  emailError: false,
};

//Reducer
export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_ERRO_TABLE_DATA:
      return {
        ...state,
        tableError: action.payload,
      };
    case UPDATE_EMAIL_ERRO:
      return {
        ...state,
        emailError: action.payload,
      };
    default:
      return state;
  }
}

export const updateTableError = (tableError) => {
  return {
    type: UPDATE_ERRO_TABLE_DATA,
    payload: tableError,
  };
};

export const updateEmailError = (emailError) => {
    return {
      type: UPDATE_EMAIL_ERRO,
      payload: emailError,
    };
  };
