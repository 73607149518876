import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import TablePaginationActions from "./TablePaginationActions";
import { TablePagination as MUITablePagination } from "@mui/material";
import { updateManagementPage } from "../../../store/ManagementPageStore";

const TablePagination = props => {
  const {
    allItems,
    setPageItems,
    optionsRowsPerPage,
    initialPageState: {
      pathname: storedPathname,
      pagination: { initialPage = 0, initialRowsPerPage = 10 }
    },
    updatePageState
  } = props;
  const shouldLoadState = storedPathname === window.location.pathname;

  const [page, setPage] = useState(shouldLoadState ? initialPage : 0);
  const [rowsPerPage, setRowsPerPage] = useState(shouldLoadState ? initialRowsPerPage : 10);
  const hasSetup = useRef(false);

  useEffect(() => {
    let pageItems = [];

    const skip = page * rowsPerPage;

    for (let i = skip; i < skip + rowsPerPage; i++) if (allItems[i]) pageItems.push(allItems[i]);

    setPageItems(pageItems);
    updatePageState({ pagination: { initialPage: page, initialRowsPerPage: rowsPerPage } });
  }, [allItems, page, rowsPerPage, setPageItems, updatePageState]);

  useEffect(() => {
    if (hasSetup.current) {
      setPage(0);
    } else if (allItems.length > 0) hasSetup.current = true;
  }, [allItems.length]);

  return (
    <MUITablePagination
      rowsPerPageOptions={optionsRowsPerPage}
      component="div"
      count={allItems.length}
      rowsPerPage={rowsPerPage}
      page={allItems.length > rowsPerPage ? page : 0}
      onPageChange={(_, newPage) => setPage(newPage)}
      onChangeRowsPerPage={e => {
        setRowsPerPage(e.target.value);
        setPage(0);
      }}
      ActionsComponent={TablePaginationActions}
      labelRowsPerPage="Itens por página:"
    />
  );
};

const mapStateToProps = state => {
  return {
    initialPageState: state.managementPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePageState: data => dispatch(updateManagementPage(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TablePagination);
