import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = props => {
  const { children, showHeader, pathRedirect, formType, user, keepData, headerTitle } = props;
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);
  const [openDrawer, setOpenDrawer] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 769;

  function handleDrawerOpenMobile(){
    setOpenDrawer(true);
  }

  return (
    <Grid>
      {showHeader && (
        <>
          <Sidebar onMobile={isMobile} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}/>
          <Header
            pathRedirect={pathRedirect}
            formType={formType}
            user={user}
            keepData={keepData}
            headerTitle={headerTitle}
            onMobile={isMobile}
            handleDrawerOpenMobile={handleDrawerOpenMobile}
            openDrawer={openDrawer}
          />
          <Box className={classes.headerArea} />
        </>
      )}
      {children}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  headerArea: theme.mixins.toolbar
}));

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(Layout);
