import React, { useState, useEffect } from 'react';
import Modal from '../../../shared/components/Modal';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import styled from "@emotion/styled";
import { css } from "@emotion/react"
import CloseIcon from "@mui/icons-material/Close";
import { Send } from "react-feather";
import { PRIMARY_COLOR, WHITE, SECONDARY_COLOR } from "../../../theme";

const UserInvite = ({ visible, user, onClose, handleInvite }) => {
  const classes = useStyles();


  return (
    <Dialog
      open={visible}
      onClose={onClose}
      classes={{ paper: classes.customDialog }}
      PaperProps={{ style: { minHeight: "330px" } }}
    >
      <Grid className={classes.customDialogTitleBar}>
        <DialogTitle className={classes.customDialogTitle}>
          Enviar convite
        </DialogTitle>
        <CloseIcon onClick={onClose} className={`${classes.customDialogTitleCloseIcon} mr-6`} />
      </Grid>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color={PRIMARY_COLOR}>CNPJ</Typography>
            <Typography variant="body1">{user?.carrierCNPJ}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color={PRIMARY_COLOR}>Transportadora</Typography>
            <Typography variant="body1">{user?.carrierName}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={css`margin-top: 15px;`}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color={PRIMARY_COLOR}>Usuário</Typography>
            <Typography variant="body1">{user?.userName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color={PRIMARY_COLOR}>E-mail</Typography>
            <Typography variant="body1">{user?.userEmail}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={css`margin-top: 15px;`}>
          <Grid item xs={4}>
            <UserInviteButtonInvite
              onClick={handleInvite}
            >
              <Send size={15} color={SECONDARY_COLOR} />
              <Typography>Enviar Convite</Typography>
            </UserInviteButtonInvite>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(_ => ({
  customDialogTitle: {
    color: WHITE,
    "& .MuiTypography-h6": {
      color: WHITE
    }
  },
  customDialogTitleBar: {
    backgroundColor: PRIMARY_COLOR,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  customDialogTitleCloseIcon: {
    cursor: "pointer",
    color: WHITE
  },
  customDialog: {
    width: 750,
    minWidth: 750,
    height: 200,
    minHeight: 200,
    overflowY: "auto"
  }
}));


const UserInviteButtonInvite = styled.button`
    font-size: 11px;
    border: 1.5px solid ${SECONDARY_COLOR};
    background-color: transparent;
    color: ${SECONDARY_COLOR};
    border-radius: 4px;
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    & .MuiSvgIcon-root {
        padding-right: 10px;
    }
    &:hover{
        background-color: ${PRIMARY_COLOR};
        color: white;
        cursor: pointer;
        border: none;
    }
    &:hover .MuiTypography-root {
        color: white
    }
    & .MuiTypography-root {
        font-size: 13px;
        color: ${SECONDARY_COLOR}
    }
    & svg {
        margin-right: 10px;
        color: ${SECONDARY_COLOR}
    }
`

export default UserInvite;
