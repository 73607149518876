import { useEffect, useState } from "react";
import PlantService from "../../../services/PlantService";
import CarrierService from "../../../services/CarrierService";

const INITIAL_NEWCARRIER = {
    carrierCNPJ: '',
    carrierName: '',
    plant: '0',
    contactName: '',
    contactEmail: ''
}
const useNewCarrier = (handleCarrierDetailsRedirect) => {
    const [openModalNewCarrier, setOpenModalNewCarrier] = useState(false);
    const [plantsOptions, setPlantOptions] = useState();
    const [newCarrierObj, setNewCarrierObj] = useState(INITIAL_NEWCARRIER);

    const handleOpenAndCloseModalNewCarrier = (status) => {
        setOpenModalNewCarrier(status);
        setNewCarrierObj(INITIAL_NEWCARRIER);
    }

    const handleOnChangeCNPJ = (event) => {
        setNewCarrierObj({ ...newCarrierObj, carrierCNPJ: event.target.value });
    }

    const handleOnChangeCarrierName = (event) => {
        setNewCarrierObj({ ...newCarrierObj, carrierName: event.target.value });
    }

    const handleOnChangePlant = (event) => {
        setNewCarrierObj({ ...newCarrierObj, plant: event.target.value });
    }

    const handleOnChangeContactName = (event) => {
        setNewCarrierObj({ ...newCarrierObj, contactName: event.target.value });
    }

    const handleOnChangeContactEmail = (event) => {
        setNewCarrierObj({ ...newCarrierObj, contactEmail: event.target.value });
    }

    useEffect(() => {
        async function getPlants() {
            await PlantService.getPlantList().then(resp => {
                setPlantOptions(resp?.data)
            });
        }

        getPlants();
    }, []);

    const handleCancelNewCarrier = () =>{
        setOpenModalNewCarrier(false);
        setNewCarrierObj(INITIAL_NEWCARRIER);
    }

    const handleAddNewCarrier = async () =>{
        CarrierService.addCarrier(newCarrierObj).then(resp =>{
            handleCarrierDetailsRedirect(resp?.data);
        })
    }

    const handleBranchInfos = (parent) =>{
        setNewCarrierObj({
            ...newCarrierObj,
            carrierName: parent.carrierName,
            plant: parent.plant ? parent.plant : '0' 
        })
    }

    return {
        openModalNewCarrier,
        plantsOptions,
        newCarrierObj,
        handleBranchInfos,
        handleAddNewCarrier,
        handleCancelNewCarrier,
        handleOnChangeCNPJ,
        handleOnChangeCarrierName,
        handleOnChangePlant,
        handleOnChangeContactEmail,
        handleOnChangeContactName,
        handleOpenAndCloseModalNewCarrier
    }
}

export default useNewCarrier;

