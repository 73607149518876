import { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import SolicitationService from "../../../services/SolicitationService";
import { saveAs } from 'file-saver';
import AnttStatusService from "../../../services/AnttStatusService";

const useSolicitationReview = (apiNotification, navigate) => {
    // const { store } = useContext(ReactReduxContext)
    const selectedSolicitation = useSelector(state => state.solicitation.selectedSolicitation);
    const [solicitationItens, setSolicitationItens] = useState([]);
    const [note, setNote] = useState('');
    const [loadConfirm, setLoadConfirm] = useState(false);

    const handleDownload = async (id, fileName) => {
        await SolicitationService.downloadFileItem(id).then(resp => {
            const base64Response = resp.data.fileContents;
            const binary = atob(base64Response.replace(/\s/g, ''));
            const len = binary.length;
            const buffer = new ArrayBuffer(len);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }
            const blob = new Blob([view], { type: 'application/octet-stream' });
            saveAs(blob, fileName);

        })
    }

    const handleOnchangeItemStatus = (item, status) => {
        const findItem = solicitationItens.find(el => el.solicitationItemId === item.solicitationItemId);
        if (!findItem) {
            setSolicitationItens([...solicitationItens, {
                solicitationItemId: item.solicitationItemId,
                status: status
            }])
        } else {
            setSolicitationItens([...solicitationItens.map(el => {
                if (el.solicitationItemId === item.solicitationItemId) {
                    return {
                        ...el,
                        status: status
                    }
                }
                return el;
            })])
        }

    }

    const handleOnChangeTextarea = (event) => {
        setNote(event.target.value)
    }

    const checkDisabledConfirmButton = () => {
        if (solicitationItens.length === selectedSolicitation.itens.length) {
            if (solicitationItens.find(el => el.status === 2)) {
                if (note.length > 0) {
                    return false
                }
                return true
            }
            return false;
        }

        return true;
    }

    const handleOnClickConfirmButton = async () => {
        await SolicitationService.completeSolicitation(selectedSolicitation.id, note, solicitationItens).finally(() => {
            setLoadConfirm(true);
            apiNotification['success']({
                message: 'Solicitação Finalizada com sucesso',
                description: 'você esta sendo redirecionado.',
                placement: 'topRight',
                duration: 5
            })

            setTimeout(() => {
                setLoadConfirm(false)
                navigate(-1);
            }, 3000);
        })
    }

    const handleCancel = async () => {
        await SolicitationService.yaraCancelSolicitation(selectedSolicitation.id, note).finally(() => {
            setLoadConfirm(true);
            apiNotification['success']({
                message: 'Solicitação Cancelada com sucesso com sucesso',
                description: 'você esta sendo redirecionado.',
                placement: 'topRight',
                duration: 5
            })

            setTimeout(() => {
                setLoadConfirm(false)
                navigate(-1);
            }, 5000);
        })
    }

    return {
        selectedSolicitation,
        note,
        loadConfirm,
        handleCancel,
        handleOnClickConfirmButton,
        checkDisabledConfirmButton,
        handleOnChangeTextarea,
        handleOnchangeItemStatus,
        handleDownload
        
    }
}

export default useSolicitationReview;