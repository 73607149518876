import { useState } from "react";
import UserService from "../../../services/UserService";

const useEditAccess = (apiNotification, handleDataMap) => {
    const [editOpen, setEditOpen] = useState(false);
    const [selectedUserEdit, setSelectedUser] = useState();
    const [editType, setEditType] = useState();

    const handleEditOpen = (open, user, type) => {
        setEditOpen(open);
        if (open) {
            setSelectedUser(user);
            setEditType(type);
        } else {
            setSelectedUser({});
            setEditType(-1);
        }
    }

    const handleEditOnChangeName = (event) => {
        setSelectedUser({ ...selectedUserEdit, userName: event.target.value });
    }
    const handleEditOnChangeEmail = (event) => {
        setSelectedUser({ ...selectedUserEdit, userEmail: event.target.value });
    }

    const handleOnSelectAccessType = (event) => {
        setSelectedUser({
            ...selectedUserEdit, profile: {
                profileId: event.value
            }
        });
    }

    const handleOnConfirmEdit = async () => {
        try {
            const usersdata = await UserService.getUsers();
            //verify if exists this new email
            let count = 0
            usersdata.data.forEach(user =>{
                if(user.userEmail === selectedUserEdit.userEmail && user.userName === selectedUserEdit.userName  && user.id !== selectedUserEdit.id){
                    throw 'Este e-mail ou nome ja esta em uso!'
                }
            });

            await UserService.updateUser(selectedUserEdit.userName, selectedUserEdit.userEmail, selectedUserEdit.id, selectedUserEdit.profile.profileId).then(async (response) => {
                if (response === undefined) throw 'usuario não encontrado ou email invalido';

                handleEditOpen(false);
                await handleDataMap();
                apiNotification['success']({
                    message: 'Edição concluida com sucesso.',
                    placement: 'topRight',
                    duration: 3
                })
            });
        } catch (err) {
            apiNotification['error']({
                message: 'Erro ao editar usuario',
                description: err,
                placement: 'topRight',
                duration: 5
            })
        }
    }

    const handleRemoveAccess = async () => {
        await UserService.updateUserActiveStatus(selectedUserEdit.id, false).then(async (response) => {
            try {
                if(response === undefined) {
                    throw 'Usuario não encontrado'
                }

                handleEditOpen(false);
                await handleDataMap();
                apiNotification['success']({
                    message: 'Acesso removido com sucesso.',
                    placement: 'topRight',
                    duration: 3
                })
            }catch(err){
                console.error(err);
                apiNotification['error']({
                    message: 'Não foi possivel remover o acesso do usuario.',
                    description: err,
                    placement: 'topRight',
                    duration: 5
                })
            }
            
        });
    }

    return {
        editOpen,
        selectedUserEdit,
        editType,
        handleRemoveAccess,
        handleOnConfirmEdit,
        handleOnSelectAccessType,
        handleEditOnChangeEmail,
        handleEditOnChangeName,
        handleEditOpen
    }
}

export default useEditAccess;