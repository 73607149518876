import { Grid, Stack, Table, TableBody, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import { Edit, Info } from "react-feather";
import React from "react";
import {
    DARK_GRAY,
    DARK_WHITE,
    LIGHT_WHITE,
    PRIMARY_COLOR,
    DARK_GREEN,
    SECONDARY_COLOR,
    ORANGE,
    RED
} from "../../../theme";
import {
    formatNumberToCurrencyString,
    getDate,
    handleStatusIddClass
} from "../../../shared/utils/Utils";
import { css } from "@emotion/react"
import styled from "@emotion/styled";
import { AvTimer } from "@mui/icons-material";

const CarrierDataHeader = props => {
    const {
        createTableHeader,
        createTableBody,
        homologationRow,
        pendingIssuesRow,
        serasaRow,
        headerData,
        handleOpenTab,
        editPermition,
        carrierView,
        pendingSolicitations
    } = props;

    return <>
        <Grid item sx={css`background-color: ${DARK_WHITE}; border-radius: 10px;`} className={`mr-1 p-4`} xs={4} sm={4} md={6}>
            <DataHeaderTitleGrid container >
                <Grid item>
                    <Typography variant="subtitle1" sx={css`color: ${DARK_GRAY};`}>
                        HOMOLOGAÇÃO
                    </Typography>
                </Grid>
                {editPermition &&
                    <Grid item>
                        <IconButton aria-label="Editar" size="large">
                            <Edit size={20} color={SECONDARY_COLOR} onClick={() => handleOpenTab(0)} />
                        </IconButton>
                    </Grid>
                }
            </DataHeaderTitleGrid>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={8} md={8}>
                    <Table>
                        <TableHead >
                            {createTableHeader([
                                { data: "Status homologação", wdith: "50%" },
                                { data: "Grupo de Produto", wdith: "50%" }
                            ])}
                        </TableHead>
                        <TableBody >{homologationRow}</TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>{createTableHeader([{ data: "Pendência", width: "" }])}</TableRow>
                        </TableHead>
                        <TableBody>{pendingIssuesRow}</TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={8} md={8}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Score Serasa", width: "50%" },
                                    { data: "Data da consulta Serasa", width: "50%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>{serasaRow}</TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>{createTableHeader([{ data: "Status ANTT", width: "" }])}</TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'statusANTT') ? css`td{color: #FF8228}` : undefined}>{createTableBody([{
                                data:
                                    <Stack spacing={3} direction={'row'}>{headerData.anttStatus ? headerData.anttStatus : '--'}{pendingSolicitations.find(el => el.variable === 'statusANTT') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                    </Stack>, class: "bold"
                            }])}</TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
        <Grid item sx={css`background-color: ${DARK_WHITE}; border-radius: 10px;`} className={`ml-1 p-4`} xs={4} sm={3.9} md={5.9}>
            <DataHeaderTitleGrid container >
                <Grid item>
                    <Typography variant="subtitle1" sx={css`color: ${DARK_GRAY};`}>
                        VALIDADES
                    </Typography>
                </Grid>
                {editPermition &&
                    <Grid item>
                        <IconButton aria-label="Editar" size="large">
                            <Edit size={20} color={SECONDARY_COLOR} onClick={() => handleOpenTab(0)} />
                        </IconButton>

                    </Grid>
                }
            </DataHeaderTitleGrid>
            <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {!carrierView && <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Status IDD", width: "35%" },
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                {createTableBody([
                                    {
                                        data: headerData.iddStatus,
                                        class: handleStatusIddClass(headerData.iddStatus)
                                    },
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                }
                <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Validade SASSMAQ", width: "" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'sassmaqValidity') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>{getDate(headerData.sassmaqValidity)}{pendingSolicitations.find(el => el.variable === 'sassmaqValidity') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>, class: "bold"
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Validade P.A.E.", width: "" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'paeValidity') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {getDate(headerData.paeValidity)}
                                            {pendingSolicitations.find(el => el.variable === 'paeValidity') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>, class: "bold"
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Validade CR Exército", width: "35%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'crExercitoValidity') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {getDate(headerData.crExercitoValidity)}
                                            {pendingSolicitations.find(el => el.variable === 'crExercitoValidity') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>, class: "bold"
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Validade CTF IBAMA", width: "" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'ctfIbamaValidity') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {getDate(headerData.ctfIbamaValidity)}
                                            {pendingSolicitations.find(el => el.variable === 'ctfIbamaValidity') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>, class: "bold"
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Validade LITPP", width: "" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'litppValidity') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {getDate(headerData.litppValidity)}
                                            {pendingSolicitations.find(el => el.variable === 'litppValidity') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>, class: "bold"
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Validade Apólice RCTR-C", width: "35%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'rctrcValidity') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {getDate(headerData.rctrcValidity)}
                                            {pendingSolicitations.find(el => el.variable === 'rctrcValidity') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>, class: "bold"
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Validade Apólice de seguro RC-DA", width: "" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'rcdaValidity') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {getDate(headerData.rcdaValidity)}
                                            {pendingSolicitations.find(el => el.variable === 'rcdaValidity') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>, class: "bold"
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "LMG Apólice RCTR-C", width: "35%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'lmgPolicyRCTRC') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {formatNumberToCurrencyString(headerData.lmgPolicyRCTRC)}
                                            {pendingSolicitations.find(el => el.variable === 'lmgPolicyRCTRC') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>,
                                        class: "bold"
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "LMG Apólice RC-DA", width: "" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'lmgPolicyRCDA') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {formatNumberToCurrencyString(headerData.lmgPolicyRCDA)}
                                            {pendingSolicitations.find(el => el.variable === 'lmgPolicyRCDA') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>,
                                        class: "bold"
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={4} sm={8} md={12} sx={css`border-radius: 10px;background-color: ${LIGHT_WHITE};`} className={` mt-2 mb-2 p-4`}>
            <DataHeaderTitleGrid container >
                <Grid item>
                    <Typography variant="subtitle1" sx={css`color: ${DARK_GRAY};`}>
                        DADOS CADASTRAIS
                    </Typography>
                </Grid>
                {editPermition &&
                    <Grid item>
                        <IconButton aria-label="Editar" size="large">
                            <Edit size={20} color={SECONDARY_COLOR} onClick={() => handleOpenTab(1)} />
                        </IconButton>
                    </Grid>
                }
            </DataHeaderTitleGrid>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={2} sm={4} md={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "CNPJ", width: "20%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createTableBody([
                                    {
                                        data: headerData.carrierCNPJ,
                                        class: css`
                                            text-decoration: underline; 
                                            color: ${PRIMARY_COLOR} !important; 
                                            font-weight: bold;
                                        `
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Razão Social", width: "20%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'companyName') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {headerData.companyName}
                                            {pendingSolicitations.find(el => el.variable === 'companyName') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Contrato Social", width: "20%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'statusSocialContract') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {headerData.statusSocialContract}
                                            {pendingSolicitations.find(el => el.variable === 'statusSocialContract') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "CNAE", width: "" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'cnaeType') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {headerData.cnaeType ? headerData.cnaeType : '--'}
                                            {pendingSolicitations.find(el => el.variable === 'cnaeTypeId') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Número do contrato", width: "20%", info: <Tooltip title={'Ao clicar abre o contrato base de prestação de serviços de transporte da Yara.'}><a href="/ContradoPrestaçãoDeServiçõDeTransporteYara.pdf"><Info size={16} color={SECONDARY_COLOR} /></a></Tooltip> }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createTableBody([
                                    { data: headerData.contractNumber },
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Status de assinatura do contrato", width: "20%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createTableBody([
                                    { data: headerData.statusContractSignature }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "E-mail para envio da NF-e Yara (Material)", width: "" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'emailSendingInvoice') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {headerData.emailSendingInvoice ? headerData.emailSendingInvoice : '--'}
                                            {pendingSolicitations.find(el => el.variable === 'emailSendingInvoice') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={2} sm={4} md={3}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {createTableHeader([
                                        { data: "ECOVADIS", width: "20%" }
                                    ])}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {createTableBody([
                                        { data: headerData.ecovadis?.ecovadisId === 1 ? headerData.ecovadis?.ecovadisName +" / "+ headerData?.ecovadisScore : headerData.ecovadis?.ecovadisName? headerData.ecovadis?.ecovadisName : "--", class: "bold" }
                                    ])}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
            </Grid>
        </Grid>
    </>;
};

const DataHeaderTitleGrid = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export default CarrierDataHeader;
