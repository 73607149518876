import { useEffect, useMemo, useRef, useState } from "react";
import CarrierService from "../../../services/CarrierService";
import { debounce } from "lodash";
import UserService from "../../../services/UserService";
import { notification } from "antd";

const INITIAL_ACCESS_VALUES = {
    accessType: -1,
    carrierCNPJ: [],
    userName: '',
    userEmail: '',
    userId: undefined,
    userAD: []
};

const useNewAccess = (handleDataMap) => {
    const [newAccessOpen, setNewAccessOpen] = useState(false);
    const [newAccess, setNewAccess] = useState(INITIAL_ACCESS_VALUES);
    const [optionsCarriers, setOptionsCarrier] = useState();
    const [fetching, setFetching] = useState(false);
    const [usersOptions, setUsersOptions] = useState();
    const [carrierData, setCarrierData] = useState();
    const [apiNotification, contextHolder] = notification.useNotification();
    const [optionsYaraUsers, setOptionsYaraUsers] = useState();
    const [fetchingYaraID, setFetchingYaraID] = useState(false);
    const [uYara, setUyara] = useState();

    const carrierRef = useRef(0);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            carrierRef.current += 1;
            const fetchId = carrierRef.current;
            setOptionsCarrier([]);
            setFetching(true);
            getCarrierByCNPJ(value).then((newOptions) => {
                if (fetchId !== carrierRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptionsCarrier(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, 800);
    }, [optionsCarriers])

    const yaraUserRef = useRef(0);
    const debounceFetcherYaraID = useMemo(() => {
        const loadOptions = (value) => {
            if (value.length == 7) {
                yaraUserRef.current += 1;
                const fetchId = yaraUserRef.current;
                setOptionsYaraUsers([]);
                setFetchingYaraID(true);
                getYaraUser(value).then((newOptions) => {
                    if (fetchId !== yaraUserRef.current) {
                        // for fetch callback order
                        return;
                    }
                    console.log(newOptions);
                    setOptionsYaraUsers([{ value: newOptions.data.userAnumber, label: newOptions.data.userAnumber }]);
                    setUyara(newOptions.data);
                    setFetchingYaraID(false);
                });
            }
        };
        return debounce(loadOptions, 800);
    }, [optionsCarriers])

    const getYaraUser = (value) => {
        return UserService.getYaraUserByYaraID(value);
    }

    const handleOnChangeYaraAD = (e) => {
        if (e.length > 0) {

            setNewAccess({ ...newAccess, userAD: [e.pop()], userName: uYara.userName, userEmail: uYara.userEmail })
        } else {
            setNewAccess({ ...newAccess, userAD: e.value, userName: uYara.userName, userEmail: uYara.userEmail })
        }
    }

    const handleOpenNewAccess = (arg) => {
        setNewAccessOpen(arg || !newAccessOpen)
        setNewAccess(INITIAL_ACCESS_VALUES);
    };

    const handleAccessType = (e) => {
        setNewAccess({ ...INITIAL_ACCESS_VALUES, accessType: e.target.value });
    }

    async function getCarrierByCNPJ(cnpj) {
        return CarrierService.getCarriersList({ cnpj: cnpj })
            .then((response) => {
                setCarrierData(response.data)
                const mapData = response.data.map((carrier) => ({
                    label: `${carrier.carrierName} - ${carrier.carrierCNPJ.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`,
                    value: carrier.carrierCNPJ,
                }))
                return mapData;
            })
    }

    async function getUsersOptions(argCNPJ) {
        return await UserService.getUsersByCNPJ(argCNPJ).then(response => {
            const mapData = response.data.filter(user => !user.userActive).map(users => ({
                label: `${users.userName} / ${users.userEmail}`,
                value: users.id
            }));

            return mapData;
        });
    }

    useEffect(async () => {
        if (newAccess.carrierCNPJ.length > 0) {
            const mapData = await getUsersOptions(newAccess.carrierCNPJ[0].value);
            setUsersOptions(mapData)
        }
    }, [newAccess])

    const handleOnChangeSelectCarrier = (e) => {
        e.length > 0 ?
            setNewAccess({ ...newAccess, carrierCNPJ: [e.pop()] })
            :
            setNewAccess({ ...newAccess, carrierCNPJ: e })
    }

    const handleUserName = (e) => {
        setNewAccess({ ...newAccess, userName: e.target.value });
    }

    const handleUserEmail = (e) => {
        setNewAccess({ ...newAccess, userEmail: e.target.value });
    }

    const onCancel = () => {
        setNewAccess(INITIAL_ACCESS_VALUES);
        setNewAccessOpen(false);
    }

    const handleOnSelectUserId = (e) => {
        setNewAccess({ ...newAccess, userId: e.value });
    }

    const handleConfirmCarrierAccessType = async () => {
        await UserService.updateUserActiveStatus(newAccess.userId, true).then(async (response) => {
            try {
                await handleDataMap();
                if (response === undefined) {
                    throw 'Usuario não encontrado'
                }

                setNewAccess(INITIAL_ACCESS_VALUES);
                setNewAccessOpen(false);
                apiNotification['success']({
                    message: 'Usuario adicionado com sucesso.',
                    placement: 'topRight',
                    duration: 3
                })
            } catch (err) {
                console.error(err);
                apiNotification['error']({
                    message: 'Não foi possivel criar o usuario.',
                    description: err,
                    placement: 'topRight',
                    duration: 5
                })
            }

        });
    }

    const handleConfirmYara = async () => {
        await UserService.addNewYaraUser(newAccess.userName, newAccess.userEmail, newAccess.accessType).then(async (response) => {
            try {
                await handleDataMap();
                if (response === undefined) {
                    throw 'Email invalido ou Usuario não encontrado'
                }

                setNewAccess(INITIAL_ACCESS_VALUES);
                setNewAccessOpen(false);
                apiNotification['success']({
                    message: 'Usuario adicionado com sucesso.',
                    placement: 'topRight',
                    duration: 3
                })
            } catch (err) {
                console.error(err);
                apiNotification['error']({
                    message: 'Não foi possivel criar o usuario.',
                    description: err,
                    placement: 'topRight',
                    duration: 5
                })
            }

        });
    }

    return {
        newAccessOpen,
        newAccess,
        debounceFetcher,
        fetching,
        optionsCarriers,
        usersOptions,
        contextHolder,
        apiNotification,
        optionsYaraUsers,
        fetchingYaraID,
        debounceFetcherYaraID,
        handleOnChangeYaraAD,
        handleConfirmYara,
        handleConfirmCarrierAccessType,
        handleOnSelectUserId,
        onCancel,
        handleUserEmail,
        handleUserName,
        handleOnChangeSelectCarrier,
        handleOpenNewAccess,
        handleAccessType,
    }
}

export default useNewAccess;