import {
    Grid, TableCell, TableRow, Tooltip, Typography,
    List,
    ListItem
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDate, handleScoreClass, handleHomologationStatus } from "../../../shared/utils/Utils";
import CarrierDataHeader from "./CarrierDataHeader";
import CarrierDataFooter from "./CarrierDataFooter";
import { GRAY, DARK_GRAY, DARK_GREEN } from "../../../theme";
import { css } from "@emotion/react"
import styled from "@emotion/styled";
import { Stack } from "@mui/system";

const CarrierData = ({ data, handleOpenTab, editPermition, contactData, carrierView, pendingSolicitations }) => {
    const [colorSerasa, setColorSerasa] = useState();


    const pendingIssuesLength = data.pendingIssues?.length;

    const createTableHeader = headerList =>
        headerList.map(header => {
            return (
                <TableCell sx={css` 
                padding: 0px 0px 0px 0px; 
                color: ${GRAY}; 
                border-bottom: none; 
                font-size: 14px; 
                @media only screen and (max-width: 600px){
                    font-size: 12px;
                }`} width={header.width}>
                    <Stack direction={'row'} spacing={1}>
                        <div>{header.data}</div>{header.info}
                    </Stack>
                </TableCell>
            );
        });

    const createTableBody = contentList =>
        contentList.map(content => {

            if (content.emailOverflow && content.data) {
                let dataEmailArr;
                let tooltipLable;

                if (content.data.includes(';')) {
                    dataEmailArr = content.data.split(';');
                    dataEmailArr = dataEmailArr.filter((el) => el.length > 0)
                    tooltipLable = dataEmailArr.join('\n');
                } else {
                    dataEmailArr = [content.data]
                    tooltipLable = dataEmailArr[0];
                }

                return (
                    <CarrierDataCell sx={tooltipLable}>
                        <Tooltip title={dataEmailArr.join(';')} enterTouchDelay={0}>
                            {(dataEmailArr[0] + (dataEmailArr.length > 1 ? ` ...(+${(dataEmailArr.length - 1)})` : '')) || "--"}
                        </Tooltip>
                    </CarrierDataCell>
                );
            }

            return (
                <CarrierDataCell sx={content.class}>
                    {content.data ? content.data : "--"}
                </CarrierDataCell>
            );
        });
    const createContactTableBody = contentList =>
        contentList.map(content => {
            const contacts = content.data;

            if (contacts?.length > 0) {

                if (content.emailOverflow) {
                    return (
                        <CarrierDataCellContact sx={contacts.map(el => el.userEmail).join('\n')}>
                            <List>
                                {contacts.map(el => {
                                    return <ListItem>
                                        { el.userEmail ? el.userEmail : '--' }
                                    </ListItem>
                                })}
                            </List>
                        </CarrierDataCellContact>
                    );
                } else if (content.nameOverflow) {
                    return (
                        <CarrierDataCellContact >
                            <List>
                                {contacts.map(el => {
                                    return <ListItem>
                                        { el.userName ? el.userName : '--' }
                                    </ListItem>
                                })}
                            </List>
                        </CarrierDataCellContact>
                    );
                } else if (content.phoneOverflow) {
                    return (
                        <CarrierDataCellContact sx={contacts.map(el => el.informations?.userInformationPhone).join('\n')}>
                            <List>
                                {contacts.map(el => {
                                    return <ListItem>
                                        { el.informations?.userInformationPhone ? el.informations?.userInformationPhone : '--'  }
                                    </ListItem>
                                })}
                            </List>
                        </CarrierDataCellContact>
                    );
                } else if (content.documentOverflow) {
                    return (
                        <CarrierDataCellContact sx={contacts.map(el => el.informations?.userInformationDocument).join('\n')}>
                            {contacts.map(el => {
                                    return <ListItem>
                                        { el.informations?.userInformationDocument ? el.informations?.userInformationDocument : '--'  }
                                    </ListItem>
                                })}
                        </CarrierDataCellContact>
                    );
                }

            }
        });

    const homologationRow = (
        <TableRow>
            <CarrierDataCell >
                {handleHomologationStatus(
                    data.listHomologation?.homologationStatus?.homologationStatusName,
                    data.listHomologation?.homologationUpdatedAt,
                    false
                )}
            </CarrierDataCell>
            <CarrierDataCell >
                {data.productGroup ? (
                    <Typography sx={css`color: ${DARK_GRAY};`}>{data.productGroup?.productGroupName}</Typography>
                ) : (
                    <Typography>--</Typography>
                )}
            </CarrierDataCell>
        </TableRow>
    );

    const pendingIssuesRow = (
        <TableRow>
            <CarrierDataCell >
                {pendingIssuesLength > 0 ? (
                    data.pendingIssues.map(pi => {
                        return (
                            <Typography sx={css`font-weight: 600; color: ${DARK_GRAY};`}>
                                {pi.pendingIssueName}
                            </Typography>
                        );
                    })
                ) : (
                    <Typography sx={css`font-weight: 600; color: ${DARK_GREEN};`}>Sem pendências</Typography>
                )}
            </CarrierDataCell>
        </TableRow>
    );

    useEffect(() => {

        setColorSerasa(handleScoreClass(data.serasaScore?.serasaScoreName))

        // react-hooks/exhaustive-deps
    }, [data.serasaScore?.serasaScoreName]);

    const serasaRow = (
        <TableRow>
            {createTableBody([
                {
                    data: (
                        <>
                            <Typography
                                variant="subtitle1"
                                sx={css`line-height: initial; color: ${colorSerasa};`}
                            >
                                {data.carrierSerasaScore || "--"}
                            </Typography>
                            <Typography variant="body2" sx={css`font-weight: 600; color: ${DARK_GRAY};`}>
                                {data.serasaScore?.serasaScoreName}
                            </Typography>
                        </>
                    )
                },
                { data: getDate(data.consultationSerasa), class: "bold" }
            ])}
        </TableRow>
    );

    const headerData = {
        anttStatus: data.anttStatus?.anttStatusName,
        iddStatus: data.iddStatus?.iddStatusName,
        productGroup: data.productGroup?.productGroupName,
        sassmaqValidity: data.sassmaqValidity,
        paeValidity: data.paeValidity,
        crExercitoValidity: data.crExercitoValidity,
        ctfIbamaValidity: data.ctfIbamaValidity,
        litppValidity: data.litppValidity,
        rctrcValidity: data.rctrcValidity,
        rcdaValidity: data.rcdaValidity,
        lmgPolicyRCTRC: data.lmgPolicyRCTRC,
        lmgPolicyRCDA: data.lmgPolicyRCDA,
        carrierCNPJ: data.carrierCNPJ,
        companyName: data.companyName,
        statusSocialContract: data.statusSocialContract?.statusSocialContractName,
        cnaeType: data.cnaeType?.cnaeTypeName,
        contractNumber: data.contractNumber,
        contractValidity: data.contractValidity,
        statusContractSignature: data.statusContractSignature?.statusContractSignatureName,
        emailSendingInvoice: data.emailSendingInvoice,
        ecovadis: data?.ecovadis,
        ecovadisScore: data?.ecovadisScore
    };

    const footerData = {
        regulatoryContact: data.regulatoryContact,
        contactLegalRepresentative: data.contactLegalRepresentative,
        operationalContact: data.operationalContact,
        regional: { ...data.regional, sapId: data.sapId },
        plant: { ...data.plant },
        bankData: data.bankData
    };

    return (
        <Grid item container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={css`padding: 2% 3%;`}>
            <CarrierDataHeader
                carrierView={carrierView}
                createTableHeader={createTableHeader}
                createTableBody={createTableBody}
                homologationRow={homologationRow}
                pendingIssuesRow={pendingIssuesRow}
                serasaRow={serasaRow}
                headerData={headerData}
                handleOpenTab={handleOpenTab}
                editPermition={editPermition}
                pendingSolicitations={pendingSolicitations}
            />
            <CarrierDataFooter
                createTableHeader={createTableHeader}
                createTableBody={createTableBody}
                footerData={footerData}
                handleOpenTab={handleOpenTab}
                editPermition={editPermition}
                contactData={contactData}
                createContactTableBody={createContactTableBody}
                pendingSolicitations={pendingSolicitations}
            />
        </Grid>
    );
};

const CarrierDataCell = styled(TableCell)`
    padding: 2px 20px 0px 0px;
    color: ${DARK_GRAY};
    border-bottom: none;
    vertical-align: top;
    font-size: 14px;
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`
const CarrierDataCellContact = styled(TableCell)`
    color: ${DARK_GRAY};
    border-bottom: none;
    left: -30px;
    font-size: 14px;
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`

export default CarrierData;
