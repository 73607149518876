//action types
const UPDATE_FILTER_SOLICITATION_LIST = 'filter/UPDATE_FILTER_SOLICITATION_LIST'


//initial state
export const INITIAL_STATE = {
    cnpjValue: '',
    carrierName: '',
    homologationStatusCheck: false,
    suspendedStatusCheck: false,
    inHomologationStatusCheck: false,
    filtred: false
}

//Reducer
export function reducer(state=INITIAL_STATE, action){
    switch(action.type){
        case UPDATE_FILTER_SOLICITATION_LIST:
            return {
            ...state,
            ...action.payload
            };
        default:
            return state;
    }
}

//Action creator
export const updateFilerListSolicitations= (state)=>{
    return{
        type: UPDATE_FILTER_SOLICITATION_LIST,
        payload: state
    }
}