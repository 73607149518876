import CarrierService from "../../services/CarrierService";
import UserService from "../../services/UserService";
import { ContactCarreirProfileType } from "../../shared/utils/enum";

class CarrierDetailsManager {
  async getCarrierDetails(carrierId) {
    const response = await CarrierService.getCarrierDetails(carrierId);
    return response && response.data;
  }

  async setCarrier(carrier) {
    const response = await CarrierService.setCarrier(carrier);
    return response && response.data;
  }

  async setCarrierStatus(carrierId, carrierStatusId, reasonToChange) {
    const response = await CarrierService.setCarrierStatus(
      carrierId,
      carrierStatusId,
      reasonToChange
    );
    return response && response.data;
  }

  async getUsersByCarrier(carrierId) {
    const response = await UserService.getUsersListByCarrier(carrierId);

    return response && response.data;
  }

  async modalUpdateUsersByCarrier(contacts, deletedContacts, carrierId) {
    const newContacts = contacts.filter((el) => el.isNewContact === true);
    const editedContacts = contacts.filter((el) => el.edited === true);
    const deleteContacts = deletedContacts.filter(
      (el) => el.isNewContact !== true
    );
    try {
      await newContacts.forEach(async (element) => {
        await UserService.createUserByCarrier(
          element.userName,
          element.userEmail,
          element.profile.profileType.profileTypeId === ContactCarreirProfileType.REGULATORY
            ? element.phone
            : undefined,
          element.profile.profileType.profileTypeId ===
            ContactCarreirProfileType.REPRESENTATIVE
            ? element.personalDocument
            : undefined,
          element.profile.profileType.profileTypeId,
          carrierId
        );
      });

      await editedContacts.forEach(async (element) => {
        await UserService.updateUserByCarrier(
          element.id,
          element.userName,
          element.userEmail,
          element.profile.profileType.profileTypeId === ContactCarreirProfileType.REGULATORY
            ? element.phone
            : undefined,
          element.profile.profileType.profileTypeId ===
            ContactCarreirProfileType.REPRESENTATIVE
            ? element.personalDocument
            : undefined
        );
      });

      await deleteContacts.forEach(async (element) => {
        await UserService.deleteUserByCarrier(
          element.id,
          carrierId,
          element.profile.profileType.profileTypeId
        );
      });
    } catch (e) {
      throw e;
    }
  }
}

export default new CarrierDetailsManager();
