export const storageKeyToken = "ProjetoBase-token";
export const storageKeyBffToken = "ProjetoBase-bff-token";
export const storageKeyIdSession = "ProjetoBase-idSessao";
export const storageKeyExpiresOn = "ProjetoBase-expiresOn";
export const STOREGE_PROVIDER_TYPE = "Digital-Homologation-AD-Provider";

export const routes = {
  root: "/",
  logIn: "/login",
  exLogIn: "/externalLogin",
  registerPassword: "/passwordRegistration",
  forgetPassword: "/forgetPassword",
  resetPassword: "/resetPassword",
  listCarriers: "/listCarriers",
  carrierDetails: "/carrierDetails",
  requestCarrierEdit: "/requestEditCarrier",
  usersList: "/usersList",
  solicitationsList: "/solicitationsList",
  solicitationReview: "/solicitationReview",
  deniedAccess: "/deniedAccess",
  notFound: "/notFound",
  userNotFound: "/userNotFound"
};

export const BP_TITLE = { en: "Digital Homologation", pt: "Homologação Digital" };

export const MOBILE_WIDTH = 768;

export const FIXED_HEADER_HEIGHT = "64px";
export const FIXED_DRAWER_WIDTH = "90px";
export const SWIPEABLE_DRAWER_WIDTH = "270px";

export const messages = {
  ERROR_MESSAGE_DEFAULT: "Ocorreu um erro ao executar a operação.",
  NO_CARRIERS: "Nenhuma  transportadora encontrada."
};
