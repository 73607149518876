// Types
const UPDATE_USER = "user/UPDATE_USER";
const DELETE_USER = "user/DELETE_USER";
const AUTHENTICATE_USER = "user/AUTHENTICATE_USER";
const UNAUTHENTICATE_USER = "user/UNAUTHENTICATE_USER";
const EXTERNAL_USER = "user/EXTERNAL_USER"

// Reducer
export const INITIAL_STATE = {
  isAuthenticated: false,
  id: null,
  email: null,
  name: null,
  adUser: null,
  profile: null,
  external: false
};

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_USER: {
      return {
        ...state,
        ...action.payload
      };
    }
    case DELETE_USER: {
      return { ...INITIAL_STATE };
    }
    case AUTHENTICATE_USER: {
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true
      };
    }
    case UNAUTHENTICATE_USER: {
      return {
        ...state,
        ...action.payload,
        isAuthenticated: false
      };
    }

    case EXTERNAL_USER:{
      return{
        ...state,
        ...action.payload,
        external: true
      }
    }
    default: {
      return state;
    }
  }
}

// Action creators

export const updateUser = userData => {
  return {
    type: UPDATE_USER,
    payload: userData
  };
};

export const deleteUser = () => {
  return {
    type: DELETE_USER
  };
};

export const authenticateUser = () => {
  return {
    type: AUTHENTICATE_USER
  };
};

export const unauthenticateUser = () => {
  return {
    type: UNAUTHENTICATE_USER
  };
};

export const externalUser= () =>{
  return {
    type: EXTERNAL_USER
  };
}
