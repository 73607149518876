import { Collapse, Table, TableRow, TableCell, Grid, Typography, TableBody } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import {
  DARK_GRAY,
  DARK_GREEN,
  DEEP_WHITE,
  GRAY,
  ORANGE,
  RED,
  SECONDARY_COLOR
} from "../../../theme";
import { getDate, handleStatusIddClass } from "../../../shared/utils/Utils";

const CarrierDetailsCard = props => {
  const { open, details, carrierView } = props;

  const classes = useStyles();

  const createHeader = (header, width, customClass) => {
    return (
      <TableCell width={width} className={customClass}>
        <Typography variant="body1" className={classes.headerCell}>
          {header}
        </Typography>
      </TableCell>
    );
  };

  const createData = (data, textAlign, customClass) => {
    return (
      <TableCell className={customClass}>
        <Typography
          variant="body1"
          align={textAlign}
          className={`${classes.dataCell} ${data.class}`}
        >
          {data.value || "-"}
        </Typography>
      </TableCell>
    );
  };

  const createTable = (header, data, textAlign, tableSpacing, headerWidth) => {
    return (
      <Grid item xs={tableSpacing} className="pr-10">
        <Table size="small">
          {header.map((h, index) => {
            const customClass = index === header.length - 1 ? classes.customTableCell : null;
            return (
              <TableBody>
                <TableRow key={index}>
                  {createHeader(h, headerWidth, customClass)}
                  {createData(data[index], textAlign, customClass)}
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </Grid>
    );
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Grid container className={classes.detailsContainer}>
        {createTable(
          ["CNAE", "Situação", "Status ANTT", "Status IDD"],
          [
            {
              value: details?.cnaeType?.cnaeTypeName || "--",
              class: classes.defaultInformation
            },
            {
              value: details?.carrierStatus?.carrierStatusName || "--",
              class: classes.highlightInformation
            },
            {
              value: details?.anttStatus?.anttStatusName || "--",
              class: classes.highlightInformation
            },
            {
              value: details?.iddStatus?.iddStatusName || "--",
              class: handleStatusIddClass(details?.iddStatus?.iddStatusName, classes)
            }
          ],
          "left",
          5,
          "30%"
        )}
        {createTable(
                    !carrierView
                    ? [
                        "Data da consulta Serasa",
                        "Validade CR Exército",
                        "Validade SASSMAQ",
                        "Validade P.A.E."
                      ]
                    : [
                        "Validade CR Exército",
                        "Validade SASSMAQ",
                        "Validade P.A.E."
                      ],
                      !carrierView
                      ? [
                          { value: getDate(details?.consultationSerasa), class: classes.highlightInformation },
                          { value: getDate(details?.crExercitoValidity), class: classes.highlightInformation },
                          { value: getDate(details?.sassmaqValidity), class: classes.highlightInformation },
                          { value: getDate(details?.paeValidity), class: classes.highlightInformation }
                        ]
                      : [
                          { value: getDate(details?.crExercitoValidity), class: classes.highlightInformation },
                          { value: getDate(details?.sassmaqValidity), class: classes.highlightInformation },
                          { value: getDate(details?.paeValidity), class: classes.highlightInformation }
                        ],
          "right",
          true,
          "70%"
        )}
        {createTable(
          [
            "Validade CTF IBAMA",
            "Validade LITPP",
            "Validade Apólice RCTR-C",
            "Validade Apólice de seguro RC-DA"
          ],
          [
            { value: getDate(details?.ctfIbamaValidity), class: classes.highlightInformation },
            { value: getDate(details?.litppValidity), class: classes.highlightInformation },
            { value: getDate(details?.rctrcValidity), class: classes.highlightInformation },
            { value: getDate(details?.rcdaValidity), class: classes.highlightInformation }
          ],
          "right",
          true,
          "70%"
        )}
      </Grid>
    </Collapse>
  );
};

const useStyles = makeStyles(() => ({
  detailsContainer: {
    backgroundColor: DEEP_WHITE
  },
  customTableCell: {
    borderBottom: "none"
  },
  headerCell: {
    color: GRAY
  },
  dataCell: {
    color: DARK_GRAY
  },
  statusOk: {
    fontWeight: 600,
    color: DARK_GREEN
  },
  inProcessStatus: {
    fontWeight: 600,
    color: SECONDARY_COLOR
  },
  warningStatus: {
    fontWeight: 600,
    color: ORANGE
  },
  errorStatus: {
    fontWeight: 600,
    color: RED
  },
  highlightInformation: {
    fontWeight: 600,
    color: DARK_GRAY
  },
  defaultInformation: {
    color: DARK_GRAY,
    textAlign: "justify"
  }
}));

export default CarrierDetailsCard;
