import { format } from "date-fns";
import { statusIdd, serasaScoreLevels, homologationStatuses } from "./enum";
import { Grid, Typography } from "@mui/material";
import { Check, X } from "react-feather";
import TooltipItem from "../components/TooltipItem";
import {
  SECONDARY_COLOR,
  DARK_GRAY,
  DARK_GREEN,
  RED,
  ORANGE,
} from "../../theme";
import { css } from "@emotion/react";
import { AvTimer } from "@mui/icons-material";

export const getDate = (string) => {
  const dateNotAllowed = [
    "0001-01-01T00:00:00",
    "1900-01-01T00:00:00",
    "9999-12-31T00:00:00",
  ];
  if (!string || string === "" || dateNotAllowed.includes(string)) return "--";
  else return format(new Date(string), "dd/MM/yyyy");
};

export const getHour = (string) => {
  if (!string || string === "") return "--";
  else return format(new Date(string), "HH:mm");
};

export const isValidDateTimeString = (string) => {
  if (!string || string === "") return false;

  // Verifica se a string corresponde a um dos formatos especificados
  const dateFormats = [
    /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/,
    /^\d{2}\/\d{2}\/\d{4}$/,
    /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}$/,
    /^\d{2}-\d{2}-\d{4}$/,
    /^\d{4}-\d{2}-\d{2}$/,
    /^\d{4}\/\d{2}\/\d{2}$/,
  ];

  for (const format of dateFormats) {
    if (format.test(string)) {
      const date = new Date(string);
      return !isNaN(date.getTime());
    }
  }

  return false;
};

export const formatNumberToCurrencyString = (
  number,
  localeCurrency = "pt-BR"
) => {
  if (typeof number !== "number" || isNaN(Number(number))) return "--";

  return `R$${Number(number).toLocaleString(localeCurrency, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

const StatusIIDStyles = {
  highlightInformation: css`
    font-weight: 600;
    color: ${DARK_GRAY} !important;
  `,
  statusOk: css`
    font-weight: 600;
    color: ${DARK_GREEN} !important;
  `,
  inProcessStatus: css`
    font-weight: 600;
    color: ${SECONDARY_COLOR} !important;
  `,
  warningStatus: css`
    font-weight: 600;
    color: ${ORANGE} !important;
  `,
  errorStatus: css`
    font-weight: 600;
    color: ${RED} !important;
  `,
};

export const handleStatusIddClass = (status) => {
  let returnClass = StatusIIDStyles.highlightInformation;
  const warningStatus = [
    statusIdd.awaitingRefreshDecision,
    statusIdd.awaitingIDDQuestionnaireFromBP,
    statusIdd.awaitingFeedbackFromBS,
    statusIdd.underDAReview,
  ];
  const errorStatus = [
    statusIdd.rejected,
    statusIdd.cancelled,
    statusIdd.terminated,
  ];

  if (status === statusIdd.approved) returnClass = StatusIIDStyles.statusOk;
  else if (status === statusIdd.kof) returnClass = StatusIIDStyles.inProcessStatus;
  else if (warningStatus.includes(status)) returnClass = StatusIIDStyles.warningStatus;
  else if (errorStatus.includes(status)) returnClass = StatusIIDStyles.errorStatus;

  return returnClass;
};

export const handleScoreClass = (level) => {
  let lv = level.split(" ");
  switch (lv[1]) {
    case serasaScoreLevels.LOW:
      return DARK_GREEN;
    case serasaScoreLevels.MEDIUM:
      return ORANGE;
    default:
      return RED;
  }
};

export const handleHomologationStatus = (status, createdAt, showTooltip) => {

  const sinceText = `desde ${getDate(createdAt)}`;

  switch (status) {
    case homologationStatuses.HOMOLOGATED:
      return (
        <>
          <Grid container alignItems={"center"} sx={css`font-weight: bold;`}>
            <Check
              size={18}
              color={DARK_GREEN}
              style={css`
                font-weight: bold;
              `}
            />
            <TooltipItem
              disabled={!showTooltip}
              tooltip={status}
              onlyWhenOverflown
            >
              <Typography
                variant="body1"
                sx={css`
                    color: ${DARK_GREEN} !important;
                    font-weight: bold;
                    padding-left: 1px;
                    font-size: 15px;
                  `}
              >
                {status}
              </Typography>
            </TooltipItem>
          </Grid>
          <TooltipItem
            disabled={!showTooltip}
            tooltip={sinceText}
            onlyWhenOverflown
          >
            <Typography
              sx={css`
                  color: ${DARK_GREEN};
                  font-size: 14;
                  padding-left: 6px;
                `}
            >
              {sinceText}
            </Typography>
          </TooltipItem>
        </>
      );

    case homologationStatuses.HOMOLOGATION:
    return (
      <>
        <Grid container alignItems={"center"} sx={css`font-weight: bold;`}>
        <AvTimer sx={css`color:#FF8228;font-size: 20px;`} />
          <TooltipItem
            disabled={!showTooltip}
            tooltip={status}
            onlyWhenOverflown
          >
            <Typography
              variant="body1"
              sx={css`
                  color: #FF8228 !important;
                  font-weight: bold;
                  padding-left: 1px;
                  font-size: 15px;
                `}
            >
              {status}
            </Typography>
          </TooltipItem>
        </Grid>
        <TooltipItem
          disabled={!showTooltip}
          tooltip={sinceText}
          onlyWhenOverflown
        >
          <Typography
            sx={css`
                color: #FF8228;
                font-size: 14;
                padding-left: 6px;
              `}
          >
            {sinceText}
          </Typography>
        </TooltipItem>
      </>
    );
    case homologationStatuses.BLOCKED:
    case homologationStatuses.SUSPENDED:
      return (
        <>
          <Grid container alignItems={"center"}>
            <X
              size={18}
              color={RED}
              style={css`
                font-weight: bold;
              `}
            />
            <TooltipItem
              disabled={!showTooltip}
              tooltip={status}
              onlyWhenOverflown
            >
              <Typography
                variant="body1"
                sx={css`
                    color: ${RED};
                    font-weight: bold;
                    padding-left: 1px;
                    font-size: 15px;
                  `}
              >
                {status}
              </Typography>
            </TooltipItem>
          </Grid>
          <TooltipItem
            disabled={!showTooltip}
            tooltip={sinceText}
            onlyWhenOverflown
          >
            <Typography
              sx={css`
                  color: ${RED};
                  font-size: 14;
                  padding-left: 6px;
                `}
            >
              {sinceText}
            </Typography>
          </TooltipItem>
        </>
      );
    default:
      return (
        <Typography
          variant="body1"
          sx={css`
              color: ${DARK_GRAY};
            `}
        >
          --
        </Typography>
      );
  }
}
