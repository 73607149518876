import React from "react";
import { Lock } from "react-feather";
import { Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { SECONDARY_COLOR } from "../../theme";

const UserNotFound = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6}>
        <Grid item xs={12} align="center" className={"pb-8"}>
          <Lock className={classes.image} />
        </Grid>
        <Grid item xs={12} className="pb-5">
          <Typography variant="h5" align="center">
            Atenção! Acesso não permitido
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" color="secondary">
            Erro ao logar
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  image: {
    height: theme.spacing(16),
    width: theme.spacing(16),
    color: SECONDARY_COLOR
  }
}));

export default UserNotFound;
