import { useEffect, useState } from "react";
import { ContactCarreirProfileType, InputVerifyDataTable } from "../../../shared/utils/enum";
import { phoneValidate } from "../../../shared/components/DataTableAddItem/DataTableAddItem";
import UserService from "../../../services/UserService";
import { configModalConfirm } from "../views/CarrierModalEdit";

const useRegulatoryContact = (contacts, open, apiNotification, modal) => {
  const [regulatoryContactsData, setRegulatoryContactsData] = useState([]);
  const [deletedRegulatoryContact, setDeletedRegulatoryContact] = useState([]);

  useEffect(() => {
    if (contacts && !open)
      setRegulatoryContactsData(
        contacts
          ?.filter(
            el => el.profile.profileType.profileTypeId === ContactCarreirProfileType.REGULATORY
          )
          .map(el => {
            return {
              ...el,
              phone: el.informations?.userInformationPhone
            };
          })
      );
  }, [contacts, open]);

  const handleFormatCelphone = phone => {
    if (phoneValidate.test(phone)) {
      if (phone.length <= 11)
        return phone
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d)/, "($1) $2")
          .replace(/(\d{5})(\d)/, "$1-$2")
          .replace(/(-\d{4})\d+?$/, "$1");

      return phone
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "+$1 $2")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");
    }

    return phone;
  };

  const defaultRegulatoryContactsColumns = [
    {
      title: "Nome",
      dataIndex: "userName",
      width: "30%",
      editable: true
    },
    {
      title: "E-mail",
      dataIndex: "userEmail",
      width: "30%",
      editable: true,
      inputVerify: InputVerifyDataTable.EMAIL
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      width: "30%",
      editable: true,
      inputVerify: InputVerifyDataTable.PHONE,
      render: text => handleFormatCelphone(text)
    }
  ];

  const handleDeleteRegulatoryContact = id => {
    const newData = regulatoryContactsData.filter(item => item.id !== id);
    setDeletedRegulatoryContact([
      ...deletedRegulatoryContact,
      regulatoryContactsData.find(item => item.id === id)
    ]);
    setRegulatoryContactsData(newData);
  };

  const handleAddRegulatoryContact = () => {
    if (regulatoryContactsData.length > 0) {
      const hightId = Math.max(...regulatoryContactsData.map(item => item.id));
      const newData = {
        id: hightId + 1,
        userName: "contato",
        userEmail: "contato@transportadora.com",
        phone: "0000000000",
        isNewContact: true,
        profile: {
          profileType: { profileTypeId: ContactCarreirProfileType.REGULATORY }
        }
      };
      setRegulatoryContactsData([newData, ...regulatoryContactsData]);
    } else {
      const newData = {
        id: 1,
        userName: "contato",
        userEmail: "contato@transportadora.com",
        phone: null,
        isNewContact: true,
        profile: {
          profileType: { profileTypeId: ContactCarreirProfileType.REGULATORY }
        }
      };
      setRegulatoryContactsData([newData]);
    }
  };

  const handleSaveRegulatoryContact = async row => {
    const emailAlreadyExists = regulatoryContactsData.filter(el => el.userEmail === row.userEmail && el.userEmail?.length > 0 && el.id !== row.id);

    if (emailAlreadyExists.length > 0) {
      apiNotification['warning']({
        message: 'Atenção ao alterar!',
        description: "Esse e-mail já está vinculado a outro usuário, gerencie pelo Controle de Acessos.",
        placement: 'topRight',
        duration: 5
      });

      return;
    }

    if (row.userEmail) {
      const response = await UserService.getUserByEmail(row.userEmail);
      if (response?.data !== null && response?.data?.userActive) {
        if (response.data?.id === row?.id && !row.isNewContact && response.data?.id !== null) {
          apiNotification['warning']({
            message: 'Atenção ao alterar!',
            description: "Esse e-mail já está vinculado a outro usuário, gerencie pelo Controle de Acessos.",
            placement: 'topRight',
            duration: 5
          });
        }

        if (response.data?.id !== row?.id && response.data !== null) {
          const confrimModal = await modal.confirm(configModalConfirm);
          if (
            !confrimModal
          ) {
            return;
          }

          row.userName = response.data.userName;
          row.userEmail = response.data.userEmail;
          row.phone = response.data.informations?.userInformationPhone;
        }
      }
    }
    const newData = [...regulatoryContactsData];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      edited: item.isNewContact ? false : true
    });
    setRegulatoryContactsData(newData);
  };

  return {
    defaultRegulatoryContactsColumns,
    regulatoryContactsData,
    deletedRegulatoryContact,
    handleDeleteRegulatoryContact,
    handleAddRegulatoryContact,
    handleSaveRegulatoryContact
  };
};

export default useRegulatoryContact;
