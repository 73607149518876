//action types
const UPDATE_FILTER_USERS_LIST = 'filter/UPDATE_FILTER_USERS_LIST'


//initial state
export const INITIAL_STATE = {
    cnpjValue: '',
    carrierName: '',
    contactName: '',
    contactEmail: '',
    homologationStatusCheck: false,
    suspendedStatusCheck: false,
    filtred: false
}

//Reducer
export function reducer(state=INITIAL_STATE, action){
    switch(action.type){
        case UPDATE_FILTER_USERS_LIST:
            return {
            ...state,
            ...action.payload
            };
        default:
            return state;
    }
}

//Action creator
export const updateFilerListUsers= (state)=>{
    return{
        type: UPDATE_FILTER_USERS_LIST,
        payload: state
    }
}