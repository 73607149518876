//action types
const UPDATE_FILTER_CARRIER_LIST = 'filter/UPDATE_FILTER_CARRIER_LIST'


//initial state
export const INITIAL_STATE = {
    status: [],
    productgroups: [],
    regionals: [],
    towers: [],
    startDay: null,
    endDay: null,
    cnpjText: '',
    checkCnpj: false,
    nameText: '',
    checkName: false,
    filtred: false
}

//Reducer
export function reducer(state=INITIAL_STATE, action){
    switch(action.type){
        case UPDATE_FILTER_CARRIER_LIST:
            return {
            ...state,
            ...action.payload
            };
        default:
            return state;
    }
}

//Action creator
export const updateFilerListCarrier = (state)=>{
    return{
        type: UPDATE_FILTER_CARRIER_LIST,
        payload: state
    }
}