import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { AppBar, Toolbar, Typography, Box, Grid, Divider, Popover, Paper } from "@mui/material";
import { LogOut, HelpCircle, Mail } from "react-feather";
import { GRAY, RED, MID_GRAY, PRIMARY_COLOR, SECONDARY_COLOR, WHITE } from "../../../theme";
import { configureEnvironment } from "../../../config/env";
import { environment } from "../../../shared/utils/enum";
import { BP_TITLE, FIXED_DRAWER_WIDTH, routes } from "../../../shared/utils/Constants";
import { useLogoutContext } from "../../../contexts/LogoutContext";
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unauthenticateUser } from "../../../store/UserStore";

const Header = ({ user, onMobile, handleDrawerOpenMobile, openDrawer }) => {

  const classes = useStyles();

  const logout = useLogoutContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const UATEnvironment =
    configureEnvironment().REACT_APP_ENVIRONMENT_NAME === environment.homologation;
  const DEVEnvironment =
    configureEnvironment().REACT_APP_ENVIRONMENT_NAME === environment.development;

    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [isCopied, setIsCopied] = useState(false);

    const handlePopoverOpen = (event) => {
      setPopoverAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setPopoverAnchorEl(null);
    };

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 3000);
      });
    };
  

  const handleLogout = async () => {
    let external = sessionStorage.getItem('externalUser');
    sessionStorage.clear();
    await dispatch(unauthenticateUser);
    if(!external) logout();
    else navigate(routes.exLogIn);
  };

  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        {onMobile && <IconButton
          color={PRIMARY_COLOR}
          aria-label="open drawer"
          onClick={handleDrawerOpenMobile}
          edge="start"
          sx={{ mr: 2, ...(openDrawer && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>}
        {!onMobile && (
        <Typography align="center" variant="h6" component="h1" className={classes.headerTitle}>
          {BP_TITLE.pt}
        </Typography>
        )}
        <Grid className={classes.alignLeft}>
          <Typography variant="body1" align="right" className={classes.primaryUserData}>
            {user?.name}
          </Typography>
          <Typography variant="body2" align="right" className={classes.secondaryUserData}>
            {`Perfil: ${user?.profile}`}
          </Typography>
        </Grid>
        {!onMobile && (
        <Grid className={`${classes.flexDisplay} ml-5`}>
          <HelpCircle
              color={RED}
              size={25}
              onClick={handlePopoverOpen}
              className={`${classes.help} hover-icon`}
            />
        </Grid>
        )}
        <Grid className={`${classes.flexDisplay} ml-5`}>
          <Divider orientation="vertical" className={classes.divider}></Divider>
          <LogOut
            color={SECONDARY_COLOR}
            size={25}
            onClick={handleLogout}
            className={`${classes.logout} hover-icon`}
          />
        </Grid>
      </Toolbar>
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper style={{ width: 400, height: 120, margin: 15, boxShadow: "none" }}>
        <Grid>
          <Typography>Caso esteja enfrentando problemas por aqui, entre em contato através dos canais abaixo:</Typography>
        </Grid>
        <Grid item container alignItems="center"  style={{ marginTop: 20 }}>
              <Grid item>
                <Mail size={18} color={SECONDARY_COLOR} style={{ marginRight: '8px' }} />
              </Grid>
              <Grid item>
                <Typography color={SECONDARY_COLOR}><a href="mailto:logistica_yara@yara.com">logistica_yara@yara.com</a></Typography>
              </Grid>
              <Grid item>
                <IconButton
                  color={SECONDARY_COLOR}
                  onClick={() => copyToClipboard('logistica_yara@yara.com')}
                  
                >
                  <FileCopyIcon color={SECONDARY_COLOR} style={{ fontSize: 15, color: SECONDARY_COLOR }}/>
                </IconButton>
              </Grid>
            </Grid>
        </Paper>
      </Popover>
      {(UATEnvironment || DEVEnvironment) && (
        <Box className={classes.envBar}>
          <Typography align="center" className={classes.envTitle}>
            {DEVEnvironment && "Ambiente DEV"}
            {UATEnvironment && "Ambiente UAT"}
          </Typography>
        </Box>
      )}
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: WHITE,
    zIndex: 20
  },
  icons: {
    display: "flex",
    margin: "auto"
  },
  headerTitle: {
    color: PRIMARY_COLOR,
    position: "absolute",
    left: `calc(50% - ${FIXED_DRAWER_WIDTH})`
  },
  primaryUserData: {
    color: PRIMARY_COLOR
  },
  secondaryUserData: {
    color: GRAY
  },
  envBar: {
    backgroundColor: RED
  },
  envTitle: {
    color: WHITE,
    fontSize: theme.spacing(1.5)
  },
  help: {
    marginTop: "auto",
    marginBottom: "auto"
  },
  logout: {
    marginTop: "auto",
    marginBottom: "auto"
  },
  divider: {
    height: 46,
    backgroundColor: MID_GRAY,
    marginRight: "20px"
  },
  alignLeft: {
    marginLeft: "auto"
  },
  flexDisplay: {
    display: "flex"
  },
  "MuiPaper-root": {
    borderRadius: 20
  }
}));

export default Header;
