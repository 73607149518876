import axios from "../axiosInstance";
import qs from "qs";

class CarrierService {
  constructor() {
    this.listCarriers = null;
  }
  async getCarriersList(filters) {
    this.listCarriers = await axios.get("carriers", {
      params: filters,
      paramsSerializer: (params) => qs.stringify(params),
    });
    return this.listCarriers;
  }

  async getCarrierDetails(carrierId) {
    return await axios.get(`carriers/${carrierId}`);
  }

  async setCarrier(carrier) {
    return await axios.put(`carriers`, null, {
      params: carrier,
    });
  }

  async setCarrierStatus(carrierId, carrierStatusId, reasonToChange) {
    return await axios.put(`carriers/${carrierId}/status`, null, {
      params: { carrierStatusId, reasonToChange },
    });
  }

  async exportData() {
    return axios.get(`carriers/report`, {
      responseType: "blob",
    });
  }

  async addCarrier(newCarrier) {
    return await axios.post(
      `carriers?CarrierCNPJ=${newCarrier.carrierCNPJ}&CompanyName=${
        newCarrier.carrierName
      }&FreightTowerId=${newCarrier.plant}&SendInvite=${
        newCarrier.contactName && newCarrier.contactEmail ? true : false
      }${
        newCarrier.contactName ? `&ContactName=${newCarrier.contactName}` : ""
      }${
        newCarrier.contactEmail
          ? `&ContactEmail=${newCarrier.contactEmail}`
          : ""
      }`
    );
  }

  async verifyCNPJ(cnpj){
    return await axios.get(`carriers/cnpj/${cnpj}`);
  }

  async getInfosForBranch(cnpj){
    return await axios.get(`carriers/dataforbranch/${cnpj}`);
  }
}

export default new CarrierService();
