import styled from '@emotion/styled';
import { css } from "@emotion/react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../../theme';
import { CheckBoxOutlineBlank, CheckBoxSharp, Close } from '@mui/icons-material';
import { Input, Select, Spin } from 'antd';

const NewAccessModal = ({
    open,
    onClose,
    handleAccessType,
    accesssType,
    debounceFetcher,
    fetching,
    options,
    value,
    handleOnChangeSelectCarrier,
    userName,
    handleName,
    userEmail,
    handleEmail,
    onCancel,
    usersOptions,
    userId,
    handleOnSelectUserId,
    handleConfirmCarrierAccessType,
    handleConfirmYara,
    debounceFetcherYaraID,
    fetchingYaraID,
    optionsYaraID,
    valueYaraID,
    handleOnChangeSelectYaraID
}) => {

    function handleDisabled() {
        if (accesssType !== -1 || value.length > 0 || userName !== '' || userEmail !== '') return false

        return true;
    }

    return (
        <NewAccessDialog open={open}>
            <HeaderDialog>
                <DialogTitle sx={css` color: white;`}>
                    Novo Acesso
                </DialogTitle>
                <Close sx={css`cursor: pointer;color: white;margin-right: 16px;`} onClick={() => onClose(false)} />
            </HeaderDialog>
            <BodyDialog>
                <FormControl component={'fieldset'}>
                    <FormLabel component={'legend'}>Tipo de Acesso</FormLabel>
                    <RadioGroup row aria-label='Access type'>
                        <FormControlLabel
                            value="3"
                            control={<Radio checked={accesssType === '3'} onChange={handleAccessType} checkedIcon={<CheckBoxSharp />} icon={<CheckBoxOutlineBlank />} />}
                            label="Transportadora"
                            labelPlacement="end"
                        />

                        <FormControlLabel
                            value="1"
                            control={<Radio checked={accesssType === '1'} onChange={handleAccessType} checkedIcon={<CheckBoxSharp />} icon={<CheckBoxOutlineBlank />} />}
                            label="Yara - Editor"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="2"
                            control={<Radio checked={accesssType === '2'} onChange={handleAccessType} checkedIcon={<CheckBoxSharp />} icon={<CheckBoxOutlineBlank />} />}
                            label="Yara - Visualizador"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="4"
                            control={<Radio checked={accesssType === '4'} onChange={handleAccessType} checkedIcon={<CheckBoxSharp />} icon={<CheckBoxOutlineBlank />} />}
                            label="Yara - Contratação"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </FormControl><br />
                {accesssType === '3' ?
                    <FormCntrolNewAccess component={'fieldset'} sx={css`display: block;`}>
                        <FormLabel component={'legend'}>CNPJ da Transportadora</FormLabel>
                        <NewAccessSelect
                            labelInValue
                            mode="multiple"
                            placeholder="Pesquise"
                            filterOption={true}
                            onSearch={debounceFetcher}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            options={options}
                            value={value}
                            onChange={handleOnChangeSelectCarrier}
                            style={{
                                width: '100%',
                                zIndex: 2000,
                            }}
                        />
                    </FormCntrolNewAccess>
                    :
                    <FormCntrolNewAccess component={'fieldset'} sx={css`display: block;`}>
                        <FormLabel component={'legend'}>Yara AD ID</FormLabel>
                        <NewAccessSelect
                            labelInValue
                            mode="multiple"
                            placeholder="Pesquise"
                            filterOption={true}
                            onSearch={debounceFetcherYaraID}
                            notFoundContent={fetchingYaraID ? <Spin size="small" /> : null}
                            options={optionsYaraID}
                            value={valueYaraID}
                            onChange={handleOnChangeSelectYaraID}
                            style={{
                                width: '100%',
                                zIndex: 2000,
                            }}
                        />
                    </FormCntrolNewAccess>
                }
                <FormCntrolNewAccess component={'fieldset'}>
                    <FormLabel component={'legend'}>Nome</FormLabel>
                    {accesssType === '3' ? <NewAccessSelect labelInValue value={userId} onChange={handleOnSelectUserId} placeholder='Selecione' options={usersOptions?.map(user => ({ ...user, label: user.label.split('/')[0] }))} /> :
                        <TextInput disabled placeholder='Digite aqui' value={userName} onChange={handleName} />}
                </FormCntrolNewAccess>
                <FormCntrolNewAccess component={'fieldset'}>
                    <FormLabel component={'legend'}>E-mail</FormLabel>
                    {accesssType === '3' ? <NewAccessSelect labelInValue value={userId} onChange={handleOnSelectUserId} placeholder='Selecione' options={usersOptions?.map(user => ({ ...user, label: user.label.split('/')[1] }))} /> :
                        <TextInput disabled placeholder='Digite aqui' value={userEmail} onChange={handleEmail} />}
                </FormCntrolNewAccess>
            </BodyDialog>
            <DialogActions>
                <Grid container justifyContent={'left'} direction="row" alignItems="center" spacing={{ xs: 2, md: 7 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={8} sm={7} xl={9} md={9.5} >
                        <Button color='error' onClick={onCancel} > Cancelar</Button>
                    </Grid>
                    <Grid item xs={8} sm={7} xl={9} md={2.5}>
                        <Button variant='contained' color='primary' disabled={handleDisabled()} onClick={accesssType === '3' ? handleConfirmCarrierAccessType : handleConfirmYara}> Adicionar acesso</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </NewAccessDialog>
    )
}

export const NewAccessDialog = styled(Dialog)`
    & .MuiPaper-root{    
        z-index: 20;
        width: 60%;
        min-width: 60%;
        height: 50%;
        min-height: 60%;
        overflow-y: auto;
    }
`

export const HeaderDialog = styled(Grid)`
    background-color: ${PRIMARY_COLOR};
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const BodyDialog = styled(DialogContent)`
    background-color: ${LIGHT_GRAY};
`

export const NewAccessSelect = styled(Select)`
    height: 56px;
`
export const FormCntrolNewAccess = styled(FormControl)`
    margin-top: 4%;
    margin-right: 1%;
    width: 35% !important;
`

export const TextInput = styled(Input)`
   height: 56px;
`

export default NewAccessModal;
