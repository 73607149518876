import React, { useEffect, useState } from "react"
import { Layout } from "../../layout"
import { Box, Button, Divider, FormControl, FormLabel, Grid, List, ListItem, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { FIXED_DRAWER_WIDTH, FIXED_HEADER_HEIGHT } from "../../../shared/utils/Constants"
import { DARK_GRAY, GRAY, LIGHT_GRAY, PRIMARY_COLOR, RED, SECONDARY_COLOR } from "../../../theme"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { ArrowLeft, Check, CheckSquare, Flag, X, XCircle } from "react-feather"
import { Stack } from "@mui/system"
import useSolicitationReview from "../hooks/useSolicitationReview"
import dayjs from "dayjs"
import { getDate, handleHomologationStatus } from "../../../shared/utils/Utils";
import { Input, Spin, notification } from 'antd';
import { useNavigate } from "react-router-dom"
import { StatusANTTEnum } from "../../../shared/utils/enum"
import { LABLE_REQUEST } from "../../carrierDetails/views/ReviewRequest"
import CnaeTypeService from "../../../services/CnaeTypeService"

const { TextArea } = Input;


const SolicitationReview = ({ }) => {

    const [apiNotification, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const [cnaeOptions, setCnaeOptions] = useState();

    const handleOptionsCNAE = async () => {
        await CnaeTypeService.getCnaeTypeList().then(response => {
            setCnaeOptions(response.data)
        });
    }

    useEffect(() => {
        handleOptionsCNAE()
    },[])

    const {
        selectedSolicitation,
        note,
        loadConfirm,
        handleCancel,
        handleOnClickConfirmButton,
        checkDisabledConfirmButton,
        handleOnchangeItemStatus,
        handleOnChangeTextarea,
        handleDownload
    } = useSolicitationReview(apiNotification, navigate);

    const handleStatusSolicitation = (status) => {
        switch (status.solicitationStatusId) {
            case 1:
                return <Typography sx={css`font-family: YaraMax;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 17px;
                    letter-spacing: 0.15000000596046448px;
                    text-align: left;
                    color: #2777B8;`
                }>
                    <Grid container justifyContent={"center"} spacing={0.5}>
                        <Grid item marginTop={0.2}>
                            <Flag size={14} style={css`margin-top:10px;`} />
                        </Grid>
                        <Grid item>
                            {status?.solicitationStatusName}
                        </Grid>
                    </Grid>
                </Typography>
            case 2:
                return <Typography sx={css`font-family: YaraMax;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 17px;
                    letter-spacing: 0.15000000596046448px;
                    text-align: left;
                    color:  #507A07;`
                }>
                    <Grid container justifyContent={"center"} spacing={0.5}>
                        <Grid item marginTop={0.2}>
                            <CheckSquare size={14} />
                        </Grid>
                        <Grid item>
                            {status?.solicitationStatusName}
                        </Grid>
                    </Grid>
                </Typography>
        }
    }

    const handleValueItemRender = (value, variableName) => {
        if(value === null) return "--";
        
        return value?.length > 0 && variableName.includes('Validity') && !isNaN(Date.parse(value)) ? dayjs(value).format('DD/MM/YYYY') : variableName.includes('ANTT') || variableName.includes('statusSocialContractId') ? StatusANTTEnum.find(el => el.anttStatusId === parseInt(value))?.anttStatusName : variableName.includes("cnaeTypeId") ?
            cnaeOptions?.find(cnae => cnae.cnaeTypeId === parseInt(value))?.cnaeTypeName : value ?? '--'
    }

    return <Layout showHeader>
        <GridContainerSection>
            <Spin spinning={loadConfirm} fullscreen >
                {contextHolder}
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <ArrowLeft
                                size={25}
                                color={SECONDARY_COLOR}
                                onClick={() => navigate(-1)}
                                className="mr-3 hover-icon"
                            />
                        </Grid>
                        <Grid item>
                            <Typography onClick={() => navigate(-1)} className="hover-icon">
                                Voltar à lista de solicitações
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <CarrierRequestBox sx={css`background-color: #F8FCFF !important;border-radius: 10px 10px 0 0 !important;padding-bottom: 0 !important;`}>
                    <Typography variant="h5" sx={css`font-weight: bold;color:${PRIMARY_COLOR};font-family: YaraMax;display: inline-block;margin-top: 25px;`}>ID {selectedSolicitation.id < 10 ?
                        '000' + selectedSolicitation.id : selectedSolicitation.id < 100 ?
                            '00' + selectedSolicitation.id : selectedSolicitation.id < 1000 ?
                                '0' + selectedSolicitation.id : selectedSolicitation.id}
                    </Typography>
                    <Stack direction={"column"} sx={css`display: inline;position: absolute;right: 250px;margin-top: 25px;`}>
                        <Typography variant="subtitle2" sx={css`color:${GRAY};font-family: YaraMax;`}>
                            Status
                        </Typography>
                        {handleStatusSolicitation(selectedSolicitation.solicitationStatus)}
                    </Stack>
                    <br />
                    <br />
                    <Divider />
                    <br />
                    <Typography variant="h8" sx={css`display: inline;color:${PRIMARY_COLOR};font-family: YaraMax;`}>Dados do solicitante</Typography>
                    <br />
                    <br />
                    <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} >
                        <Grid item xs={4} sm={4} md={4}>
                            <Stack direction={"column"}>
                                <Typography variant="subtitle2" sx={css`color:${GRAY};font-family: YaraMax;`}>
                                    Aberta em
                                </Typography>
                                <Typography variant="subtitle2" sx={css`;font-family: YaraMax;font-size:16px;`}>
                                    {dayjs(selectedSolicitation.dataInc).format('DD/MM/YYYY')}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Stack direction={"column"}>
                                <Typography variant="subtitle2" sx={css`color:${GRAY};font-family: YaraMax;`}>
                                    Solicitante
                                </Typography>
                                <Typography variant="subtitle2" sx={css`;font-family: YaraMax;font-size:16px;`}>
                                    {selectedSolicitation.userName}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Stack direction={"column"}>
                                <Typography variant="subtitle2" sx={css`color:${GRAY};font-family: YaraMax;`}>
                                    E-mail
                                </Typography>
                                <Typography variant="subtitle2" sx={css`;font-family: YaraMax;font-size:16px;`}>
                                    {selectedSolicitation.userEmail}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Stack direction={"column"}>
                                <Typography variant="subtitle2" sx={css`color:${GRAY};font-family: YaraMax;`}>
                                    CNPJ
                                </Typography>
                                <Typography variant="subtitle2" sx={css`;font-family: YaraMax;font-size:16px;`}>
                                    {selectedSolicitation.carrierCNPJ.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Stack direction={"column"}>
                                <Typography variant="subtitle2" sx={css`color:${GRAY};font-family: YaraMax;`}>
                                    Transportadora
                                </Typography>
                                <Typography variant="subtitle2" sx={css`;font-family: YaraMax;font-size:16px;`}>
                                    {selectedSolicitation.carrierName}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Stack direction={"column"}>
                                <Typography variant="subtitle2" sx={css`color:${GRAY};font-family: YaraMax;`}>
                                    Status Homologação
                                </Typography>
                                {handleHomologationStatus(selectedSolicitation.homologation.homologationStatus.homologationStatusName, selectedSolicitation.homologation.homologationCreatedAt)}
                            </Stack>
                        </Grid>
                    </Grid>
                    <br />
                    <Divider />
                </CarrierRequestBox>
                <CarrierRequestBox sx={css`border-radius: 0 0 10px 10px !important;margin-top: 0 !important;`}>
                    <Typography variant="subtitle2" sx={css`display: inline;color:${PRIMARY_COLOR};font-family: YaraMax;`}>Solicitações de edição</Typography>
                    <List>
                        {selectedSolicitation.itens.map(obj => {
                            if (['REGULATORY', 'REPRESENTATIVE', 'COMERCIAL'].includes(obj.variable) && (obj.contacts.filter(con => con.action.includes('add')).length > 0 || obj.contacts.filter(con => con.action.includes('update')).length > 0)) {
                                return (<div>
                                    <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Contato {LABLE_REQUEST[obj.variable]} - </Typography>
                                    <List>{obj.contacts.map((elContact) => {
                                        if (elContact.action.includes('add') || elContact.action.includes('update')) {
                                            return <ListItem>
                                                <Table>
                                                    <TableHead>
                                                        <TableHead sx={css`background-color: white;`}>
                                                            <TableRow >
                                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"15%"}>Nome</TableCell>
                                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"15%"}>Email</TableCell>
                                                                {obj.variable.includes("REGULATORY") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}>Telefone</TableCell>}
                                                                {obj.variable.includes("REPRESENTATIVE") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}>CPF</TableCell>}
                                                                {obj.variable.includes("COMERCIAL") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}></TableCell>}

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody sx={css`background-color: white;`}>
                                                            <TableRow >
                                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.name}</TableCell>
                                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.email}</TableCell>
                                                                {obj.variable.includes("REGULATORY") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.phone ? elContact.phone : '--'}</TableCell>}
                                                                {obj.variable.includes("REPRESENTATIVE") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.document ? elContact.document : '--'}</TableCell>}
                                                                {obj.variable.includes("COMERCIAL") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;`}></TableCell>}
                                                                {selectedSolicitation.solicitationStatus.solicitationStatusId === 1 ? <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>
                                                                    <FormControl component={'fieldset'}>
                                                                        <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Aprovar / Reprovar</FormLabel>
                                                                        <Select variant="outlined" sx={css`height: 41px; width: 162px;`} onChange={(e) => handleOnchangeItemStatus(obj, e.target.value)}>
                                                                            <MenuItem value={1}>Aprovado</MenuItem>
                                                                            <MenuItem value={2} sx={css`color:${RED};`}>Reprovado</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell> : <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{obj.solicitationItemStatus.solicitationItemStatusName}</TableCell>}
                                                            </TableRow>
                                                        </TableBody>
                                                    </TableHead>
                                                </Table>
                                            </ListItem>
                                        }
                                    })}</List>
                                </div>)
                            } else if (!['REGULATORY', 'REPRESENTATIVE', 'COMERCIAL'].includes(obj.variable))
                                return <ListItem>
                                    <Table>
                                        <TableHead>
                                            <TableHead sx={css`background-color: white;`}>
                                                <TableRow >
                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Valor atual {LABLE_REQUEST[obj.variable]}</TableCell>
                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Alterar para</TableCell>
                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"60%"}>Anexo</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody sx={css`background-color: white;`}>
                                                <TableRow >
                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{handleValueItemRender(obj.oldValue, obj.variable)}</TableCell>
                                                    <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{handleValueItemRender(obj.newValue, obj.variable)}</TableCell>
                                                    <TableCell sx={css`font-size: 14px;color:-webkit-link;padding: 16px 20px 20px 0px;text-decoration: underline; a{ cursor: pointer; }`}><a onClick={() => handleDownload(obj.solicitationItemId, LABLE_REQUEST[obj.variable].replace(' ', '') + obj.solicitationItemId + obj.extension)}>{obj.extension ? LABLE_REQUEST[obj.variable] + obj.solicitationItemId + obj.extension : ''}</a></TableCell>
                                                    {selectedSolicitation.solicitationStatus.solicitationStatusId === 1 ? <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>
                                                        <FormControl component={'fieldset'}>
                                                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Aprovar / Reprovar</FormLabel>
                                                            <Select variant="outlined" sx={css`height: 41px; width: 162px;`} onChange={(e) => handleOnchangeItemStatus(obj, e.target.value)}>
                                                                <MenuItem value={1}>Aprovado</MenuItem>
                                                                <MenuItem value={2} sx={css`color:${RED};`}>Reprovado</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </TableCell> : <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{obj.solicitationItemStatus.solicitationItemStatusName}</TableCell>}
                                                </TableRow>
                                            </TableBody>
                                        </TableHead>
                                    </Table>
                                </ListItem>
                        })}
                    </List>
                    <br />
                    <Typography variant="subtitle2" sx={css`display: inline;color:${PRIMARY_COLOR};font-family: YaraMax;`}>Solicitações de exclusão</Typography>
                    <List>
                        {selectedSolicitation.itens.map(obj => {
                            if (['REGULATORY', 'REPRESENTATIVE', 'COMERCIAL'].includes(obj.variable) && obj.contacts.filter(el => el.action.includes('remove')).length > 0) {
                                return (<div>
                                    <Typography variant="subtitle2" sx={css`display: inline;color:${GRAY};font-family: YaraMax;`}>Contato {LABLE_REQUEST[obj.variable]} -</Typography>
                                    <List>{obj.contacts.map((elContact) => {
                                        if (elContact.action.includes('remove')) {
                                            return <ListItem>
                                                <Table>
                                                    <TableHead>
                                                        <TableHead sx={css`background-color: white;`}>
                                                            <TableRow >
                                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Nome</TableCell>
                                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`}>Email</TableCell>
                                                                {obj.variable.includes("REGULATORY") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}>Telefone</TableCell>}
                                                                {obj.variable.includes("REPRESENTATIVE") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}>CPF</TableCell>}
                                                                {obj.variable.includes("COMERCIAL") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"80%"}></TableCell>}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody sx={css`background-color: white;`}>
                                                            <TableRow >
                                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.name}</TableCell>
                                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.email}</TableCell>
                                                                {obj.variable.includes("REGULATORY") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.phone ? elContact.phone : '--'}</TableCell>}
                                                                {obj.variable.includes("REPRESENTATIVE") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{elContact.document ? elContact.document : '--'}</TableCell>}
                                                                {obj.variable.includes("COMERCIAL") && <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;`}></TableCell>}
                                                                {selectedSolicitation.solicitationStatus.solicitationStatusId === 1 ? <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>
                                                                    <FormControl component={'fieldset'}>
                                                                        <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Aprovar / Reprovar</FormLabel>
                                                                        <Select variant="outlined" sx={css`height: 41px; width: 162px;`} onChange={(e) => handleOnchangeItemStatus(obj, e.target.value)}>
                                                                            <MenuItem value={1}>Aprovado</MenuItem>
                                                                            <MenuItem value={2} sx={css`color:${RED};`}>Reprovado</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </TableCell> : <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 20px 0px;`}>{obj.solicitationItemStatus.solicitationItemStatusName}</TableCell>}
                                                            </TableRow>
                                                        </TableBody>
                                                    </TableHead>
                                                </Table>
                                            </ListItem>
                                        }
                                    })}</List>
                                </div>)
                            }
                        })
                        }
                    </List>
                    <Typography variant="subtitle2" sx={css`color:${GRAY};font-family: YaraMax;`}> Incluir comentário {selectedSolicitation.solicitationStatus.solicitationStatusId === 1 ? <Typography variant="subtitle1" sx={css`font-size: 14px;color:${RED};font-family: YaraMax;display:inline;`}> - Para cancelar ou reprovar um item é obrigatorio inserir um comentario.</Typography> : ''}</Typography>
                    <br />
                    {selectedSolicitation.solicitationStatus.solicitationStatusId === 1 ? <TextArea rows={4} value={note} onChange={handleOnChangeTextarea} /> : <TextArea rows={4} value={selectedSolicitation.observation} disabled />}
                    <br />
                    <br />
                    {selectedSolicitation.solicitationStatus.solicitationStatusId === 1 && <>
                        <Button onClick={handleCancel} disabled={!(note.length > 0)} startIcon={<XCircle size={20} />} variant="outlined" color="error" sx={css`font-weight: 600;font-size: 14px;`}>
                            Cancelar Solicitação
                        </Button>
                        <Typography variant="subtitle1" sx={css`font-size: 14px;color:${DARK_GRAY};font-family: YaraMax;display: inline;position: absolute; right: 260px;`}>Analise e selecione a opção desejada em todos os itens para conseguir concluir.</Typography>

                        <Button disabled={checkDisabledConfirmButton()} onClick={handleOnClickConfirmButton} variant="outlined" color="success" sx={css`position: absolute; right: 30px;font-weight: 600;font-size: 14px;`}>
                            Concluir solicitação
                        </Button>
                    </>
                    }
                </CarrierRequestBox>
            </Spin>
        </GridContainerSection>
    </Layout>
}

const GridContainerSection = styled(Grid)`
    padding: 2rem 2rem 2rem ${FIXED_DRAWER_WIDTH};
    min-height: calc(100vh - ${FIXED_HEADER_HEIGHT});
    background-color: ${LIGHT_GRAY};

    @media only screen and (max-width: 768px){
        padding: 2rem 2px;
    }
`
const CarrierRequestBox = styled(Box)`
    background-color: WHITE;
    width: 100%;
    border-radius: 10px;
    margin-left: 6px;
    margin-top: 6px;
    padding: 20px;
    position: relative;
`

export default SolicitationReview;