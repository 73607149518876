import React, { useState, useCallback } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";
import { PRIMARY_COLOR, WHITE, GRAY, RED } from "../../../theme";
import { carrierStatus } from "../../../shared/utils/enum";
import CarrierDetailsManager from "../CarrierDetailsManager";

const CarrierModalSetStatus = props => {
  const { open, onClose, isActive, carrier, onConfirm } = props;
  const classes = useStyles();
  const [reasonToChange, setReasonToChange] = useState("");
  const [errorReasonToChange, setErrorReasonToChange] = useState("");

  const handleReasonToChange = event => {
    setReasonToChange(event.target.value);
    validateComment();
  };

  const handleSubmit = async () => {
    if (validateComment()) {
      setCarrierStatus(
        carrier.carrierId,
        isActive === true ? carrierStatus.inactive.id : carrierStatus.active.id,
        reasonToChange
      ).then(carrierNewId => {
        setReasonToChange("");
        onConfirm(carrierNewId);
      });
    }
  };

  const setCarrierStatus = useCallback(async (carrierId, carrierStatusId, reasonToChange) => {
    const carrierNewId = await CarrierDetailsManager.setCarrierStatus(
      carrierId,
      carrierStatusId,
      reasonToChange
    );
    return carrierNewId;
  }, []);

  const validateComment = () => {
    if (isActive && reasonToChange.length === 0) {
      setErrorReasonToChange("Preencha o motivo.");
      return false;
    } else if (isActive && (reasonToChange.length < 10 || reasonToChange.length > 100)) {
      setErrorReasonToChange("O motivo deve conter entre 10 e 100 caracteres.");
      return false;
    } else {
      setErrorReasonToChange("");
      return true;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.customDialog }}
      PaperProps={{ style: { minHeight: isActive ? "330px" : "185px" } }}
    >
      <Grid className={classes.customDialogTitleBar}>
        <DialogTitle className={classes.customDialogTitle}>
          {isActive ? "Inativar" : "Ativar"}
        </DialogTitle>
        <CloseIcon onClick={onClose} className={`${classes.customDialogTitleCloseIcon} mr-6`} />
      </Grid>
      <DialogContent>
        <Typography variant="subtitle1" className={`${classes.textQuestion} pt-8 pb-8`}>
          {isActive ? "Deseja inativar a transportadora?" : "Deseja ativar a transportadora?"}
        </Typography>
        {isActive && (
          <>
            <Typography variant="body2" className={`${classes.statusLabel} ml-3`}>
              Digite o motivo* (campo obrigatório)
            </Typography>
            <TextField
              label="Descreva aqui"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={3}
              inputProps={{ maxLength: 300 }}
              value={reasonToChange}
              onChange={handleReasonToChange}
              className={classes.customReasonToChange}
              error={errorReasonToChange !== ""}
              helperText={errorReasonToChange}
            />
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.buttonAction}>
        <Button onClick={onClose} className={classes.buttonCancel}>
          CANCELAR
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isActive && (reasonToChange.length === 0 || errorReasonToChange.length > 0)}
          className={classes.buttonConfirm}
        >
          CONFIRMAR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(_ => ({
  customDialogTitle: {
    color: WHITE,
    "& .MuiTypography-h6": {
      color: WHITE
    }
  },
  customDialogTitleBar: {
    backgroundColor: PRIMARY_COLOR,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  customDialogTitleCloseIcon: {
    cursor: "pointer",
    color: WHITE
  },
  customDialog: {
    width: 500,
    minHeight: 330,
    overflowY: "auto"
  },
  customReasonToChange: {
    fontSize: "0.875rem",
    color: PRIMARY_COLOR
  },
  statusLabel: {
    color: GRAY,
    margin: 0
  },
  textQuestion: {
    color: PRIMARY_COLOR,
    margin: 0
  },
  buttonAction: {
    justifyContent: "space-between"
  },
  buttonConfirm: {
    backgroundColor: PRIMARY_COLOR,
    color: WHITE,
    borderRadius: 10,
    width: 150,
    margin: 20,
    "&:hover": {
      backgroundColor: GRAY + ' !important',
      color: WHITE + ' !important'
    }
  },
  buttonCancel: {
    backgroundColor: WHITE,
    margin: 20,
    color: RED,
    borderRadius: 10,
    width: 150,
    "&:hover": {
      backgroundColor: RED,
      color: WHITE
    }
  }
}));

export default CarrierModalSetStatus;
