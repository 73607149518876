import React, { useState } from "react";
import { BodyDialog, HeaderDialog, NewAccessDialog } from "../../usersList/view/NewAccessModal";
import { Checkbox, DialogActions, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Select, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { css } from "@emotion/react"
import { CancelButton, ConfirmButton } from "../../carrierDetails/views/RequestEditCarrier";
import { cnpjValidate, emailValidate } from "../../../shared/components/DataTableAddItem/DataTableAddItem";
import CarrierService from "../../../services/CarrierService";


const AddNewCarrierModal = ({
    open,
    onClose,
    plantsOptions,
    newCarrierObj,
    handleOnChangeCNPJ,
    handleOnChangeCarrierName,
    handleOnChangePlant,
    handleOnChangeContactEmail,
    handleOnChangeContactName,
    handleCancelNewCarrier,
    handleAddNewCarrier,
    handleBranchInfos,
    carrierView
}) => {
    const [check, setCheck] = useState(false);
    const [checkValidEmail, setCheckValidEmail] = useState(false);
    const [verifyCNPJ, setVerifyCNPJ] = useState(false);
    const [cnpjHelperText, setCNPJHelperText] = useState('');
    const [branchVerify, setBranchVerify] = useState(false);

    function validateCNPJCalc(cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj === '') return false;

        if (cnpj.length !== 14)
            return false;

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === "00000000000000" ||
            cnpj === "11111111111111" ||
            cnpj === "22222222222222" ||
            cnpj === "33333333333333" ||
            cnpj === "44444444444444" ||
            cnpj === "55555555555555" ||
            cnpj === "66666666666666" ||
            cnpj === "77777777777777" ||
            cnpj === "88888888888888" ||
            cnpj === "99999999999999")
            return false;

        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== Number(digitos.charAt(0)))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== Number(digitos.charAt(1)))
            return false;

        return true;

    }

    const handleCheckboxOnChange = (event) => {
        setCheck(event.target.checked)
        if (!event.target.checked) {
            handleOnChangeContactName({ target: { value: '' } })
            handleOnChangeContactEmail({ target: { value: '' } })
        }
    }

    const handleOnBlurEmail = (event) => {
        console.log(event.target.value, "TES:::",emailValidate.test(event.target.value) )
        if (!emailValidate.test(event.target.value)) {
            setCheckValidEmail(true)
        } else {
            setCheckValidEmail(false)
        }
    }

    const handleOnBlurCNPJ = async (event) => {
        await CarrierService.verifyCNPJ(event.target.value.replace(/[^\w]/g, '')).then(async resp => {
            const check = validateCNPJCalc(event.target.value);
            if (resp?.data?.length > 0) {
                setVerifyCNPJ(true);
                setCNPJHelperText('Este CNPJ já está cadastrado')
            } else if (!check) {
                setVerifyCNPJ(true);
                setCNPJHelperText('Este CNPJ não é valido');
            } else {
                setVerifyCNPJ(false);
                await CarrierService.getInfosForBranch(event.target.value.replace(/[^\w]/g, '')).then(response => {
                    if (response.data) {
                        handleBranchInfos(response.data);
                        setBranchVerify(true);
                    }
                });
            }
        })
    }

    const handleCanAdd = () => {
        if (carrierView && !branchVerify) {
            return false
        }
        if (newCarrierObj.carrierCNPJ && newCarrierObj.carrierName && newCarrierObj.plant !== '0' && !verifyCNPJ) {
            if (check) {
                if (!checkValidEmail && newCarrierObj.contactName) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        }

        return false
    }

    return (
        <NewAccessDialog sx={css`height: 80% !important;`} open={open}>
            <HeaderDialog>
                <DialogTitle sx={css` color: white;`}>
                    Adicionar uma nova transportadora                </DialogTitle>
                <Close sx={css`cursor: pointer;color: white;margin-right: 16px;`} onClick={() => { onClose(false); setVerifyCNPJ(false); }} />
            </HeaderDialog>
            <BodyDialog>
                <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item md={3.5}>
                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>CNPJ</FormLabel>
                            <TextField error={verifyCNPJ} helperText={verifyCNPJ && cnpjHelperText} onBlur={handleOnBlurCNPJ} onChange={handleOnChangeCNPJ} value={newCarrierObj.carrierCNPJ} sx={css`&.MuiTextField-root{height: 41px; width: 100%;}& .MuiOutlinedInput-root{background: white;}`} />
                        </FormControl>
                    </Grid>
                    <Grid item md={5}>
                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Razão social</FormLabel>
                            <TextField onChange={handleOnChangeCarrierName} value={newCarrierObj.carrierName} sx={css`&.MuiTextField-root{height: 41px; width: 100%;}& .MuiOutlinedInput-root{background: white;}`} />
                        </FormControl>
                    </Grid>
                    <Grid item md={3.5}>
                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Torre de frete</FormLabel>
                            <Select onChange={handleOnChangePlant} value={newCarrierObj.plant} variant="outlined" sx={css`width: 100%; &.MuiOutlinedInput-root{background: white;}`}>
                                <MenuItem value="0" sx={css`font-size: 13.5;`}>Selecione</MenuItem>
                                {plantsOptions?.map((plant) => (
                                    <MenuItem key={plant.plantId} value={plant.plantId}>
                                        {plant.plantName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {!carrierView && <Grid item md={12}>
                        <FormControlLabel
                            value="3"
                            control={<Checkbox checked={check} onChange={handleCheckboxOnChange} />}
                            label="Enviar convite de acesso a transportadora!"
                            labelPlacement="end"
                        />
                    </Grid>}
                    {check && <Grid item md={3.5}>
                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Nome contato comercial</FormLabel>
                            <TextField onChange={handleOnChangeContactName} value={newCarrierObj.contactName} sx={css`&.MuiTextField-root{height: 41px; width: 100%;}& .MuiOutlinedInput-root{background: white;}`} />
                        </FormControl>
                    </Grid>}
                    {check && <Grid item md={5}>
                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>E-mail contato comercial</FormLabel>
                            <TextField error={checkValidEmail} helperText={checkValidEmail && 'Email invalido!'} onBlur={handleOnBlurEmail} onChange={handleOnChangeContactEmail} value={newCarrierObj.contactEmail} sx={css`&.MuiTextField-root{height: 41px; width: 100%;}& .MuiOutlinedInput-root{background: white;}`} />
                        </FormControl>
                    </Grid>}
                </Grid>
            </BodyDialog>
            <DialogActions sx={css`display: flow;`}>
                <ConfirmButton disabled={!handleCanAdd()} onClick={handleAddNewCarrier}>
                    ADICIONAR
                </ConfirmButton>
                <CancelButton onClick={handleCancelNewCarrier}>
                    CANCELAR
                </CancelButton>
            </DialogActions>
        </NewAccessDialog>
    )
}

export default AddNewCarrierModal;