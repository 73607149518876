import { IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { X } from "react-feather";
import { SECONDARY_COLOR } from "../../theme";

const CloseIcon = props => {
  const { color = SECONDARY_COLOR, onClick } = props;

  const classes = useStyles();

  return (
    <IconButton
      className={`${classes.closeIcon} mr-2 mt-2`}
      onClick={onClick}
      size="large">
      <X color={color} />
    </IconButton>
  );
};

const useStyles = makeStyles(() => ({
  closeIcon: {
    position: "absolute",
    right: 0
  }
}));
export default CloseIcon;
