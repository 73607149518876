import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ChevronDown, Filter, Plus, Users, X } from 'react-feather';
import { GRAY, LIGHT_GRAY, PRIMARY_COLOR, SECONDARY_COLOR, WHITE } from '../../../theme';
import FloatingDropdown from '../../../shared/components/FloatingDropdown';
import { css } from "@emotion/react";
import './style.css';
import { Space } from 'antd';

const HeaderUsersList = ({
    handleOpenFilter,
    open,
    cnpjValue,
    handleCNPJOnChange,
    handleCarrierNameOnChange,
    carrierName,
    homologationStatusCheck,
    handleHomologationStatusOnChange,
    suspendedStatusCheck,
    handleSuspendedStatusOnChange,
    handleApplyFilter,
    handleCleanFilter,
    handleOpenNewAccess,
    isFiltred,
    contactName,
    handleContactName,
    contactEmail,
    handleContactEmail
}) => {
    const [openCNPJ, setOpenCNPJ] = useState(false);
    const [openCarrierName, setOpenCarrierName] = useState(false);
    const [openName, setOpenName] = useState(false);
    const [openEmail, setOpenEmail] = useState(false);

    function handleDisabled() {
        if (cnpjValue || carrierName || homologationStatusCheck || suspendedStatusCheck || contactName || contactEmail) return false

        return true;
    }

    useEffect(() => {
        if (cnpjValue) {
            setOpenCNPJ(true);
        }
        if (carrierName) {
            setOpenCarrierName(true);
        }
    }, [carrierName, cnpjValue])

    return (
        <>
            <Grid container spacing={1} direction="row" alignItems="center" columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={3} md={isFiltred? 8 : 9.5}>
                    <TitleTypo justifyContent={'space-between'} ><Space><Users color={PRIMARY_COLOR} />Controle de acesso</Space></TitleTypo>
                </Grid>
                <Grid item xs={4} sm={3} md={1.5}>
                    <NewAccessButton startIcon={<Plus />} variant="outlined" onClick={() => handleOpenNewAccess()}>
                        Adicionar Acesso
                    </NewAccessButton>
                </Grid>
                {isFiltred ? (
                        <Grid item xs={4} sm={2} md={1.4}>
                            <Typography variant="outlined" sx={css`color: #D83E38;display:block;`}>
                                Filtros estão aplicados
                            </Typography>
                            <Button
                                startIcon={<X size={16} color={PRIMARY_COLOR} />}
                                onClick={() => {
                                    handleCleanFilter();
                                }}
                            >
                                Limpar filtros
                            </Button>
                        </Grid>
                    )
                        : ''
                    }
                <Grid item xs={4} sm={2} md={1}>
                    <FilterButton xs={css`margin-right: 4px;`} endIcon={<Filter />} variant="outlined" onClick={handleOpenFilter}>
                        Filtros
                    </FilterButton>

                </Grid>
            </Grid>
            {
                open ?
                    <FloatingDropdown
                        justifyStart
                        onOutsideClick={handleOpenFilter}
                        className={'p-6 floatingDrop'}
                        sx={css`margin-right: 0px;margin-left: calc(100% - 40%);z-index:3 !important;`}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={24}>
                                <FormControlLabel
                                    checked={openCNPJ}
                                    control={<Checkbox />}
                                    onChange={() => {
                                        setOpenCNPJ(!openCNPJ);
                                        handleCNPJOnChange({target: ''});
                                    }}
                                    label='CNPJ'
                                    sx={css`display: inline;`}
                                />
                                {openCNPJ ?
                                    <TextField sx={css`width: 100%;display: inline;`} size='small' value={cnpjValue} onChange={handleCNPJOnChange} placeholder='digite aqui' variant='outlined' />
                                    : ''
                                }
                            </Grid>
                            <Grid item xs={24}>
                                <FormControlLabel
                                    checked={openCarrierName}
                                    control={<Checkbox />}
                                    onChange={() => {
                                        setOpenCarrierName(!openCarrierName);
                                        handleCarrierNameOnChange({target: ''})
                                    }}
                                    label='Nome da Transportadora'
                                    sx={css`display: inline;`}
                                />
                                {openCarrierName ?
                                    <TextField sx={css`width: 100%;display: inline;`} size='small' value={carrierName} onChange={handleCarrierNameOnChange} placeholder='digite aqui' variant='outlined' />
                                    : ''
                                }
                            </Grid>
                            <Grid item xs={24}>
                                <FormControlLabel
                                    checked={openName}
                                    control={<Checkbox />}
                                    onChange={() => {
                                        setOpenName(!openName);
                                        handleContactName({target: ''})
                                    }}
                                    label='Nome do usuário'
                                    sx={css`display: inline;`}
                                />
                                {openName ?
                                    <TextField sx={css`width: 100%;display: inline;`} size='small' value={contactName} onChange={handleContactName} placeholder='digite aqui' variant='outlined' />
                                    : ''
                                }
                            </Grid>
                            <Grid item xs={24}>
                                <FormControlLabel
                                    checked={openEmail}
                                    control={<Checkbox />}
                                    onChange={() => {
                                        setOpenEmail(!openEmail);
                                        handleContactEmail({target: ''})
                                    }}
                                    label='Email do usuário'
                                    sx={css`display: inline;`}
                                />
                                {openEmail ?
                                    <TextField sx={css`width: 100%;display: inline;`} size='small' value={contactEmail} onChange={handleContactEmail} placeholder='digite aqui' variant='outlined' />
                                    : ''
                                }
                            </Grid>
                            <Grid item xs={24}>
                                <FilterGroupAccordion sx={css`max-width: 50%;`}>
                                    <AccordionSummary
                                        expandIcon={<ChevronDown />}
                                    >
                                        Status de Homologação
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormControlLabel
                                            checked={homologationStatusCheck}
                                            control={<Checkbox />}
                                            onChange={handleHomologationStatusOnChange}
                                            label='Homologada'
                                            sx={css`display: inline;`}
                                        />
                                        <br />
                                        <FormControlLabel
                                            checked={suspendedStatusCheck}
                                            control={<Checkbox />}
                                            onChange={handleSuspendedStatusOnChange}
                                            label='Suspensa'
                                            sx={css`display: inline;`}
                                        />
                                    </AccordionDetails>
                                </FilterGroupAccordion>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid container justifyContent={'space-between'}>
                            <CleanButton
                                disabled={handleDisabled()}
                                onClick={() => {
                                    handleCleanFilter();
                                    setOpenCNPJ(false);
                                    setOpenCarrierName(false);
                                }}
                            >
                                LIMPAR FILTRO
                            </CleanButton>
                            <ApplyButton
                                disabled={handleDisabled()}
                                onClick={handleApplyFilter}
                            >
                                APLICAR FILTRO
                            </ApplyButton>
                        </Grid>
                    </FloatingDropdown>
                    : ''
            }
        </>
    );
}

const FilterButton = styled(Button)`
    max-width: 140;
    height: 45px;
    border-radius: 4px;
    margin-left: auto;
    color: ${GRAY};
    background-color: WHITE;
    border-color: ${LIGHT_GRAY};
    & .MuiTypography-root: {
      color: GRAY;
    }
`

const NewAccessButton = styled(Button)`
    max-width: 140;
    height: 45px;
    border-radius: 10px;
    margin-left: auto;
    background-color: WHITE;
`

const CleanButton = styled(Button)`
    color: ${GRAY};
    width: 45%;
    max-height: 40px;

    & :hover {
      background-color: ${LIGHT_GRAY}
    };

    & .Mui-disabled {
      background-color: ${WHITE};
      color: ${LIGHT_GRAY}
    }
`

const ApplyButton = styled(Button)`
    color: ${WHITE};
    background-Color: ${PRIMARY_COLOR};
    width: 45%;
    max-height: 40px;
    &.Mui-disabled {
      color: ${WHITE};
      background-color: ${GRAY}
    }
    &:hover: {
      color: ${SECONDARY_COLOR}
    }
`

const FilterGroupAccordion = styled(Accordion)`
    color:${GRAY};
    box-shadow: none;
    .MuiAccordionSummary-root{
        padding-left: 0;
    }
`

const TitleTypo = styled(Typography)`
    margin-left:10px;
    margin-top: 10px;
    font-family: YaraMaxLF-SemiBold;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #00205B;

`


export default HeaderUsersList;