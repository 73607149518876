import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Table } from 'antd';
import { Button as MuiButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './style.css';
import styled from "@emotion/styled";
import { PRIMARY_COLOR } from "../../../theme";
import { InputVerifyDataTable } from '../../utils/enum'
import { useDispatch } from "react-redux";
import { updateTableError } from '../../../store/AddItemTableStore';

export const phoneValidate = new RegExp(/^([0-9]{2})?([0-9]{2})([0-9]{4,5})([0-9]{4})$/)
export const emailValidate = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i)
export const cpfValidate = new RegExp(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$$/)
export const cnpjValidate = new RegExp('d{2}.?d{3}.?d{3}/?d{4}-?d{2}');

function verifyCPF(cpf) {
    // Remova quaisquer caracteres não numéricos do CPF
    cpf = cpf.replace(/\D+/g, '');

    // Verifique se o CPF possui 11 dígitos
    if (cpf.length !== 11) {
        return false;
    }

    // Calcula o primeiro dígito verificador
    let soma = 0;
    for (let i = 1; i <= 9; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    let resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
        resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10))) {
        return false;
    }

    // Calcula o segundo dígito verificador
    soma = 0;
    for (let i = 1; i <= 10; i++) {
        soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) {
        resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11))) {
        return false;
    }

    // Se passou por todas as verificações, o CPF é válido
    return true;
}

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    inputVerify,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    const [statusInput, setStatusInput] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    /**
    useEffect(()=>{
        if(statusInput){
            dispatch(updateTableError(true));
        }else{
            dispatch(updateTableError(false));
        }
    }, [statusInput])
 */
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            switch(inputVerify){
                case InputVerifyDataTable.PHONE:
                    if(!phoneValidate.test(values[dataIndex])){
                        setStatusInput("error")
                    }else{
                        setStatusInput(undefined)
                    }
                break;
                case InputVerifyDataTable.EMAIL:
                    if(!emailValidate.test(values[dataIndex])){
                        setStatusInput("error")
                    }else{
                        setStatusInput(undefined)
                    }
                break;
                case InputVerifyDataTable.CPF:
                    if(!cpfValidate.test(values[dataIndex]) && !verifyCPF(values[dataIndex])){
                        setStatusInput("error")
                    }else{
                        setStatusInput(undefined)
                    }
                break;
            }
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.error('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: false,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} status={statusInput}/>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                    color: statusInput?.includes("error") ? "#ff4d4f" : undefined
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

const DataTableAddItem = ({ pageSize, titleButton, columns, dataSource, handleAdd, handleSave, handleDelete }) => {
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const [page, setPage] = useState();
    const dispatch = useDispatch();

    function handleColumns(cols) {
        const responseCol = cols.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                    inputVerify: col.inputVerify
                }),
            };
        });

        responseCol.push({
            title: 'Ações',
            dataIndex: 'actions',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Button icon={<DeleteIcon />} onClick={() => handleDelete(record.id)} />
                ) : null,
        })

        return responseCol;

    }

    return <div>
        <HomologationButton
            onClick={()=> {
                handleAdd(); 
                setPage(1);
                dispatch(updateTableError(true));
            }}
            type="primary"
        >
            {titleButton}
        </HomologationButton>
        <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            columns={handleColumns(columns)}
            pagination={{
                pageSize: pageSize || 3,
                onChange: (index) => setPage(index),
                current: page
            }}
        />
    </div>
}

const HomologationButton = styled(MuiButton)`
    background-color: ${PRIMARY_COLOR};
    color: white;
    border-radius: 10;
    width: 150;
    margin: 20;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    &:hover {
        background-color: gray;
        color: white
    }
`

export default DataTableAddItem;