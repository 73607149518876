import React from "react"
import { BodyDialog, HeaderDialog, NewAccessDialog } from "../../usersList/view/NewAccessModal";
import { DialogTitle, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { css } from "@emotion/react";

const HomologationModal = ({
    open,
    onClose
}) => {

    return (
        <NewAccessDialog sx={css`height: 80% !important;`} open={open}>
            <HeaderDialog>
                <DialogTitle sx={css` color: white;`}>
                    Requisitos de Homologação
                </DialogTitle>
                <Close sx={css`cursor: pointer;color: white;margin-right: 16px;`} onClick={() => onClose(false)} />
            </HeaderDialog>
            <BodyDialog>
                <Typography>Os requisitos de homologação são os seguintes:</Typography>
                <ul>
                    <li>Produto Simples: ANTT ativa, Contrato Social, Apólice de seguro RCTR-C e Apólice de seguro RC-DA.</li>
                    <li>Grupo 2 estadual: ANTT ativa, Contrato Social, Apólice de seguro RCTR-C e Apólice de seguro RC-DA, SASSMAQ, Plano de Atendimento a Emergências (P.A.E.), C.T.F. do Ibama.</li>
                    <li>Grupo 2 Interestadual: ANTT ativa, Contrato Social, Apólice de seguro RCTR-C e Apólice de seguro RC-DA, SASSMAQ, Plano de Atendimento a Emergências (P.A.E.), C.T.F. do Ibama e Licença Interestadual de Produtos Perigosos do Ibama (L.I.T.T.P.).</li>
                    <li>Grupo 1 estadual: ANTT ativa, Contrato Social, Apólice de seguro RCTR-C e Apólice de seguro RC-DA, SASSMAQ, Plano de Atendimento a Emergências (P.A.E.), C.T.F. do Ibama, CR do exército. </li>
                    <li>Grupo 1 Interestadual: ANTT ativa, Contrato Social, Apólice de seguro RCTR-C e Apólice de seguro RC-DA, SASSMAQ, Plano de Atendimento a Emergências (P.A.E.), C.T.F. do Ibama, CR do exército e Licença Interestadual de Produtos Perigosos do Ibama (L.I.T.T.P.).</li>
                </ul>
            </BodyDialog>
        </NewAccessDialog>
    )
}

export default HomologationModal;