import axios from "axios";
import axiosA from "../axiosInstance";
import { configureEnvironment } from "../config/env";

//
class AuthenticationService {
  async getAuthenticate(adToken) {
    try {
      return await axiosA.get("loginad", {
        handleError: false,
        headers: {
          Authorization: `Bearer ${adToken}`,
        },
      });
    } catch (error) {
      return { data: { requestError: true } };
    }
  }

  async getExternalAuthenticate(uname, pass) {
    const basicAuth = "Basic " + btoa(uname + ":" + pass);

    try {
      return await axios.post(
        `${configureEnvironment().REACT_APP_API_URL}loginexternal/`,
        {},
        {
          headers: {
            Authorization: basicAuth,
          },
        }
      );
    } catch (error) {
      return { data: { requestError: true } };
    }
  }

  async verifyEmailToPasswordRegistration(email) {
    return axios.get(
      `${
        configureEnvironment().REACT_APP_API_URL
      }register/validemail?email=${email}`
    );
  }

  async registerNewPassword(email, pass) {
    return axios.post(`${configureEnvironment().REACT_APP_API_URL}register`, {
      email: email,
      password: pass,
    });
  }

  async emailConfirmation(tk) {
    return axios.get(
      `${
        configureEnvironment().REACT_APP_API_URL
      }register/tokenregister?token=${tk}`
    );
  }

  async sendEmailToRecoverPassword(email) {
    return axios.get(
      `${
        configureEnvironment().REACT_APP_API_URL
      }register/recoverpassword?email=${email}`
    );
  }

  async resetPassword(pass, token) {
    return axios.post(
      `${configureEnvironment().REACT_APP_API_URL}register/resetpassword`,
      {
        password: pass,
        token: token,
      }
    );
  }
}

export default new AuthenticationService();
