import React from "react";
import { CircularProgress } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const Loading = props => {
  const { loading = true, centered = true, color = "secondary", className, size } = props;
  const classes = useStyles({ centered, color });

  return (
    loading && (
      <CircularProgress
        id="loading"
        size={size ? size : 40}
        className={className ? className : classes.progress}
      />
    )
  );
};

const useStyles = makeStyles(theme => ({
  progress: ({ centered, color }) => ({
    margin: centered ? theme.spacing(2) : undefined,
    position: centered ? "relative" : undefined,
    left: centered ? "calc(50% - 40px)" : undefined,
    color: theme.palette[color]?.main || color
  })
}));

export default Loading;
