import { Alert, FormControl, FormLabel, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Logo from "../../shared/img/Logo.svg";
import { LogoImg, SingupButton } from "./ExternalLogin";
import { css } from "@emotion/react";
import { PRIMARY_COLOR } from "../../theme";
import AuthenticationService from "../../services/AuthenticationService";

const ForgetPassWord = () => {
    const [userName, setUserName] = useState();
    const [errName, setErrName] = useState(false);
    const [changeStage, setChangeStage] = useState(false);

    const handleUseName = (e) => {
        setUserName(e.target.value);
    }

    const verifyEmail = async () => {
        if (userName?.length > 0) await AuthenticationService.verifyEmailToPasswordRegistration(userName).then(resp => {
            if (!resp?.data) setErrName(true)
            else setErrName(false)
        })
    }

    const verifyEnableButton = () => {
        if (!userName) {
            return true;
        }
        if (errName) {
            return true;
        }

        return false;
    }

    const handleSendEmail = async () => {
        setChangeStage(true);
        await AuthenticationService.sendEmailToRecoverPassword(userName);
    }

    return (
        <Grid xs={12} container sx={css`height: 100vh;`}>
            <Grid item xs={12} sx={css`display: flex; margin: auto; align-items: center;`}>
                <Grid item xs={12} container lg={12} className="pl-6 pr-6 pb-12 pt-12">
                    <Grid item xs={12} alignContent={"center"}>
                        <Paper elevation={3} square={false} align="center" sx={css`width: 30% !important;padding: 20px;margin-left: 35%;`}>
                            <Stack direction={"column"} spacing={3}>
                                <LogoImg src={Logo} alt="Logo Yara" align="center" />
                                <div>
                                    <Typography variant="h5" sx={css`color:${PRIMARY_COLOR};font-family: YaraMax;`}>Homologação Digital</Typography>
                                    <Typography variant="h5" sx={css`color:${PRIMARY_COLOR};font-family: YaraMax;`}>Esqueci minha senha</Typography>
                                </div>
                                {!changeStage ? <>
                                    <FormControl component={'fieldset'}>
                                        <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Informe o e-mail cadastrado</FormLabel>
                                        <TextField onBlur={verifyEmail} error={errName} helperText={errName ? 'E-mail não encontrado no nosso pré-cadastro' : ''} placeholder="Email" onChange={handleUseName} />
                                    </FormControl>
                                    <SingupButton disabled={verifyEnableButton()} onClick={handleSendEmail}>receber e-mail de redefinição</SingupButton>
                                </> : <>
                                <Alert severity="info">Enviamos um e-mail para redefinir sua senha</Alert>
                                <SingupButton disabled={verifyEnableButton()} onClick={handleSendEmail}>reenviar e-mail</SingupButton>
                                </>}
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ForgetPassWord;