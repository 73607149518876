import { IconButton, List, ListItem, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { css } from "@emotion/react"
import { GRAY } from "../../../theme";
import { Box, Stack } from "@mui/system";
import { Download, Image } from "react-feather";
import pdfIcon from "../../../shared/img/pdfIcon.png";
import { LABLE_REQUEST } from "./ReviewRequest";
import dayjs from "dayjs";
import SolicitationService from "../../../services/SolicitationService";
import { saveAs } from 'file-saver';
import { StatusANTTEnum } from "../../../shared/utils/enum";

const CarrierDocument = ({ solicitationDocsList, cnaeOptions }) => {

    const handleDownload = async (id, fileName) => {
        await SolicitationService.downloadFileItem(id).then(resp => {
            const base64Response = resp.data.fileContents;
            const binary = atob(base64Response.replace(/\s/g, ''));
            const len = binary.length;
            const buffer = new ArrayBuffer(len);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }
            const blob = new Blob([view], { type: 'application/octet-stream' });
            saveAs(blob, fileName);

        })
    }

    const handleValueItemRender = (value, variableName) => {
        if(value === null) return "--";

        return value?.length > 0 && variableName.includes('Validity') && !isNaN(Date.parse(value)) ? dayjs(value).format('DD/MM/YYYY') : variableName.includes('ANTT') || variableName.includes('statusSocialContractId') ? StatusANTTEnum.find(el => el.anttStatusId === parseInt(value))?.anttStatusName : variableName.includes("cnaeTypeId") ?
            cnaeOptions?.find(cnae => cnae.cnaeTypeId === parseInt(value))?.cnaeTypeName : value ?? '--'
    }

    return (<Box padding={2}>
        {solicitationDocsList.length > 0 ?
            <List>
                {solicitationDocsList?.map(el => {
                    return el.itens.map(item => {
                        if (item.extension)
                            return <ListItem>
                                <Table>
                                    <TableHead>
                                        <TableHead sx={css`background-color: white;`}>
                                            <TableRow >
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"35%"}>{LABLE_REQUEST[item.variable]}</TableCell>
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"25%"}></TableCell>
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Adicionado por</TableCell>
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"10%"}>Data de inclusão</TableCell>
                                                <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"10%"}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody sx={css`background-color: white;`}>
                                            <TableRow >
                                                <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}>{handleValueItemRender(item.newValue, item.variable)}</TableCell>
                                                <TableCell sx={css`font-size: 16px;font-weight: bold;color: #00205B;padding: 16px 20px 20px 0px;`}><Stack spacing={1} direction={'row'}> {item.extension.includes('.pdf') ? <img src={pdfIcon} /> : <Image />}<div style={{ textDecoration: 'underline', display: 'inline' }}>{LABLE_REQUEST[item.variable] + item.extension}</div></Stack></TableCell>
                                                <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}>{el.userName}</TableCell>
                                                <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}>{dayjs(el.dataInc).format('DD/MM/YYYY')}</TableCell>
                                                <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}>{ }</TableCell>
                                                <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}><IconButton onClick={() => handleDownload(item.solicitationItemId, LABLE_REQUEST[item.variable] + item.extension)}><Download color="#2777B8" /></IconButton> </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </TableHead>
                                </Table>
                            </ListItem>
                    })
                })}
            </List>
            : ''}
    </Box>)
}

export default CarrierDocument;