import React, { useContext, useEffect, useState } from "react";
import { List } from "@mui/material";
import SidebarItem from "./SidebarItem";
import { accessType, sidebarItems, sidebarItemsNames } from "../../../shared/utils/enum";
import { ReactReduxContext } from "react-redux";

const SidebarListItems = props => {
  const { open, onClickItem, icons, names } = props;
  const { store } = useContext(ReactReduxContext);
  const [canControlAccess, setCanControlAccess] = useState(false);

  useEffect(() => {
      const { user: {
          profile
      } } = store.getState();

      if (profile === accessType.logisticsAnalyst) {
        setCanControlAccess(true);
      }
  }, [store])

  return (
    <List>
      {sidebarItems.map((item, index) => {
        if(item.name === sidebarItemsNames.accessManagement && !canControlAccess) return;
        if(item.name === sidebarItemsNames.solicitations && !canControlAccess) return;
        return (
        <SidebarItem
          key={item.name + index}
          icon={item.icon}
          name={item.name}
          open={open}
          icons={icons}
          names={names}
          handleClick={() => onClickItem(item.route)}
        />
      )})}
    </List>
  );
};

export default SidebarListItems;
