import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";

export const PRIMARY_COLOR = "#00205B";
export const SECONDARY_COLOR = "#2777b8";

export const DARK_GRAY = "#0A0A0B";
export const GRAY = "#666666";
export const LIGHT_GRAY = "#E0E0E0";
export const DEEP_GRAY = "#8E8277";
export const MID_GRAY = "#D8D0C7";
export const THIN_BLUE = "#E9F1F8";
export const MID_BLUE_LIGHT = "#64A6EB";
export const BRIGHT_BLUE = "#63B6E6";
export const PALE_BLUE_GRAY = "#D9E1E2";
export const LIGHT_BLUE = "#64B5F6";
export const LINCOLN_GREEN = "#279500";
export const PALE_GREEN = "#E2E477";
export const MID_GREEN = "#C2CC23";
export const DARK_GREEN = "#507A07";
export const LIGHT_GREEN = "#67BD10";
export const THIN_GREEN = "#D1EBB7";
export const LIGHT_YELLOW = "#FFF1B3";
export const THIN_YELLOW = "#FFF5CC";
export const YELLOW = "#FFCF01";
export const DARK_YELLOW = "#CCA500";
export const ORANGE = "#FF8228";
export const RED = "#D83E38";
export const THIN_RED = "#F7D8D7";
export const UPSDELL_RED = "#AA1C27";
export const WHITE = "#FFFFFF";
export const LIGHT_WHITE = "#FAFAFA";
export const DEEP_WHITE = "#FDFDFD";
export const DARK_WHITE = "#F5F9FB";

export const SPACING = 8;
export const FONT_FAMILY = "YaraMax";
export const DEFAULT_ROW_HIGHT = "56px";

export function hexToRgba(hex, opacity) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;

  return result ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})` : null;
}

// Mixins

/* Funcionalidade para o em da unidade de media de tela
  const round2 = (n) => Math.round(n * 100) / 100;
  const em = (n) => round2(n) + 'em'; */

const generateNClasses = (name, number, calc) => {
  let classes = {};

  for (let i = 0; i <= number; i++) {
    let className = name.replace("{i}", i);
    classes[className] = calc(i);
  }

  return classes;
};

const spacing = num => (num ? num * SPACING + "px" : "0");

const theme = {
  spacing: SPACING,

  dimensions: {
    rowHight: DEFAULT_ROW_HIGHT
  },

  palette: {
    primary: {
      light: PRIMARY_COLOR,
      main: PRIMARY_COLOR,
      dark: PRIMARY_COLOR
    },
    secondary: {
      light: SECONDARY_COLOR,
      main: SECONDARY_COLOR
    },
    action: {
      disabled: {}
    },
    background: {
      default: "#ffffff"
    }
  },

  typography: {
    fontFamily: FONT_FAMILY,

    h1: {
      fontWeight: "300",
      fontSize: "6rem",
      color: PRIMARY_COLOR
    },
    h2: {
      fontWeight: "300",
      fontSize: "3.75rem",
      color: PRIMARY_COLOR
    },
    h3: {
      fontWeight: "400",
      fontSize: "3rem",
      color: PRIMARY_COLOR
    },
    h4: {
      fontWeight: "400",
      fontSize: "2.125rem",
      color: PRIMARY_COLOR
    },
    h5: {
      fontWeight: "400",
      fontSize: "1.5rem",
      color: PRIMARY_COLOR
    },
    h6: {
      fontWeight: "600",
      fontSize: "1.25rem",
      color: PRIMARY_COLOR
    },
    subtitle1: {
      fontWeight: "600",
      fontSize: "1rem",
      color: PRIMARY_COLOR
    },
    subtitle2: {
      fontWeight: "600",
      fontSize: "0.875rem",
      color: PRIMARY_COLOR
    },
    body1: {
      fontWeight: "400",
      fontSize: "1rem",
      color: PRIMARY_COLOR
    },
    body2: {
      fontWeight: "400",
      fontSize: "0.875rem",
      color: PRIMARY_COLOR
    },
    caption: {
      fontWeight: "400",
      fontSize: "0.75rem",
      color: PRIMARY_COLOR
    },
    overline: {
      fontWeight: "400",
      fontSize: "0.625rem",
      color: PRIMARY_COLOR
    }
  },

  overrides: {
    MuiTableCell: {
      root: {
        "&.spacing-1": {
          padding: `${spacing(2)} ${spacing(1)} ${spacing(2)} ${spacing(1)}`
        }
      },
      sizeSmall: {
        padding: `${spacing(1)} ${spacing(1)} ${spacing(1)} ${spacing(1)}`
      },
      head: {
        color: SECONDARY_COLOR,
        fontSize: "0.75rem"
      },
      body: {
        color: GRAY,
        fontSize: "0.875rem"
      }
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontWeight: "600",
        borderRadius: "8px",
        height: DEFAULT_ROW_HIGHT,
        "&.Mui-disabled": {
          backgroundColor: LIGHT_GRAY,
          color: DEEP_GRAY
        }
      },
      text: {
        "&:hover": {
          backgroundColor: "inherit"
        }
      },
      textPrimary: {
        "&:hover": {
          color: SECONDARY_COLOR,
          backgroundColor: "inherit"
        }
      },
      outlined: {
        "&.Mui-disabled": {
          border: "none"
        }
      },
      outlinedSecondary: {
        "&:hover": {
          color: WHITE,
          backgroundColor: SECONDARY_COLOR
        }
      },
      containedPrimary: {
        color: WHITE,
        "&.Mui-disabled": {
          border: "none",
          backgroundColor: LIGHT_GRAY,
          color: DEEP_GRAY
        },
        "&:hover": {
          backgroundColor: SECONDARY_COLOR
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        "&.Mui-disabled": {
          "&:hover": {
            "& $notchedOutline": {
              borderColor: "rgba(0,0,0,0.23)",
              color: GRAY
            }
          }
        },
        "&:hover": {
          "& $notchedOutline": {
            borderColor: SECONDARY_COLOR
          },
          "&.Mui-error": {
            "& $notchedOutline": {
              borderColor: RED
            }
          }
        },
        "&.Mui-focused": {
          "& $notchedOutline": {
            borderColor: SECONDARY_COLOR
          },
          "&.Mui-error": {
            "& $notchedOutline": {
              borderColor: RED
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      clearIndicator: {
        color: "inherit"
      },
      popupIndicator: {
        color: "inherit",
        marginRight: "24px"
      }
    },
    MuiInputBase: {
      root: {
        color: GRAY,
        fontWeight: "500",
        fontSize: "1rem",
        borderColor: LIGHT_GRAY,

        "&.Mui-disabled": {
          backgroundColor: WHITE,
          color: LIGHT_GRAY
        },

        "&.Mui-error": {
          color: RED
        }
      }
    },
    MuiInput: {
      underline: {
        "&:hover:not(.Mui-disabled):before,&:after": {
          borderColor: SECONDARY_COLOR
        },
        "&.Mui-focused": {
          borderColor: SECONDARY_COLOR
        }
      }
    },
    MuiRadio: {
      root: {
        "&.Mui-disabled": {
          color: LIGHT_GRAY,
          "&.Mui-checked": {
            color: LIGHT_GRAY
          }
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "1rem",
        fontWeight: "500",
        color: GRAY,
        "&.Mui-error": {
          color: RED
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        width: "100%"
      }
    },
    MuiFormHelperText: {
      root: {
        fontWeight: "400",
        fontSize: "0.75rem",
        color: RED,
        "&.Mui-focused": {
          color: RED
        },
        "&.Mui-error": {
          color: RED
        }
      },
      contained: {
        marginLeft: 0
      }
    },
    MuiMobileStepper: {
      dot: {
        backgroundColor: LIGHT_GRAY
      }
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        "&.Mui-disabled": {
          color: LIGHT_GRAY
        }
      }
    },
    MuiSlider: {
      thumb: {
        color: PRIMARY_COLOR,
        width: "15px",
        height: "15px",
        marginTop: "-4px"
      },
      track: {
        color: SECONDARY_COLOR,
        height: "8px",
        borderRadius: "200px"
      },
      rail: {
        color: PALE_BLUE_GRAY,
        height: "8px",
        borderRadius: "200px",
        width: "100%",
        marginLeft: "2px"
      },
      mark: {
        height: "12px",
        marginTop: "8px",
        color: PALE_BLUE_GRAY
      },
      markLabel: {
        marginTop: "7px",
        color: GRAY
      },
      markActive: {
        backgroundColor: PALE_BLUE_GRAY
      },
      markLabelActive: {
        color: GRAY
      }
    },
    MuiSwitch: {
      track: {
        backgroundColor: LIGHT_GRAY,
        opacity: "1.0"
      },
      switchBase: {
        color: PRIMARY_COLOR
      }
    },
    MuiPickersModal: {
      dialogRootWider: {
        minWidth: "340px"
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: "340px"
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: "8px"
      }
    }
  },

  global: {
    ".text-overflow-ellipsis": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      display: "block"
    },
    ".hover-icon": {
      "&:hover": {
        stroke: SECONDARY_COLOR,
        cursor: "pointer"
      }
    },
    ".icone-pequeno": { height: "16px", width: "16px" },
    ".icone-medio": { height: "22px", width: "22px" },
    ".icone-grande": { height: "48px", width: "48px" },
    ".italic": {
      fontStyle: "italic"
    },
    ".bold": {
      fontWeight: 600
    },
    ".regular": {
      fontWeight: 400
    },
    ".light": {
      fontWeight: 300
    },
    ".float-right": {
      display: "block",
      float: "right"
    },
    ".h7": {
      fontWeight: "400",
      fontSize: "1.125rem"
    },
    ".body3": {
      fontWeight: "700",
      fontSize: "0.875rem"
    },
    "::-webkit-scrollbar": {
      width: "0.563rem"
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: GRAY,
      borderRadius: 30
    },
    "::-webkit-scrollbar-track": {
      backgroundColor: LIGHT_GRAY,
      borderRadius: 30
    },
    ...generateNClasses(".m-{i}", 12, i => ({ margin: spacing(i * 0.5) })),
    ...generateNClasses(".mb-{i}", 12, i => ({
      marginBottom: spacing(i * 0.5)
    })),
    ...generateNClasses(".mt-{i}", 12, i => ({ marginTop: spacing(i * 0.5) })),
    ...generateNClasses(".mr-{i}", 12, i => ({
      marginRight: spacing(i * 0.5)
    })),
    ...generateNClasses(".ml-{i}", 12, i => ({
      marginLeft: spacing(i * 0.5)
    })),
    ".aviso-indicador": {
      display: "inline-block",
      borderRadius: "50%",
      width: "8px",
      height: "8px",
      verticalAlign: "inherit"
    },
    ...generateNClasses(".p-{i}", 12, i => ({ padding: spacing(i * 0.5) })),
    ...generateNClasses(".pb-{i}", 12, i => ({
      paddingBottom: spacing(i * 0.5)
    })),
    ...generateNClasses(".pt-{i}", 12, i => ({
      paddingTop: spacing(i * 0.5)
    })),
    ...generateNClasses(".pr-{i}", 12, i => ({
      paddingRight: spacing(i * 0.5)
    })),
    ...generateNClasses(".pl-{i}", 12, i => ({
      paddingLeft: spacing(i * 0.5)
    })),
    ".divider": {
      height: 1,
      backgroundColor: LIGHT_GRAY
    },
    ...generateNClasses(".divider-{i}", 8, i => ({
      height: i,
      backgroundColor: LIGHT_GRAY
    }))
  }
};

export default theme;
