import { Grid, Table, TableBody, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Edit, Info } from "react-feather";
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from "react";
import { DARK_GRAY, LIGHT_WHITE, PRIMARY_COLOR, SECONDARY_COLOR } from "../../../theme";
import { css } from "@emotion/react"
import styled from "@emotion/styled";
import { ContactCarreirProfileType } from "../../../shared/utils/enum";
import { Stack } from "@mui/system";
import { AvTimer } from "@mui/icons-material";

const CarrierDataFooter = props => {
    const { createTableHeader, createTableBody, footerData, handleOpenTab, editPermition, contactData, createContactTableBody, pendingSolicitations } = props;
    const [regulatoryContacts, setRegulatoryContacts] = useState([]);
    const [representativeContacts, setRepresentativeContacts] = useState([]);
    const [comercialContacts, setComercialContacts] = useState([]);

    useEffect(() => {
        if (contactData) {
            setRegulatoryContacts(contactData.filter(el => el.profile.profileType.profileTypeId === ContactCarreirProfileType.REGULATORY));
            setRepresentativeContacts(contactData.filter(el => el.profile.profileType.profileTypeId === ContactCarreirProfileType.REPRESENTATIVE));
            setComercialContacts(contactData.filter(el => el.profile.profileType.profileTypeId === ContactCarreirProfileType.COMERCIAL));
        }
    }, [contactData])

    return <>
        <FooterDataContainer item className={`mr-1 mb-1 p-4`} xs={4} sm={4} md={6}>
            <FooterDataHeaderContainer container >
                <Grid item>
                    <CardTitle variant="subtitle1" >
                        Contato regulatório <Tooltip title={'Este contato é o responsável pela gestão de documentos na plataforma e também recebe os alertas por e-mail de documentos vencidos e á vencer.'}><Info size={16} color={SECONDARY_COLOR}/></Tooltip> 
                    </CardTitle>
                </Grid>
                {editPermition &&
                    <Grid item>
                        <IconButton aria-label="Editar" size="large">
                            <Edit size={20} color={SECONDARY_COLOR} onClick={() => handleOpenTab(2)} />
                        </IconButton>
                    </Grid>
                }
            </FooterDataHeaderContainer>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Nome", width: "20%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createContactTableBody([{ data: regulatoryContacts, nameOverflow: true }])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Telefone", width: "50%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createContactTableBody([{ data: regulatoryContacts, phoneOverflow: true }])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={5}>
                    <Table>
                        <TableHead>
                            <TableRow>{createTableHeader([{ data: "E-mail", width: "20%" }])}</TableRow>
                        </TableHead>
                        <TableBody sx={css`max-width: 100%;`}>
                            <TableRow sx={css`max-width: 100%;`}>{createContactTableBody([{ data: regulatoryContacts, emailOverflow: true }])}</TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </FooterDataContainer>
        <FooterDataContainer item className={` ml-1 mb-1 p-4`} xs={4} sm={3.9} md={5.9}>
            <FooterDataHeaderContainer container >
                <Grid item>
                    <CardTitle variant="subtitle1" >
                        Representante legal
                    </CardTitle>
                </Grid>
                {editPermition &&
                    <Grid item>
                        <IconButton aria-label="Editar" size="large">
                            <Edit size={20} color={SECONDARY_COLOR} onClick={() => handleOpenTab(2)} />
                        </IconButton>
                    </Grid>
                }
            </FooterDataHeaderContainer>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Nome", width: "50%" },
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createContactTableBody([{ data: representativeContacts, nameOverflow: true }])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "CPF", width: "50%",  info: <Tooltip title={'Esta informação será utilizada para preenchimento do contrato.'}><Info size={16} color={SECONDARY_COLOR}/></Tooltip>}
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createContactTableBody([{ data: representativeContacts, documentOverflow: true }])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={5}>
                    <Table>
                        <TableHead>
                            <TableRow>{createTableHeader([{ data: "E-mail", width: "" }])}</TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createContactTableBody([{ data: representativeContacts, emailOverflow: true }])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </FooterDataContainer>
        <Grid item xs={4} sm={8} md={12} />
        <FooterDataContainer item className={` mr-1 mt-1 p-4`} xs={4} sm={4} md={6}>
            <FooterDataHeaderContainer container >
                <Grid item>
                    <CardTitle variant="subtitle1" >
                        Contato comercial / operacional
                    </CardTitle>
                </Grid>
                {editPermition &&
                    <Grid item>
                        <IconButton aria-label="Editar" size="large">
                            <Edit size={20} color={SECONDARY_COLOR} onClick={() => handleOpenTab(3)} />
                        </IconButton>
                    </Grid>
                }
            </FooterDataHeaderContainer>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={8} md={6}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Nome", width: "50%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createContactTableBody([{ data: comercialContacts, nameOverflow: true }])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={8} md={6}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "E-mail", width: "50%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createContactTableBody([{ data: comercialContacts, emailOverflow: true }])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

        </FooterDataContainer>
        <FooterDataContainer item className={` ml-1 mt-1 p-4`} xs={4} sm={3.9} md={5.9}>
            <FooterDataHeaderContainer container >
                <Grid item>
                    <CardTitle variant="subtitle1" >
                        Dados Yara
                    </CardTitle>
                </Grid>
                {editPermition &&
                    <Grid item>
                        <IconButton aria-label="Editar" size="large">
                            <Edit size={20} color={SECONDARY_COLOR} onClick={() => handleOpenTab(3)} />
                        </IconButton>
                    </Grid>
                }
            </FooterDataHeaderContainer>

            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Regional Yara", width: "30%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createTableBody([
                                    { data: footerData.regional?.regionalName }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Torre", width: "30%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createTableBody([
                                    { data: footerData.plant?.plantName }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Responsável Torre", width: "40%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createTableBody([
                                    { data: footerData.plant?.plantContactName }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "E-mail responsável", width: "30%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createTableBody([
                                    { data: footerData.plant?.plantContactEmail }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "ID SAP", width: "30%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {createTableBody([
                                    { data: footerData.regional?.sapId }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </FooterDataContainer>
        <FooterDataContainer item xs={12} className={` mt-2 p-4`}>
            <FooterDataHeaderContainer container >
                <Grid item>
                    <CardTitle variant="subtitle1" >
                        Dados Bancários
                    </CardTitle>
                </Grid>
                {editPermition &&
                    <Grid item>
                        <IconButton aria-label="Editar" size="large">
                            <Edit size={20} color={SECONDARY_COLOR} onClick={() => handleOpenTab(4)} />
                        </IconButton>
                    </Grid>
                }
            </FooterDataHeaderContainer>
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={2.5} md={2.5}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "País", width: "15%" },
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'bankCountry') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {footerData.bankData?.bankCountry ? footerData.bankData?.bankCountry :'--'}
                                            {pendingSolicitations.find(el => el.variable === 'bankCountry') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>
                                    },
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={2.5} md={2.5}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Bank key", width: "10%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'bankKey') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {footerData.bankData?.bankKey ? footerData.bankData?.bankKey : '--'}
                                            {pendingSolicitations.find(el => el.variable === 'bankKey') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={2.5} md={2.5}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Bank account", width: "15%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'bankAccount') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {footerData.bankData?.bankAccount ? footerData.bankData?.bankAccount : '--' }
                                            {pendingSolicitations.find(el => el.variable === 'bankAccount') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={2.5} md={2.5}>
                    <Table>
                        <TableHead>
                            <TableRow >
                                {createTableHeader([
                                    { data: "AK", width: "10%" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'bankAccountAK') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {footerData.bankData?.bankAccountAK ? footerData.bankData?.bankAccountAK : "--"}
                                            {pendingSolicitations.find(el => el.variable === 'bankAccountAK') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={4} sm={2} md={2}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {createTableHeader([
                                    { data: "Alternative payee", width: "" }
                                ])}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={pendingSolicitations.find(el => el.variable === 'alternativePayee') ? css`td{color: #FF8228}` : undefined}>
                                {createTableBody([
                                    {
                                        data: <Stack spacing={3} direction={'row'}>
                                            {footerData.bankData?.alternativePayee ? footerData.bankData?.alternativePayee  : '--'}
                                            {pendingSolicitations.find(el => el.variable === 'alternativePayee') && <Tooltip title="Solicitação pendente" placement="bottom"><AvTimer sx={css`color:#FF8228;font-size: 20px;`} /></Tooltip>}
                                        </Stack>
                                    }
                                ])}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </FooterDataContainer>
    </>;
};

const FooterDataContainer = styled(Grid)`
    border-radius: 10px;
    background-color: ${LIGHT_WHITE};
`
const FooterDataHeaderContainer = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const CardTitle = styled(Typography)` color: ${DARK_GRAY};`

export default CarrierDataFooter;
