import { createTheme, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { ThemeProvider } from "@mui/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";
import URLPaths from "./URLPaths";
import styles from "./theme";
import "./App.css";
import { connect } from "react-redux";
import { ptBR } from "@mui/material/locale";
import { css } from "@emotion/react";
import { ConfigProvider } from "antd";
//
const projectTheme = createTheme({ ...styles, ptBR });

const inputGlobalStyles = (
  <GlobalStyles
    styles={css`
      @font-face {
        font-family: YaraMax !important;
        src: local(""), url("/fonts/YaraMaxLF-Regular.ttf") format("truetype"),
          url("/fonts/YaraMaxLF-SemiBold.ttf") format("truetype"),
          url("/fonts/YaraMaxLF-light.ttf") format("truetype");
      }
      * {
        box-sizing: border-box;
        font-family: YaraMax;
      }

      html,
      body {
        width: 100%;
        height: 100%;
        min-height: 100%;
        padding: 0;
        margin: 0;
      }

      & .MuiTableCell-root,
      .MuiInputBase-input,
      .MuiTypography-root {
        font-family: YaraMax;
      }
    `}
  />
);

function App({ pca }) {
  useStyles();

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ConfigProvider
          theme={{
            components:{
              Table:{
                headerColor: "#666666",
                headerBg:"#F0EFEF"	
              }
            }
          }}
        >
          <ThemeProvider theme={projectTheme}>
            <CssBaseline />
            {inputGlobalStyles}
            <URLPaths pcaInstances={pca} />
          </ThemeProvider>
        </ConfigProvider>
      </StyledEngineProvider>
    </>
  );
}

const useStyles = makeStyles(() => ({
  "@global": styles.global,
}));

const mapStateToProps = (state) => {
  return {
    authenticated: state.user.isAuthenticated,
  };
};

export default connect(mapStateToProps, null)(App);
