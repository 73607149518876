import { useContext, useEffect, useState } from "react";
import SolicitationService from "../../../services/SolicitationService";
import { ReactReduxContext, useDispatch } from "react-redux";
import { updateFilerListSolicitations } from "../../../store/FilterListSolicitationsStore";
import { INITIAL_STATE as INITIAL_STATE_SOLICITATIONS_LIST } from "../../../store/FilterListSolicitationsStore";
import { selectSolicitation } from "../../../store/SolicitationStore";
import { useNavigate } from "react-router";
import { routes } from "../../../shared/utils/Constants";

const useSolicitations = () => {
    const [solicitationsCloses, setSolicitationsCloses] = useState();
    const [solicitationsOpens, setSolicitationsOpens] = useState();
    const [openFilter, setOpenFilter] = useState(false);
    const [filters, setFilters] = useState(INITIAL_STATE_SOLICITATIONS_LIST);
    const [load, setLoad] = useState(false);
    const { store } = useContext(ReactReduxContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleFilterVisibility = () => {
        setOpenFilter(!openFilter);
    }

    async function handleGetSolicitations(filter) {
        let response;
        if (filter) {
            const statusFilter = []
            if (filter.homologationStatusCheck) statusFilter.push(1);
            if (filter.suspendedStatusCheck) statusFilter.push(2);
            if (filter.inHomologationStatusCheck) statusFilter.push(3);

            response = await SolicitationService.getSolicitations(filter.cnpjValue, filter.carrierName, statusFilter)
        } else {
            response = await SolicitationService.getSolicitations();
        }

        return response.data;
    }

    async function handleDataMap() {
        const fil = store.getState().listSolicitationsFilter;

        const data = await handleGetSolicitations(fil);
        const dataCloses = data.filter(el => el.solicitationStatus?.solicitationStatusId >= 2 && el.solicitationStatus?.solicitationStatusId <= 4);
        const dataOpens = data.filter(el => el.solicitationStatus?.solicitationStatusId === 1);

        setFilters(fil);

        setSolicitationsCloses(dataCloses.map((el, index) => {
            return {
                ...el,
                key: index
            }
        }));

        setSolicitationsOpens(dataOpens.map((el, index) => {
            return {
                ...el,
                key: index
            }
        }))
    }

    useEffect(async () => {
        await handleDataMap();
    }, [])

    const handleCNPJOnChange = (e) => {
        setFilters({ ...filters, cnpjValue: e.target.value })
    }

    const handleCarrierNameOnChange = (e) => {
        setFilters({ ...filters, carrierName: e.target.value })
    }

    const handleHomologationOnchangeFilter = () => {
        setFilters({ ...filters, homologationStatusCheck: !filters.homologationStatusCheck })
    }

    const handleinHomologationStatusOnChange = () => {
        setFilters({ ...filters, inHomologationStatusCheck: !filters.inHomologationStatusCheck })
    }

    const handleSuspendedOnchangeFilter = () => {
        setFilters({ ...filters, suspendedStatusCheck: !filters.suspendedStatusCheck })
    }


    const handleApplyFilter = async () => {
        setLoad(true)
        dispatch(updateFilerListSolicitations({ ...filters, filtred: true }))
        await handleDataMap().finally(() => {
            setLoad(false)
        })
    }

    const handleCleanFilter = async () => {
        setLoad(true)
        setFilters(INITIAL_STATE_SOLICITATIONS_LIST);
        dispatch(updateFilerListSolicitations(INITIAL_STATE_SOLICITATIONS_LIST))
        await handleDataMap().finally(() => {
            setLoad(false)
        })
    }

    const handleOnClickRow = (row) => {
        try {
            dispatch(selectSolicitation(row));
        }finally{
            navigate(routes.solicitationReview);
        }
    }


    return {
        solicitationsCloses,
        solicitationsOpens,
        openFilter,
        filters,
        load,
        handleinHomologationStatusOnChange,
        handleOnClickRow,
        handleDataMap,
        handleFilterVisibility,
        handleCNPJOnChange,
        handleCarrierNameOnChange,
        handleHomologationOnchangeFilter,
        handleSuspendedOnchangeFilter,
        handleApplyFilter,
        handleCleanFilter
    }

}

export default useSolicitations;