import { MsalAuthProvider, LoginType } from "react-aad-msal";
//
export const authConfig = {
    auth: {
        authority: "https://login.microsoftonline.com/ef8a53ea-1a1c-4189-b792-c832dcaea568", // Instance + TenantId
        clientId: "4e42404c-c6eb-4205-b291-95c735b77b8e", // App Registration - Digital Homologation
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
};

export const authParams = {
    scopes: [
        "8834363e-97f0-437b-8aaf-f165694784a3/Acesso" // Scope do App Registration do BackEnd - Digital Homologation
    ]
};

export const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin
};

export const authProvider = new MsalAuthProvider(authConfig, authParams, options);
