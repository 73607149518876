import { useEffect, useState } from "react";
import { ContactCarreirProfileType, InputVerifyDataTable } from "../../../shared/utils/enum";
import UserService from "../../../services/UserService";
import { configModalConfirm } from "../views/CarrierModalEdit";


const useComercialContact = (contacts, open, apiNotification, modal) => {
  const [comercialContactsData, setComercialContactsData] = useState([]);
  const [deletedComercialContact, setDeletedComercialContact] = useState([]);

  useEffect(() => {
    if (contacts && !open)
      setComercialContactsData(
        contacts.filter(
          el => el.profile.profileType.profileTypeId === ContactCarreirProfileType.COMERCIAL
        )
      );
  }, [contacts, open]);

  const defaultComercialContactsColumns = [
    {
      title: "Nome",
      dataIndex: "userName",
      width: "50%",
      editable: true
    },
    {
      title: "E-mail",
      dataIndex: "userEmail",
      width: "50%",
      editable: true,
      inputVerify: InputVerifyDataTable.EMAIL
    }
  ];

  const handleDeleteComercialContact = id => {
    const newData = comercialContactsData.filter(item => item.id !== id);
    setDeletedComercialContact([
      ...deletedComercialContact,
      comercialContactsData.find(item => item.id === id)
    ]);
    setComercialContactsData(newData);
  };

  const handleAddComercialContact = () => {
    if (comercialContactsData.length > 0) {
      const hightId = Math.max(...comercialContactsData.map(item => item.id));

      const newData = {
        id: hightId + 1,
        userName: "contato",
        userEmail: "contato@transportadora.com",
        isNewContact: true,
        profile: {
          profileType: { profileTypeId: ContactCarreirProfileType.COMERCIAL }
        }
      };
      setComercialContactsData([newData, ...comercialContactsData]);
    } else {
      const newData = {
        id: 1,
        userName: "contato",
        userEmail: "contato@transportadora.com",
        isNewContact: true,
        profile: {
          profileType: { profileTypeId: ContactCarreirProfileType.COMERCIAL }
        }
      };
      setComercialContactsData([newData]);
    }
  };

  const handleSaveComercialContact = async row => {
    const emailAlreadyExists = comercialContactsData.filter(el => el.userEmail === row.userEmail && el.userEmail?.length > 0 && el.id !== row.id);

    if (emailAlreadyExists.length > 0) {
      apiNotification['warning']({
        message: 'Atenção ao alterar!',
        description: "Esse e-mail já está vinculado a outro usuário, gerencie pelo Controle de Acessos.",
        placement: 'topRight',
        duration: 5
      });

      return;
    }

    if (row.userEmail) {
      const response = await UserService.getUserByEmail(row.userEmail);
      if (response?.data !== null && response?.data?.userActive) {
        if (response.data.id !== row.id && !row.isNewContact) {
          apiNotification['warning']({
            message: 'Atenção ao alterar!',
            description: "Esse e-mail já está vinculado a outro usuário, gerencie pelo Controle de Acessos. As alterações não serão realizadas.",
            placement: 'topRight',
            duration: 5
          });
        }

        if (response.data?.id === row?.id && !row.isNewContact && response.data?.id !== null) {
          const confrimModal = await modal.confirm(configModalConfirm);
          if (
            !confrimModal
          ) {
            return;
          }

          row.userName = response.data.userName;
          row.userEmail = response.data.userEmail;
        }
      }
    }

    const newData = [...comercialContactsData];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      edited: item.isNewContact ? false : true
    });
    setComercialContactsData(newData);
  };

  return {
    defaultComercialContactsColumns,
    comercialContactsData,
    deletedComercialContact,
    handleDeleteComercialContact,
    handleAddComercialContact,
    handleSaveComercialContact
  };
};

export default useComercialContact;
