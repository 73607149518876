import React, { useState } from "react";
import { TableCell, TableRow, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  GRAY,
  DARK_GRAY,
  PRIMARY_COLOR,
  DARK_GREEN,
  ORANGE,
  RED,
  SECONDARY_COLOR,
  LIGHT_GRAY,
  WHITE
} from "../../../theme";
import { ChevronUp, ChevronDown } from "react-feather";
import CarrierDetailsCard from "./CarrierDetailsCard";
import TooltipItem from "../../../shared/components/TooltipItem";
import { handleScoreClass, handleHomologationStatus } from "../../../shared/utils/Utils";
import { css } from "@emotion/react";

const CarrierContentTableRow = props => {
  const { item, colSpan, handleRedirect, carrierView } = props;

  const classes = useStyles();

  const [openDetails, setOpenDetails] = useState(false);

  const pendingIssuesLength = item.pendingIssues?.length;

  return (
    <>
      <TableRow>
        <TableCell
          className={`${classes.bodyCell} ${classes.bodyCellCNPJ} bold hover-icon`}
          align="left"
          onClick={() => handleRedirect(item.carrierId)}
        >
          <TooltipItem tooltip={item.carrierCNPJ} onlyWhenOverflown>
            <Typography variant="body1" className={`${classes.dataTextCell} bold`}>
              {item.carrierCNPJ || "--"}
            </Typography>
          </TooltipItem>
        </TableCell>
        <TableCell
          className={`${classes.bodyCell} hover-icon`}
          align="left"
          onClick={() => handleRedirect(item.carrierId)}
        >
          <TooltipItem tooltip={item.carrierName} onlyWhenOverflown>
            <Typography
              variant="body1"
              className={`${classes.defaultText} ${classes.dataTextCell}`}
            >
              {item.carrierName || "--"}
            </Typography>
          </TooltipItem>
        </TableCell>
        <TableCell
          className={`${classes.bodyCell} hover-icon`}
          align="left"
          onClick={() => handleRedirect(item.carrierId)}
        >
          <TooltipItem tooltip={item.regional?.regionalName} onlyWhenOverflown>
            <Typography
              variant="body1"
              className={`${classes.regional} ${classes.dataTextCell} bold`}
            >
              {item.regional?.regionalName || "--"}
            </Typography>
          </TooltipItem>
        </TableCell>
        <TableCell
          className={`${classes.bodyCell} hover-icon`}
          align="left"
          onClick={() => handleRedirect(item.carrierId)}
        >
          {handleHomologationStatus(
            item.listHomologation?.homologationStatus?.homologationStatusName,
            item.listHomologation?.homologationUpdatedAt,
            true,
            classes
          )}
        </TableCell>
        <TableCell
          className={`${classes.bodyCell} hover-icon`}
          align="left"
          onClick={() => handleRedirect(item.carrierId)}
        >
        {item.productGroup?.productGroupName && (
            <TooltipItem tooltip={<Typography className={classes.tooltip}>{item.productGroup.productGroupName}</Typography>}>
                <Typography className={`${classes.listText} bold`}>
                    {item.productGroup.productGroupName}
                </Typography>
            </TooltipItem>
        )}
        {!item.productGroup?.productGroupName && (
            <Typography className={`${classes.listText} bold`}>
                --
            </Typography>
        )}
        </TableCell>
        <TableCell
          className={`${classes.bodyCell} hover-icon`}
          align="left"
          onClick={() => handleRedirect(item.carrierId)}
        >
          <TooltipItem
            tooltip={
              pendingIssuesLength > 0 &&
              item.pendingIssues.map(pi => {
                return <Typography className={classes.tooltip}>{pi.pendingIssueName}</Typography>;
              })
            }
            disabled={pendingIssuesLength <= 3}
          >
            <Typography>
              {pendingIssuesLength > 0 ? (
                item.pendingIssues.map((pi, i) => {
                  let concat = pendingIssuesLength > 3 && i === 2;
                  let label = concat ? `${pi.pendingIssueName}...` : pi.pendingIssueName;
                  return (
                    i < 3 && (
                      <TooltipItem
                        tooltip={label}
                        disabled={pendingIssuesLength > 3}
                        onlyWhenOverflown
                      >
                        <Typography className={`${classes.listText} ${classes.dataTextCell} bold`}>
                          {label}
                        </Typography>
                      </TooltipItem>
                    )
                  );
                })
              ) : (
                <TooltipItem tooltip="Sem pendências" onlyWhenOverflown>
                  <Typography className={`${classes.statusOk} ${classes.dataTextCell} bold`}>
                    Sem pendências
                  </Typography>
                </TooltipItem>
              )}
            </Typography>
          </TooltipItem>
        </TableCell>
        {!carrierView && (
        <TableCell
          className={`${classes.bodyCell} hover-icon`}
          align="left"
          onClick={() => handleRedirect(item.carrierId)}
        >
          <TooltipItem tooltip={item.carrierSerasaScore} onlyWhenOverflown>
            <Typography
              variant="body1"
              className={`${handleScoreClass(item.serasaScore?.serasaScoreName, classes)} ${
                classes.dataTextCell
              } bold`}
              sx={css`color: ${handleScoreClass(item.serasaScore?.serasaScoreName, classes)};`}
            >
              {item.carrierSerasaScore || "--"}
            </Typography>
          </TooltipItem>
          <TooltipItem tooltip={item.serasaScore?.serasaScoreName} onlyWhenOverflown>
            <Typography
              variant="body1"
              className={`${classes.defaultText} ${classes.dataTextCell}`}
            >
              {item.serasaScore?.serasaScoreName || "--"}
            </Typography>
          </TooltipItem>
        </TableCell>
        )}
        <TableCell className={classes.chevronCell}>
          {!openDetails ? (
            <ChevronDown
              size={20}
              onClick={() => setOpenDetails(true)}
              className={`${classes.chevron} hover-icon`}
            ></ChevronDown>
          ) : (
            <ChevronUp
              size={20}
              onClick={() => setOpenDetails(false)}
              className={`${classes.chevron} hover-icon`}
            ></ChevronUp>
          )}
        </TableCell>
      </TableRow>
      {openDetails && (
        <TableRow className={classes.detailsRow}>
          <TableCell colSpan={colSpan} className={classes.detailsCell}>
            <CarrierDetailsCard open={openDetails} details={item} carrierView={carrierView}></CarrierDetailsCard>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  bodyCell: {
    "&.MuiTableCell-root": {
      padding: "16px 8px 16px 8px"
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "inherit"
    }
  },
  dataTextCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  chevronCell: {
    "&.MuiTableCell-root": {
      padding: "0px"
    }
  },
  detailsRow: {
    backgroundColor: LIGHT_GRAY
  },
  detailsCell: {
    "&.MuiTableCell-root": {
      padding: "0px 0px 8px 0px"
    }
  },
  bodyCellCNPJ: {
    color: PRIMARY_COLOR
  },
  defaultText: {
    color: DARK_GRAY
  },
  listText: {
    color: DARK_GRAY,
    lineHeight: 1.25
  },
  statusDate: {
    color: DARK_GRAY,
    fontSize: 12
  },
  regional: {
    color: GRAY
  },
  statusOk: {
    color: DARK_GREEN
  },
  errorStatus: {
    color: RED
  },
  serasaScoreLevel1: {
    color: DARK_GREEN
  },
  serasaScoreLevel2: {
    color: ORANGE
  },
  serasaScoreLevel3: {
    color: RED
  },
  chevron: {
    color: SECONDARY_COLOR,
    display: "flex"
  },
  tooltip: {
    color: WHITE,
    fontWeight: 400,
    fontSize: 10
  },
  statusContainer: {
    alignItems: "center"
  }
}));

export default CarrierContentTableRow;
