import React, { useState } from "react";
import { DARK_GRAY, GRAY, LIGHT_GRAY, PRIMARY_COLOR, SECONDARY_COLOR, WHITE } from "../../../theme";
import { Button, Checkbox, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Calendar, ChevronDown, ChevronUp } from "react-feather";
import { carrierFilterOptions } from "../../../shared/utils/enum";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from "date-fns/locale/pt-BR";

const FilterOptions = props => {
  const {
    homologationStatus,
    productGroups,
    regionals,
    setSelectingFilters,
    submitFilters,
    listHasBeenFiltered,
    setListHasBeenFiltered,
    loading,
    cnpjIsChecked,
    setCheckCnpj,
    cnpjText,
    setCnpjText,
    nameIsChecked,
    setCheckName,
    nameText,
    setNameText,
    startDay,
    setStartDay,
    endDay,
    setEndDay,
    cleanFilters,
    towers
  } = props;
  const classes = useStyles();

  const [openStatusGroup, setOpenStatusGroup] = useState(false);
  const [openRegionalGroup, setOpenRegionalGroup] = useState(false);
  const [openProductGroup, setOpenProductGroup] = useState(false);
  const [openDocumentGroup, setOpenDocumentsGroup] = useState(false);
  const [openTowerGroup, setOpenTowerGroup] = useState(false);
  const [scheduleStop, setScheduleStop] = useState(false);
  const [isMatriz, setIsMatriz] = useState(false);
  const [filters, setFilters] = useState({
    status: homologationStatus,
    productgroups: productGroups,
    regionals: regionals,
    towers: towers
  });

  const createCheckboxes = (data, type) => {
    let checkboxGroup = [];
    data?.forEach(e => {
      checkboxGroup.push(
        <Grid container className={`${classes.filterContainer}`}>
          <Checkbox
            checked={e.isChecked}
            onChange={() => {
              e.isChecked = !e.isChecked;
              setFilters({
                status: type === carrierFilterOptions.status ? data : filters.status,
                productgroups: type === carrierFilterOptions.productgroups ? data : filters.productgroups,
                regionals: type === carrierFilterOptions.regional ? data : filters.regionals,
                towers: type === carrierFilterOptions.towers ? data : filters.towers,
              });
            }}
            className="pl-0 pt-1 pb-1"
          />
          <Typography variant="body1" className={classes.filterLabel}>
            {e.name}
          </Typography>
        </Grid>
      );
    });

    return checkboxGroup;
  };

  const createGroup = (groupTitle, openChevron, chevronAction, firstColumn) => {
    return (
      <Grid
        container
        className={`${classes.filterContainer} ${!firstColumn && classes.columnSpacing}`}
      >
        <Grid item className="mr-5">
          <Typography
            variant="body1"
            className={`${classes.groupTitle} ${firstColumn && classes.firstColumn}`}
          >
            {groupTitle}
          </Typography>
        </Grid>
        <Grid item xs>
          {openChevron ? (
            <ChevronUp
              size={24}
              color={SECONDARY_COLOR}
              onClick={() => chevronAction(!openChevron)}
              className={`${classes.chevronIcon} hover-icon`}
            />
          ) : (
            <ChevronDown
              size={24}
              color={SECONDARY_COLOR}
              onClick={() => chevronAction(!openChevron)}
              className={`${classes.chevronIcon} hover-icon`}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const handleMaxStartDate = () => {
    if (endDay) return new Date(endDay);
  };

  const handleMinEndDate = () => {
    if (startDay) return new Date(startDay);
    else return new Date();
  };

  const handleSubmit = async () => {
    await submitFilters({
      cnpj: cnpjText,
      name: nameText,
      homologationStatusIds: filters.status?.filter(e => e.isChecked)?.map(e => e.id),
      productGroupsIds: filters.productgroups?.filter(e => e.isChecked)?.map(e => e.id),
      regionalsIds: filters.regionals?.filter(e => e.isChecked)?.map(e => e.id),
      towersIds: filters.towers?.filter(e => e.isChecked)?.map(e => e.id),
      startDay: startDay ? new Date(startDay) : null,
      endDay: endDay ? new Date(endDay) : null,
      scheduledStop: scheduleStop,
      isMatriz: isMatriz
    });
    setListHasBeenFiltered(true);
  };

  const hasAnyFiltersSelected =
    startDay ||
    endDay ||
    scheduleStop ||
    isMatriz ||
    filters.status?.find(e => e.isChecked) ||
    filters.productgroups?.find(e => e.isChecked) ||
    filters.regionals?.find(e => e.isChecked) ||
    filters.towers?.find(e => e.isChecked) ||
    (cnpjIsChecked && cnpjText.length > 0) ||
    (nameIsChecked && nameText.length > 0);

  return <>
    <Grid container className={`${classes.filterContainer} mb-2`}>
      <Checkbox
        checked={cnpjIsChecked}
        onChange={() => {
          setCheckCnpj(!cnpjIsChecked);
          setCnpjText("");
        }}
        className="pl-0"
      />
      <Typography variant="body1" className={classes.filterLabel}>
        CNPJ
      </Typography>
      {cnpjIsChecked && (
        <Grid item xs>
          <TextField
            id="cnpj"
            placeholder="Digite aqui"
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 18 }}
            value={cnpjText}
            onChange={e => setCnpjText(e.target.value)}
            className={`${classes.textField} ml-5`}
          />
        </Grid>
      )}
    </Grid>
    <Grid container className={`${classes.filterContainer} mb-2`}>
      <Checkbox
        checked={nameIsChecked}
        onChange={() => {
          setCheckName(!nameIsChecked);
          setNameText("");
        }}
        className="pl-0"
      />
      <Typography variant="body1" className={classes.filterLabel}>
        Nome da transportadora
      </Typography>
      {nameIsChecked && (
        <Grid item xs>
          <TextField
            id="carrierName"
            placeholder="Digite aqui"
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 255 }}
            value={nameText}
            onChange={e => setNameText(e.target.value)}
            className={`${classes.textField} ml-5`}
          />
        </Grid>
      )}
    </Grid>
    <Grid container className={`${classes.filterContainer} mb-2`}>
      <Checkbox
        checked={scheduleStop}
        onChange={() => {
          setScheduleStop(!scheduleStop)
        }}
        className="pl-0"
      />
      <Typography variant="body1" className={classes.filterLabel}>
        Parada programada
      </Typography>
    </Grid>
    <Grid container className={`${classes.filterContainer} mb-8`}>
      <Checkbox
        checked={isMatriz}
        onChange={() => {
          setIsMatriz(!isMatriz)
        }}
        className="pl-0"
      />
      <Typography variant="body1" className={classes.filterLabel}>
        Somente matriz
      </Typography>
    </Grid>
    <Grid container className="mb-4">
      <Grid item className={classes.columnSpacing}>
        {createGroup("Status de homologação", openStatusGroup, setOpenStatusGroup, true)}
        {openStatusGroup && createCheckboxes(homologationStatus, carrierFilterOptions.status)}
      </Grid>
      <Grid item>
        {createGroup("Regional", openRegionalGroup, setOpenRegionalGroup, false)}
        {openRegionalGroup && createCheckboxes(regionals, carrierFilterOptions.regional)}
      </Grid>
    </Grid>
    <Grid container className="mb-4">
      <Grid item className={classes.columnSpacing}>
        {createGroup("Tipo de homologação", openProductGroup, setOpenProductGroup, true)}
        {openProductGroup && createCheckboxes(productGroups, carrierFilterOptions.productgroups)}
      </Grid>
      <Grid item>
        {createGroup("Torres", openTowerGroup, setOpenTowerGroup, false)}
        {openTowerGroup && createCheckboxes(towers, carrierFilterOptions.towers)}
      </Grid>
    </Grid>
    <Grid className="mb-12">
      {createGroup("Documentos a vencer", openDocumentGroup, setOpenDocumentsGroup, true)}
      {openDocumentGroup && (
        <Grid container className="mt-3">
          <Grid item xs={5}>
            <Typography variant="body1" className={classes.groupTitle}>
              Data início
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} locale={ptBR}>
              <DatePicker
                locale={ptBR}
                value={startDay || null}
                label={!startDay && "Selecione"}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                cancelLabel="CANCELAR"
                minDate={new Date()}
                maxDate={handleMaxStartDate()}
                onOpen={() => setSelectingFilters(true)}
                onClose={() => setSelectingFilters(false)}
                onChange={value => setStartDay(value.setHours(0))}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton className="p-0" size="large">
                        <Calendar size={24} color={SECONDARY_COLOR} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                className={classes.datePicker}
              ></DatePicker>
            </LocalizationProvider>
          </Grid>
          <Grid item xs>
            <Typography variant="body1" className={classes.groupTitle}>
              Data fim
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR} locale={ptBR}>
              <DatePicker
                locale={ptBR}
                value={endDay || null}
                label={!endDay && "Selecione"}
                format="dd/MM/yyyy"
                inputVariant="outlined"
                cancelLabel="CANCELAR"
                minDate={handleMinEndDate()}
                onOpen={() => setSelectingFilters(true)}
                onClose={() => setSelectingFilters(false)}
                onChange={value => setEndDay(value.setHours(0))}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton className="p-0" size="large">
                        <Calendar size={24} color={SECONDARY_COLOR} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                className={classes.datePicker}
              ></DatePicker>
            </LocalizationProvider>
          </Grid>
        </Grid>
      )}
    </Grid>
    <Grid container className={classes.buttonsContainer}>
      <Button
        onClick={() => { setScheduleStop(false); cleanFilters(); }}
        disabled={(loading || !hasAnyFiltersSelected) && !listHasBeenFiltered}
        className={classes.cleanButton}
      >
        LIMPAR FILTRO
      </Button>
      <Button
        onClick={handleSubmit}
        disabled={loading || !hasAnyFiltersSelected}
        className={classes.filterButton}
      >
        APLICAR FILTRO
      </Button>
    </Grid>
  </>;
};

const useStyles = makeStyles(_ => ({
  filterContainer: {
    alignItems: "center"
  },
  filterLabel: {
    color: DARK_GRAY
  },
  textField: {
    width: "-webkit-fill-available"
  },
  groupTitle: {
    color: GRAY
  },
  firstColumn: {
    minWidth: 177
  },
  chevronIcon: {
    display: "flex"
  },
  columnSpacing: {
    marginRight: 130
  },
  buttonsContainer: {
    justifyContent: "space-between"
  },
  cleanButton: {
    color: GRAY,
    width: "45%",
    maxHeight: 40,
    "&:hover": {
      backgroundColor: LIGHT_GRAY
    },
    "&.Mui-disabled": {
      backgroundColor: WHITE,
      color: LIGHT_GRAY
    }
  },
  filterButton: {
    color: WHITE,
    backgroundColor: PRIMARY_COLOR,
    width: "45%",
    maxHeight: 40,
    "&.Mui-disabled": {
      color: WHITE,
      backgroundColor: GRAY
    },
    "&:hover": {
      color: SECONDARY_COLOR
    }
  },
  datePicker: {
    width: 190,
    "& .MuiInputBase-input": {
      cursor: "pointer",
      padding: 14
    },
    "& .MuiInput-underline:before": {
      display: "none"
    },
    "& .MuiInput-underline:after": {
      display: "none"
    },
    "&:hover": {
      borderColor: SECONDARY_COLOR
    },
    "& .MuiInputLabel-formControl": {
      top: -5
    }
  }
}));

export default FilterOptions;
