import { Layout } from '../../layout';
import { Space, Table, Tabs } from 'antd'
import styled from "@emotion/styled";
import { Box, Typography } from '@mui/material';
import { css } from "@emotion/react"
import useSolicitations from '../Hooks/useSolicitations';
import { Check, X } from 'react-feather';
import dayjs from 'dayjs';
import HeaderSolicitationsList from './HeaderSolicitationsList';
import { DARK_GRAY, PRIMARY_COLOR } from '../../../theme';
import { handleHomologationStatus } from '../../../shared/utils/Utils';

const SolicitationsList = () => {

    const {
        solicitationsCloses,
        solicitationsOpens,
        openFilter,
        filters,
        load,
        handleinHomologationStatusOnChange,
        handleDataMap,
        handleOnClickRow,
        handleFilterVisibility,
        handleCNPJOnChange,
        handleCarrierNameOnChange,
        handleHomologationOnchangeFilter,
        handleSuspendedOnchangeFilter,
        handleApplyFilter,
        handleCleanFilter
    } = useSolicitations();

    const columnsOpens = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '5%'
        },
        {
            title: 'Aberta em',
            dataIndex: 'dataInc',
            width: '10%',
            render: (text, record) => {
                return <div>
                    <Typography>{dayjs(text).format('DD/MM/YYYY')}</Typography>
                </div>
            }
        },
        {
            title: 'Solicitante',
            dataIndex: 'userName',
            width: '15%'
        },
        {
            title: 'E-mail',
            dataIndex: 'userEmail',
            width: '15%'
        },
        {
            title: 'CNPJ',
            key: 'cnpj',
            dataIndex: 'carrierCNPJ',
            align: 'center',
            width: '15%',
            render: (text, record) => (
                <Space>
                    <Typography sx={css`font-size: 14px;font-weight: bold;color: #00205B;display:inline;`}>{text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}</Typography>
                </Space>
            )
        },
        {
            title: 'Transportadora',
            dataIndex: 'carrierName',
        },
        {
            title: 'Status Homologação',
            dataIndex: 'status',
            render: (text, record) => {
                return handleHomologationStatus(record.homologation.homologationStatus.homologationStatusName, record.homologation.homologationCreatedAt)
            }
        }
    ]

    const columnsCloses = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '5%'
        },
        {
            title: 'Aberta em',
            dataIndex: 'dataInc',
            width: '10%',
            render: (text, record) => {
                return <div>
                    <Typography>{dayjs(text).format('DD/MM/YYYY')}</Typography>
                </div>
            }
        },
        {
            title: 'Solicitante',
            dataIndex: 'userName',
            width: '15%'
        },
        {
            title: 'E-mail',
            dataIndex: 'userEmail',
            width: '15%'
        },
        {
            title: 'CNPJ',
            key: 'cnpj',
            dataIndex: 'carrierCNPJ',
            align: 'center',
            width: '15%',
            render: (text, record) => (
                <Space>
                    <Typography sx={css`font-size: 14px;font-weight: bold;color: #00205B;display:inline;`}>{text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}</Typography>
                </Space>
            )
        },
        {
            title: 'Transportadora',
            dataIndex: 'carrierName',
        },
        {
            title: 'Status Homologação',
            dataIndex: 'status',
            render: (text, record) => {
                return handleHomologationStatus(record.homologation.homologationStatus.homologationStatusName, record.homologation.homologationCreatedAt)
            }
        }
    ]

    function handleDataTable(tabKey) {
        return (
            <DataTable
                loading={load}
                dataSource={tabKey === 1 ? solicitationsOpens : solicitationsCloses}
                columns={tabKey === 1 ? columnsOpens : columnsCloses}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            handleOnClickRow(record);
                        }, // click row
                    };
                }}
            />
        )
    }

    return (
        <Layout showHeader>
            <UsersListContainer>
                <HeaderSolicitationsList
                    open={openFilter}
                    handleOpenFilter={handleFilterVisibility}
                    cnpjValue={filters.cnpjValue}
                    handleCNPJOnChange={handleCNPJOnChange}
                    carrierName={filters.carrierName}
                    handleCarrierNameOnChange={handleCarrierNameOnChange}
                    homologationStatusCheck={filters.homologationStatusCheck}
                    handleHomologationStatusOnChange={handleHomologationOnchangeFilter}
                    suspendedStatusCheck={filters.suspendedStatusCheck}
                    handleSuspendedStatusOnChange={handleSuspendedOnchangeFilter}
                    inHomologationStatusCheck={filters.inHomologationStatusCheck}
                    handleinHomologationStatusOnChange={handleinHomologationStatusOnChange}
                    handleApplyFilter={handleApplyFilter}
                    handleCleanFilter={handleCleanFilter}
                    isFiltred={filters.filtred}
                />
                <UserListTabs
                    defaultActiveKey='1'
                    items={[
                        {
                            label: 'Abertas',
                            key: '1',
                            children: handleDataTable(1),
                        },
                        {
                            label: 'Concluídas ',
                            key: '2',
                            children: handleDataTable(2)
                        }
                    ]}
                />
            </UsersListContainer>
        </Layout>
    )
}

const DataTable = styled(Table)`
    th{
        font-weight: 400 !important;
    }
    .ant-table-expanded-row{
        background: #F0EFEF;
    }
`
const UsersListContainer = styled(Box)`
    margin-top: 30px;
    margin-left: 105px;
    margin-right: 10px;
`
const UserListTabs = styled(Tabs)`
    font-weight: 400;
    color: ${DARK_GRAY};
    text-transform: initial;
    opacity: unset;
    min-width: fit-content;

    & .ant-tabs-tab-btn{
        font-size: 16px;
      }

    & .ant-tabs-tab-active {
      font-weight: 700;
      & .ant-tabs-tab-btn{
        color: ${PRIMARY_COLOR} !important;
      }
    }

    & .ant-tabs-ink-bar{
        height: 5px !important;
        border-radius: 3px !important;
    }
`




export default SolicitationsList;