import { environment } from "../../shared/utils/enum";

export const envConfig = {
    PUBLIC_URL: "http://localhost:3000/",
    REACT_APP_ENVIRONMENT_NAME: environment.development,
    REACT_APP_NAME: "$npm_package_name",
    REACT_APP_VERSION: "$npm_package_version",
    REACT_APP_API_URL: "https://app-digitalhomolog-preprod-backend.azurewebsites.net/api/v1/",
    REACT_APP_CLARITY_KEY: "",
    REACT_APP_CLIENTID_AD: "4e42404c-c6eb-4205-b291-95c735b77b8e",
    REACT_APP_CLIENTID_B2C: "609947c0-e6d4-492e-a75c-797a6406c7d9",
    REACT_APP_SCOPE_AD: "8834363e-97f0-437b-8aaf-f165694784a3/Acesso",
    REACT_APP_SCOPE_B2C: "https://YaraBrazilB2C.onmicrosoft.com/609947c0-e6d4-492e-a75c-797a6406c7d9/demo.read"
};
