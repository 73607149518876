import { clarity } from "react-microsoft-clarity";
import { envConfig as developer } from "./developer";
import { envConfig as homologation } from "./homologation";
import { envConfig as production } from "./production";

export function configureEnvironment() {
  const hostname = window && window.location && window.location.hostname;

  if (hostname.startsWith("app-digitalhomolog-preprod-frontend.azurewebsites.net")) {
    return homologation;
  } else if (hostname.startsWith("app-digitalhomolog-prod-frontend.azurewebsites.net") || hostname.startsWith("homologacaodigital.yarabrasil.com.br")) {
    clarity.init("k8zb6mtnrf");
    return production;
  } else {
    return developer;
  }
}
