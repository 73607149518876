import { jwtDecode } from "jwt-decode";
import AuthenticationService from "../services/AuthenticationService";
import {
  storageKeyToken,
  storageKeyExpiresOn,
  storageKeyBffToken,
} from "../shared/utils/Constants";

class LoginManager {
  async authenticate() {
    const token = sessionStorage.getItem(storageKeyToken);

    return AuthenticationService.getAuthenticate(token).then((response) => {
      if (response && response.data && !response.data.requestError) {
        const { exp, id, ad, name, email, idProfile, profile } = jwtDecode(
          response.data
        );

        const user = {
          id: parseInt(id),
          adUser: ad,
          name: name,
          email: email,
          profile: {
            id: parseInt(idProfile),
            profileName: profile,
          },
          requestError: false,
        };

        sessionStorage.setItem(storageKeyExpiresOn, new Date(exp * 1000));
        sessionStorage.setItem(storageKeyBffToken, response.data);

        return user;
      }
      return response?.data;
    });
  }

  async externalAuthenticate(userName, password) {
    return AuthenticationService.getExternalAuthenticate(
      userName,
      password
    ).then((resp) => {
      if (resp && resp.data && !resp.data.requestError) {
        const { exp, id, ad, name, email, idProfile, profile } = jwtDecode(
          resp.data
        );
        const user = {
          id: parseInt(id),
          adUser: ad,
          name: name,
          email: email,
          profile: {
            id: parseInt(idProfile),
            profileName: profile,
          },
          requestError: false,
        };

        sessionStorage.setItem(storageKeyExpiresOn, new Date(exp * 1000));
        sessionStorage.setItem(storageKeyBffToken, resp.data);

        return user;
      }
    });
  }
}

export default new LoginManager();
