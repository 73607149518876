import React, { useLayoutEffect, useRef, useState } from "react";
import { Paper, Divider, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useOutsideEvent } from "../customHooks/useOutsideEvent";

const FloatingDropdown = props => {
  const { children, disabled, enableDividers, parentRef, className, onOutsideClick, justifyStart, sx } =
    props;

  const dropdownRef = useRef(null);
  const [rect, setRect] = useState({});

  const classes = useStyles();

  useLayoutEffect(() => {
    if (parentRef && dropdownRef.current && justifyStart) {
      const parentRect = parentRef.getBoundingClientRect();
      const paddingRight = getComputedStyle(dropdownRef.current).paddingRight;
      const marginTop = getComputedStyle(dropdownRef.current).marginTop;

      //margin is suffixed with 'px'
      let x = paddingRight.substring(0, paddingRight.length - 2) - 0;
      let y = parentRect.y + parentRect.height + 4;
      y -= marginTop.substring(0, marginTop.length - 2);

      setRect({ x, y });
    }
  }, [parentRef, justifyStart, disabled]);

  useOutsideEvent(dropdownRef, "mousedown", onOutsideClick);

  return (
    !disabled && (
      <Paper
        sx={sx}
        elevation={3}
        className={`${className} ${classes.dropdown}`}
        style={{ right: rect.x, top: rect.y }}
        ref={dropdownRef}
      >
        {Array.isArray(children) ? (
          children.map((child, index) => {
            return (
              <Grid key={index}>
                {!!child && index > 0 && enableDividers && <Divider className="divider" />}
                {child}
              </Grid>
            );
          })
        ) : (
          <Grid>{children}</Grid>
        )}
      </Paper>
    )
  );
};

const useStyles = makeStyles(() => ({
  dropdown: {
    position: "absolute",
    zIndex: 1
  }
}));

export default FloatingDropdown;
