import React from "react";
import { ListItem, ListItemText, ListItemIcon, Typography, Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { BRIGHT_BLUE, DARK_GRAY } from "../../../theme";

const SideBarItem = props => {
  const { handleClick, icon, name, isSubMenuItem, icons, names } = props;

  const classes = useStyles();

  return (
    <Box className={isSubMenuItem && classes.submenuItem}>
      <ListItem button onClick={() => handleClick()} className="pt-4 pb-4">
        {icons && <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>}
        {names && (
          <ListItemText
            primary={
              <Typography variant="body1" component="div" className={classes.listItemText}>
                {name}
              </Typography>
            }
          />
        )}
      </ListItem>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  listItemText: {
    color: DARK_GRAY,
    paddingLeft: "16px"
  },
  listItemIcon: {
    placeContent: "center",
    display: "flex",
    minWidth: "56px"
  },
  submenuItem: {
    backgroundColor: BRIGHT_BLUE
  }
}));

export default SideBarItem;
