import { environment } from "../../shared/utils/enum";
export const envConfig = {
  PUBLIC_URL: "https://app-digitalhomolog-prod-frontend.azurewebsites.net",
  REACT_APP_ENVIRONMENT_NAME: environment.production,
  REACT_APP_NAME: "$npm_package_name",
  REACT_APP_VERSION: "$npm_package_version",
  REACT_APP_API_URL: "https://app-digitalhomolog-prod-backend.azurewebsites.net/api/v1/",
  REACT_APP_CLARITY_KEY: "7cuo1307fs",
  REACT_APP_CLIENTID_AD: "1c3ce5a0-375e-4a62-abc4-67084a6f81d0",
  REACT_APP_CLIENTID_B2C: "3c8c0cee-ff73-432e-822c-59974d35b3c0",
  REACT_APP_SCOPE_AD: "a0711d51-9b85-41cd-bf8b-a0204787e6e3/Acesso",
  REACT_APP_SCOPE_B2C: "https://yarabrazilb2c.onmicrosoft.com/3c8c0cee-ff73-432e-822c-59974d35b3c0/demo.read"
};
