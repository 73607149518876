import React from "react";
import { Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "../../img/SearchIcon.svg";

const CustomTableHasNoData = props => {
  const { message } = props;
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" item xs={12} className={classes.noDataBorderTop}>
      <Grid item className="pt-6 mb-8 mr-8">
        <img src={SearchIcon} alt="" />
      </Grid>
      <Grid item xs={12} className="pb-6">
        <Typography variant="h4" component="p" className="mb-4" align="center" color="primary">
          Oops, Desculpe...
        </Typography>
        <Typography
          variant="h5"
          component="p"
          className={classes.description}
          align="center"
          color="secondary"
        >
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  noDataBorderTop: {
    border: "none"
  },
  description: {
    maxWidth: 600,
    margin: "auto"
  }
}));

export default CustomTableHasNoData;
