import axios from "../axiosInstance";

class SolicitationService {
  constructor() {}

  async getSolicitations(cnpj, name, status) {
    return await axios.get(
      `solicitation?${cnpj ? `CNPJ=${cnpj}&` : ""}${
        name ? `Name=${name}&` : ""
      }${status ? status.map((el) => `HomologationStatusIds=${el}&`) : ""}`
    );
  }

  async sendSolicitation(carrier) {
    return await axios.post(`solicitation?carrierid=${carrier}`);
  }

  async sendSolicitationData(sol, solNum) {
    return await axios.post(
      `solicitation/item?solicitationid=${solNum}&variable=${sol.variable}&oldValue=${sol.oldValue}&newValue=${sol.newValue}`,
      sol.file,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  async getPendingSolicitation(carrier) {
    return await axios.get(`solicitation/pending/${carrier}`);
  }

  async sendContatsSolicitation(id, solicitationId, contacts) {
    return await axios.post(`solicitation/contact?carrierid=${id}&solicitationid=${solicitationId}`, contacts);
  }

  async getSolicitationByCarrier(id) {
    return await axios.get(`solicitation/carrier/${id}`);
  }

  async getSolicitationDocs(id) {
    return await axios.get(`solicitation/documents/${id}`);
  }

  async carrierCancelSolicitation(idSol) {
    return await axios.put(`solicitation/close/${idSol}`);
  }

  async downloadFileItem(id) {
    return await axios.get(`solicitation/download/${id}`);
  }

  async completeSolicitation(id, note, itens) {
    return await axios.post(
      `solicitation/confirm?solicitationid=${id}&observation=${note}`, itens
    );
  }

  async yaraCancelSolicitation(id, note) {
    return axios.post(
      `solicitation/cancel?solicitationid=${id}&observation=${note}`
    );
  }
}

export default new SolicitationService();
