import { configureStore, applyMiddleware, compose } from "@reduxjs/toolkit";
import reducers from "./reducers";
import thunk from "redux-thunk";

const composeEnhancers =
  (process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) ||
  compose;

const store = configureStore({reducer: reducers}, composeEnhancers(applyMiddleware(thunk)));
export default store;
