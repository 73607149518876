import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "../../layout";
import { Button, Grid, Tab, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CustomTable from "../../../shared/components/CustomTable/CustomTable";
import CarrierContentTableRow from "./CarrierContentTableRow";
import ListCarriersManager from "../ListCarriersManager";
import { FIXED_DRAWER_WIDTH, messages, routes } from "../../../shared/utils/Constants";
import { TabContext, TabList } from "@mui/lab";
import {
  DARK_GRAY,
  GRAY,
  LIGHT_GRAY,
  PRIMARY_COLOR,
  RED,
  SECONDARY_COLOR,
  WHITE,
  hexToRgba
} from "../../../theme";
import { accessType, carrierStatus } from "../../../shared/utils/enum";
import { Filter, Download, X, Plus } from "react-feather";
import FloatingDropdown from "../../../shared/components/FloatingDropdown";
import FilterOptions from "./FilterOptions";
import Loading from "../../../shared/components/Loading";
import CarrierService from "../../../services/CarrierService";
import { downloadResponseFile } from "../../../shared/utils/RequestUtil";
import styled from "@emotion/styled";
import { ReactReduxContext, useDispatch } from "react-redux";
import { INITIAL_STATE, updateFilerListCarrier } from "../../../store/FilterListCarrierStore";
import AddNewCarrierModal from "./AddNewCarrierModal";
import { css } from "@emotion/react";
import useNewCarrier from "../hooks/useNewCarrier";
import { Stack } from "@mui/system";


const ListCarriers = () => {
  const activeTabValue = "1";
  const inactiveTabValue = "2";
  const actionsMenuRef = useRef();
  const dispatch = useDispatch();
  const { store } = useContext(ReactReduxContext)

  const [carriers, setCarriers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(activeTabValue);
  const [openFilters, setOpenFilters] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [filters, setFilters] = useState();
  const [selectingFilters, setSelectingFilters] = useState(false);
  const [listHasBeenFiltered, setListHasBeenFiltered] = useState(false);
  const [cnpjIsChecked, setCheckCnpj] = useState(false);
  const [nameIsChecked, setCheckName] = useState(false);
  const [cnpjText, setCnpjText] = useState("");
  const [nameText, setNameText] = useState("");
  const [startDay, setStartDay] = useState();
  const [endDay, setEndDay] = useState();
  const [carrierView, setCarrierView] = useState(false);

  useEffect(() => {
    const { user: {
      profile
    } } = store.getState();

    if (profile === accessType.carrier) {
      setCarrierView(true);
    }
  }, [store])

  const classes = useStyles({ openFilters: openFilters || listHasBeenFiltered });
  const navigate = useNavigate();

  const listCarriers = useCallback(async filters => {
    setLoading(true);

    const result = await ListCarriersManager.listCarriers(filters);
    if (result) setCarriers(result);

    setLoading(false);
  }, []);

  //Load the filters from the redux store
  useEffect(async () => {
    const { listCarrierFilter } = store.getState();

    if (listCarrierFilter) {
      await ListCarriersManager.loadFilters().then(result => {
        setFilters({
          status: result.status.map(el => {
            if (listCarrierFilter.status?.includes(el.homologationStatusId)) {
              return {
                id: el.homologationStatusId,
                name: el.homologationStatusName,
                isChecked: true
              }
            }
            return {
              id: el.homologationStatusId,
              name: el.homologationStatusName,
              isChecked: false
            };
          }),
          productgroups: result.productgroups.map(el => {
            if (listCarrierFilter.productgroups?.includes(el.productGroupId)) {
              return {
                id: el.productGroupId,
                name: el.productGroupName,
                isChecked: true
              }
            }
            return {
              id: el.productGroupId,
              name: el.productGroupName,
              isChecked: false
            };
          }),
          regionals: result.regionals.map(el => {
            if (listCarrierFilter.regionals?.includes(el.regionalId)) {
              return {
                id: el.regionalId,
                name: el.regionalName,
                isChecked: true
              }
            }
            return {
              id: el.regionalId,
              name: el.regionalName,
              isChecked: false
            };
          }),
          towers: result.towers.map(el => {
            if (listCarrierFilter.towers?.includes(el.plantId)) {
              return {
                id: el.plantId,
                name: el.plantName,
                isChecked: true
              }
            }
            return {
              id: el.plantId,
              name: el.plantName,
              isChecked: false
            };
          })
        });
      });

      setCnpjText(listCarrierFilter.cnpjText);
      setCheckCnpj(listCarrierFilter.checkCnpj);
      setNameText(listCarrierFilter.nameText);
      setCheckName(listCarrierFilter.checkName);

      setStartDay(listCarrierFilter.startDay);
      setEndDay(listCarrierFilter.endDay);

      setOpenFilters(false);
      await listCarriers({
        cnpj: listCarrierFilter.cnpjText,
        name: listCarrierFilter.nameText,
        homologationStatusIds: listCarrierFilter.status,
        productGroupsIds: listCarrierFilter.productgroups,
        regionalsIds: listCarrierFilter.regionals,
        startDay: listCarrierFilter.startDay ? new Date(listCarrierFilter.startDay) : null,
        endDay: listCarrierFilter.endDay ? new Date(listCarrierFilter.endDay) : null
      });
      setListHasBeenFiltered(listCarrierFilter.filtred);
    }
  }, [])


  //Dispatch the filters to redux store
  useEffect(() => {
    const filterToDispatch = {};
    if (filters) {
      filterToDispatch['status'] = filters.status?.filter(e => e.isChecked).map(el => el.id);
      filterToDispatch['productgroups'] = filters.productgroups?.filter(e => e.isChecked).map(el => el.id);
      filterToDispatch['regionals'] = filters.regionals?.filter(e => e.isChecked).map(el => el.id);
      filterToDispatch['towers'] = filters.towers?.filter(e => e.isChecked).map(el => el.id);
    }

    if (startDay) {
      filterToDispatch['startDay'] = startDay;
    }

    if (endDay) {
      filterToDispatch['endDay'] = endDay;
    }

    if (cnpjIsChecked) {
      filterToDispatch['cnpjText'] = cnpjText;
      filterToDispatch['checkCnpj'] = cnpjIsChecked;
    }

    if (nameIsChecked) {
      filterToDispatch['nameText'] = nameText;
      filterToDispatch['checkName'] = nameIsChecked;
    }


    filterToDispatch['filtred'] = listHasBeenFiltered;

    dispatch(updateFilerListCarrier(filterToDispatch))
  }, [filters, cnpjIsChecked, nameIsChecked, cnpjText, nameText, startDay, endDay, listHasBeenFiltered])

  /*
  useEffect(() => {
    listCarriers();
  }, [listCarriers]);*/

  const handleCarrierDetailsRedirect = carrierId => {
    navigate(routes.carrierDetails, { state: { carrierId } });
  };

  const carriersHeaderTable = [
    { title: "CNPJ", width: "13%" },
    { title: "Transportadora", width: "15%" },
    { title: "Regional Yara", width: "7%" },
    { title: "Status Homologação", width: "11%" },
    { title: "Grupo de Produto", width: "18%" },
    { title: "Pendência", width: "10%" },
    { title: "Score Serasa", width: "5%" },
    { title: "", width: "2%" }
  ];


  const handleChangeTab = (_, newValue) => setTabValue(newValue);

  const groupCarriersByStatus = () => {
    const groupedCarriers = [];
    if (carriers)
      carriers.forEach(carrier => {
        if (
          (activeTabValue === tabValue &&
            carrier.carrierStatus?.carrierStatusId === carrierStatus.active.id) ||
          (inactiveTabValue === tabValue &&
            carrier.carrierStatus?.carrierStatusId === carrierStatus.inactive.id)
        )
          groupedCarriers.push(carrier);
      });
    return groupedCarriers;
  };

  const formatFilters = result => {
    const formattedFilters = { status: [], productgroups: [], regionals: [], towers: [] };
    if (result.status)
      result.status.forEach(e => {
        formattedFilters.status.push({
          name: e.homologationStatusName,
          id: e.homologationStatusId,
          isChecked: false
        });
      });
    if (result.productgroups)
      result.productgroups.forEach(e => {
        formattedFilters.productgroups.push({
          name: e.productGroupName,
          id: e.productGroupId,
          isChecked: false
        });
      });
    if (result.regionals)
      result.regionals.forEach(e => {
        formattedFilters.regionals.push({
          name: e.regionalName,
          id: e.regionalId,
          isChecked: false
        });
      });
    if (result.towers)
      result.towers.forEach(e => {
        formattedFilters.towers.push({
          name: e.plantName,
          id: e.plantId,
          isChecked: false
        });
      });
    return formattedFilters;
  };

  const loadFilterOptions = async () => {
    if (listHasBeenFiltered) setOpenFilters(true);
    else {
      setLoadingFilters(true);
      const result = await ListCarriersManager.loadFilters();
      if (result) {
        const formattedFilters = formatFilters(result);
        setFilters(formattedFilters);
        setOpenFilters(true);
      }
      setLoadingFilters(false);
    }
  };

  const dataExport = async () => {
    const response = await CarrierService.exportData();
    downloadResponseFile(response, "Relatório de Transportadoras.xlsx");
  };

  const cleanFilters = async () => {
    setCnpjText("");
    setCheckCnpj(false);
    setNameText("");
    setCheckName(false);
    setFilters({
      status: filters.status?.forEach(e => (e.isChecked = false)),
      productgroups: filters.productgroups?.forEach(e => (e.isChecked = false)),
      regionals: filters.regionals?.forEach(e => (e.isChecked = false)),
      towers: filters.towers?.forEach(e => (e.isChecked = false)),
    });
    setStartDay();
    setEndDay();
    if (listHasBeenFiltered) {
      setOpenFilters(false);
      await listCarriers();
    }
    setListHasBeenFiltered(false);
    dispatch(updateFilerListCarrier(INITIAL_STATE))
  };

  const {
    openModalNewCarrier,
    plantsOptions,
    newCarrierObj,
    handleBranchInfos,
    handleAddNewCarrier,
    handleCancelNewCarrier,
    handleOnChangeCNPJ,
    handleOnChangeCarrierName,
    handleOnChangePlant,
    handleOnChangeContactEmail,
    handleOnChangeContactName,
    handleOpenAndCloseModalNewCarrier
  } = useNewCarrier(handleCarrierDetailsRedirect);

  return (
    <Layout showHeader>
      <ListCarriersGridContainer>
        <Grid container className={classes.headerContent}>
          <Typography variant="h6">Transportadoras</Typography>
          <TabContext value={tabValue}>
            <TabList onChange={handleChangeTab} className={`${classes.tabs} ml-8`}>
              <Tab label="Ativas" value={activeTabValue} className={`${classes.tab}  pb-0 mr-5`} />
              {!carrierView &&
                <Tab label="Inativas" value={inactiveTabValue} className={`${classes.tab} pb-0`} />
              }
            </TabList>
          </TabContext>
          <Button
            variant="outlined"
            startIcon={<Plus />}
            sx={css`max-width: 140;
                    height: 45px;
                    border-radius: 10px;
                    margin-left: auto;
                    background-color: WHITE;`
            }
            onClick={() => handleOpenAndCloseModalNewCarrier(true)}
          >
            {!carrierView ? 'Adicionar Transportadora' : 'Adicionar Filial'}
          </Button>
          {(!listHasBeenFiltered && !carrierView) && (
            <Button
              fullWidth
              className={classes.exportButton}
              variant="outlined"
              endIcon={
                <Download
                  color={GRAY}
                  size={24}
                  className="m-2"
                />
              }
              onClick={dataExport}
            >
              <Typography variant="body1">
                Exportar
              </Typography>
            </Button>
          )}
          {listHasBeenFiltered && (
            <Stack direction={'column'} sx={css`margin-left: 30px;`}>
              <Typography variant="outlined" className={classes.cleanFiltersText}>
                Filtros estão aplicados
              </Typography>
              <Button
                startIcon={<X size={16} color={PRIMARY_COLOR} />}
                className={`${classes.cleanFiltersButton} p-0 mr-4`}
                onClick={cleanFilters}
              >
                Limpar filtros
              </Button>
            </Stack>
          )}
          {!carrierView && <Button
            fullWidth
            className={classes.filtersButton}
            variant="outlined"
            endIcon={
              loadingFilters ? null : (
                <Filter
                  color={openFilters || listHasBeenFiltered ? WHITE : GRAY}
                  size={24}
                  className="m-2"
                />
              )
            }
            ref={actionsMenuRef}
            onClick={loadFilterOptions}
          >
            {loadingFilters ? (
              <Loading loading size={24} className={classes.loading} />
            ) : (
              <Typography variant="body1" className={classes.filterText}>
                Filtros
              </Typography>
            )}
          </Button>}

          {filters && (
            <FloatingDropdown
              disabled={!openFilters}
              justifyStart
              parentRef={actionsMenuRef.current}
              onOutsideClick={() => !selectingFilters && setOpenFilters(false)}
              className="p-6"
            >
              <FilterOptions
                homologationStatus={filters?.status}
                productGroups={filters?.productgroups}
                regionals={filters?.regionals}
                towers={filters?.towers}
                setSelectingFilters={setSelectingFilters}
                submitFilters={listCarriers}
                listHasBeenFiltered={listHasBeenFiltered}
                setListHasBeenFiltered={setListHasBeenFiltered}
                loading={loading}
                cnpjIsChecked={cnpjIsChecked}
                setCheckCnpj={setCheckCnpj}
                cnpjText={cnpjText}
                setCnpjText={setCnpjText}
                nameIsChecked={nameIsChecked}
                setCheckName={setCheckName}
                nameText={nameText}
                setNameText={setNameText}
                startDay={startDay}
                setStartDay={setStartDay}
                endDay={endDay}
                setEndDay={setEndDay}
                cleanFilters={cleanFilters}
              />
            </FloatingDropdown>
          )}
        </Grid>
        <CustomTable
          data={groupCarriersByStatus()}
          loading={loading}
          noDataMessage={messages.NO_CARRIERS}
          headerData={carriersHeaderTable}
          RowComponent={({ item }) => (
            <CarrierContentTableRow
              item={item}
              colSpan={carriersHeaderTable.length}
              handleRedirect={handleCarrierDetailsRedirect}
              carrierView={carrierView}
            />
          )}
          optionsRowsPerPage={[10, 50, 100]}
        />
        <AddNewCarrierModal
          plantsOptions={plantsOptions}
          open={openModalNewCarrier}
          onClose={handleOpenAndCloseModalNewCarrier}
          newCarrierObj={newCarrierObj}
          handleOnChangeCNPJ={handleOnChangeCNPJ}
          handleOnChangeCarrierName={handleOnChangeCarrierName}
          handleOnChangePlant={handleOnChangePlant}
          handleOnChangeContactEmail={handleOnChangeContactEmail}
          handleOnChangeContactName={handleOnChangeContactName}
          handleCancelNewCarrier={handleCancelNewCarrier}
          handleAddNewCarrier={handleAddNewCarrier}
          handleBranchInfos={handleBranchInfos}
          carrierView={carrierView}
        />
      </ListCarriersGridContainer>
    </Layout>
  );
};

const ListCarriersGridContainer = styled(Grid)`
  margin-left: ${FIXED_DRAWER_WIDTH};
  @media only screen and (max-width: 768px){
    margin-left: 1px;
    margin-right: 1px;
  }
`


const useStyles = makeStyles(_ => ({
  container: {
    marginLeft: FIXED_DRAWER_WIDTH
  },
  headerContent: {
    alignItems: "center",
    padding: "20px 24px 20px 24px "
  },
  tabs: {
    "& .MuiTabs-indicator": {
      height: "5px",
      borderRadius: "15px"
    }
  },
  tab: {
    fontWeight: 400,
    fontSize: "16px",
    color: DARK_GRAY,
    textTransform: "initial",
    opacity: "unset",
    minWidth: "fit-content",
    "&.Mui-selected": {
      color: PRIMARY_COLOR,
      fontWeight: 700
    }
  },
  cleanFiltersGrid: {
    display: "grid",
    justifyContent: "end"
  },
  cleanFiltersText: {
    color: RED
  },
  cleanFiltersButton: {
    color: PRIMARY_COLOR,
    height: "auto",
    justifyContent: "end",
    width: "inherit"
  },
  exportButton: {
    maxWidth: 140,
    height: 45,
    borderRadius: 4,
    marginLeft: "30px",
    color: GRAY,
    backgroundColor: WHITE,
    borderColor: LIGHT_GRAY,
    "& .MuiTypography-root": {
      color: GRAY
    }
  },
  filtersButton: props => ({
    maxWidth: 120,
    height: 45,
    borderRadius: 4,
    marginLeft: "30px",
    backgroundColor: props.openFilters ? SECONDARY_COLOR : WHITE,
    borderColor: props.openFilters ? SECONDARY_COLOR : LIGHT_GRAY,
    "&:hover": {
      backgroundColor: hexToRgba(props.openFilters ? SECONDARY_COLOR : LIGHT_GRAY, 0.9)
    }
  }),
  filterText: props => ({
    color: props.openFilters ? WHITE : GRAY
  }),
  filterContainer: {
    alignItems: "center"
  },
  filterLabel: {
    color: DARK_GRAY
  },
  loading: {
    display: "flex",
    margin: "auto"
  }
}));

export default ListCarriers;
