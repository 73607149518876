import React, { useState } from 'react';
import { Layout } from '../../layout';
import { Flex, Space, Table, Tabs } from 'antd'
import styled from "@emotion/styled";
import { Box, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { css } from "@emotion/react"
import useUsers from '../Hooks/useUsers';
import { Send, ChevronUp, ChevronDown, Check, X, Users, Edit } from 'react-feather';
import dayjs from 'dayjs';
import HeaderUsersList from './HeaderUsersList';
import { DARK_GRAY, GRAY, PRIMARY_COLOR } from '../../../theme';
import UserInvite from './UserInvite';
import useNewAccess from '../Hooks/useNewAccess';
import NewAccessModal from './NewAccessModal';
import useEditAccess from '../Hooks/useEditAccess';
import EditAccessModal from './EditAccessModal';
import UserService from '../../../services/UserService';
import { handleHomologationStatus } from '../../../shared/utils/Utils';

const UsersList = () => {
    const [expand, setExpand] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const {
        usersYara,
        usersCarrier,
        openFilter,
        filters,
        load,
        handleContactEmailOnChange,
        handleContactNameOnChange,
        handleDataMap,
        handleFilterVisibility,
        handleCNPJOnChange,
        handleCarrierNameOnChange,
        handleHomologationOnchangeFilter,
        handleSuspendedOnchangeFilter,
        handleApplyFilter,
        handleCleanFilter
    } = useUsers();

    const {
        newAccessOpen,
        newAccess,
        debounceFetcher,
        fetching,
        optionsCarriers,
        usersOptions,
        contextHolder,
        apiNotification,
        optionsYaraUsers,
        fetchingYaraID,
        debounceFetcherYaraID,
        handleOnChangeYaraAD,
        handleConfirmYara,
        handleConfirmCarrierAccessType,
        handleOnSelectUserId,
        onCancel,
        handleUserEmail,
        handleUserName,
        handleOnChangeSelectCarrier,
        handleOpenNewAccess,
        handleAccessType
    } = useNewAccess(handleDataMap);

    const {
        editOpen,
        selectedUserEdit,
        editType,
        handleRemoveAccess,
        handleOnConfirmEdit,
        handleOnSelectAccessType,
        handleEditOnChangeEmail,
        handleEditOnChangeName,
        handleEditOpen
    } = useEditAccess(apiNotification, handleDataMap);

    const handleOpenModal = (record) => {
        setSelectedUser(record);
        setTimeout(() => {
            setModalVisible(true);
        }, 0);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const columnsCarrier = [
        {
            title: 'Nome',
            dataIndex: 'userName',
            width: '15%'
        },
        {
            title: 'E-mail',
            dataIndex: 'userEmail',
            width: '20%'
        },
        {
            title: 'CNPJ',
            key: 'cnpj',
            dataIndex: 'carrierCNPJ',
            align: 'center',
            width: '20%',
            render: (text, record) => (
                <Space>
                    <Typography sx={css`font-size: 14px;font-weight: bold;color: #00205B;display:inline;`}>{text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}</Typography>
                    {record.carriers.length > 1 ?
                        <Typography sx={css`font-size: 14px;font-weight: bold;color: #2777B8;display:inline;`}>
                            {"+" + (record.carriers.length - 1)}
                        </Typography> : ''
                    }
                </Space>
            )
        },
        {
            title: 'Transportadora',
            dataIndex: 'carrierName',
        },
        {
            title: 'Status Homologação',
            dataIndex: 'status',
            render: (text, record) => {
                return handleHomologationStatus(record.carriers[0].homologation.homologationStatus.homologationStatusName, record.carriers[0].homologation.homologationCreatedAt)
            }
        },
        {
            title: 'Ações',
            dataIndex: '',
            render: (_txt, record) => <Space>
                <IconButton onClick={() => handleOpenModal(record)}>
                    <Send color='#2777B8' />
                </IconButton>
                <IconButton onClick={() => handleEditOpen(true, record, 2)}>
                    <Edit color='#2777B8' />
                </IconButton>
            </Space>
        }
    ]

    const columnsYara = [
        {
            title: 'Nome',
            dataIndex: 'userName',
            width: '15%'
        },
        {
            title: 'E-mail',
            dataIndex: 'userEmail',
            width: '15%'
        },
        {
            title: 'Tipo de Acesso',
            dataIndex: 'profileName',
            width: '60%'
        },
        {
            title: 'Ações',
            dataIndex: '',
            width: 150,
            fixed: 'right',
            render: (_text, record) => <Space>
                <IconButton onClick={() => handleEditOpen(true, record, 1)}>
                    <Edit color='#2777B8' />
                </IconButton>
            </Space>
        }
    ]

    function handleCarrierBranches(record) {
        if (record !== undefined) {
            if (record.carriers.length <= 1) {
                return 'Sem mais CNPJs';
            } else if (record.carriers.length >= 10) {
                return `${record.carriers.length - 1} CNPJs`;
            } else {
                return `0${record.carriers.length - 1} CNPJs`
            }

        }
        return '';
    }

    function handleDataTable(tabKey) {
        return (
            <DataTable
                loading={load}
                expandable={tabKey === 1 ? {
                    expandedRowRender: (record) => {
                        return (
                            <Flex horizontal gap={'middle'}>
                                <div style={{ width: '15%' }}></div>
                                <div style={{ width: '18%' }}></div>
                                <Grid container spacing={2} sx={css`width: 20%;justify-content:center;`}>
                                    <Grid item>
                                        {record.carriers.filter(e => e.carrierCNPJ !== record.carriers[0].carrierCNPJ).map(el => {
                                            return (
                                                <Grid item xs={24}>
                                                    <Typography sx={css`font-size: 14px;font-weight: bold;color: #666666;`}> {el.carrierCNPJ.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")} </Typography>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid container sx={css`width: 20%;`} rowSpacing={0}>
                                    <Grid item xs={24}>
                                        <Typography sx={css`font-size: 12px;font-weight: 400;color: #666666;`}>CNPJs</Typography>
                                        <Typography sx={css`font-size: 14px;font-weight: 400;color: #0A0A0B;`}>{handleCarrierBranches(record)}</Typography>
                                    </Grid>
                                </Grid>
                                <div style={{ width: 'auto' }}></div>
                                <div style={{ width: 'auto' }}></div>
                                <div style={{ width: 'auto' }}></div>
                            </Flex>
                        )
                    },
                    expandIconAsCell: false,
                    expandIconColumnIndex: 5,
                    expandIcon: ({ expanded, onExpand, record }) => {
                        if (record.carriers.length <= 1) {
                            return (<IconButton sx={css`cursor: not-allowed;`}>
                                <ChevronDown fontSize='medium' color={GRAY} />
                            </IconButton>)
                        }

                        return expanded ? (
                            <IconButton onClick={(e) => {
                                onExpand(record, e)
                                setExpand(false);
                            }}>
                                <ChevronUp fontSize='medium' />
                            </IconButton>
                        ) : (
                            <IconButton onClick={(e) => {
                                onExpand(record, e)
                                setExpand(true);

                            }}>
                                <ChevronDown fontSize='medium' color='#2777B8' />
                            </IconButton>
                        )
                    }
                } : undefined}
                dataSource={tabKey === 1 ? usersCarrier : usersYara}
                columns={tabKey === 1 ? columnsCarrier : columnsYara}
            />
        )
    }

    const handleInvite = async () => {
        try {
            await UserService.inviteUser(selectedUser.id, selectedUser.carriers[0].carrierId).then((response) => {
                if (response === undefined) throw 'falha ao enviar o email';
                apiNotification['success']({
                    message: 'Convite enviado com sucesso.',
                    placement: 'topRight',
                    duration: 4
                });
                handleCloseModal();
            });
        } catch (err) {
            apiNotification['error']({
                message: 'Falha ao enviar o convite.',
                placement: 'topRight',
                duration: 4
            })
        }
    }

    return (
        <Layout showHeader>
            {contextHolder}
            <UsersListContainer>
                <HeaderUsersList
                    open={openFilter}
                    handleOpenFilter={handleFilterVisibility}
                    cnpjValue={filters.cnpjValue}
                    handleCNPJOnChange={handleCNPJOnChange}
                    carrierName={filters.carrierName}
                    handleCarrierNameOnChange={handleCarrierNameOnChange}
                    homologationStatusCheck={filters.homologationStatusCheck}
                    handleHomologationStatusOnChange={handleHomologationOnchangeFilter}
                    suspendedStatusCheck={filters.suspendedStatusCheck}
                    handleSuspendedStatusOnChange={handleSuspendedOnchangeFilter}
                    handleApplyFilter={handleApplyFilter}
                    handleCleanFilter={handleCleanFilter}
                    handleOpenNewAccess={handleOpenNewAccess}
                    isFiltred={filters.filtred}
                    contactName={filters.contactName}
                    handleContactName={handleContactNameOnChange}
                    contactEmail={filters.contactEmail}
                    handleContactEmail={handleContactEmailOnChange}
                />
                <UserListTabs
                    defaultActiveKey='1'
                    items={[
                        {
                            label: 'Transportadoras Cadastradas',
                            key: '1',
                            children: handleDataTable(1),
                        },
                        {
                            label: 'Logística ',
                            key: '2',
                            children: handleDataTable(2)
                        }
                    ]}
                />
            </UsersListContainer>
            <UserInvite
                visible={modalVisible}
                user={selectedUser}
                onClose={handleCloseModal}
                handleInvite={handleInvite}
            />
            <NewAccessModal
                open={newAccessOpen}
                onClose={handleOpenNewAccess}
                handleAccessType={handleAccessType}
                accesssType={newAccess.accessType}
                debounceFetcher={debounceFetcher}
                fetching={fetching}
                options={optionsCarriers}
                value={newAccess.carrierCNPJ}
                handleOnChangeSelectCarrier={handleOnChangeSelectCarrier}
                userName={newAccess.userName}
                userEmail={newAccess.userEmail}
                handleEmail={handleUserEmail}
                handleName={handleUserName}
                onCancel={onCancel}
                usersOptions={usersOptions}
                userId={newAccess.userId}
                handleOnSelectUserId={handleOnSelectUserId}
                handleConfirmCarrierAccessType={handleConfirmCarrierAccessType}
                handleConfirmYara={handleConfirmYara}

                debounceFetcherYaraID={debounceFetcherYaraID}
                fetchingYaraID={fetchingYaraID}
                optionsYaraID={optionsYaraUsers}
                valueYaraID={newAccess.userAD}
                handleOnChangeSelectYaraID={handleOnChangeYaraAD}
            />
            <EditAccessModal
                onClose={handleEditOpen}
                open={editOpen}
                selectedUserEdit={selectedUserEdit}
                editType={editType}
                handleOnChangeEmail={handleEditOnChangeEmail}
                handleOnChangeName={handleEditOnChangeName}
                handleOnSelectAccessType={handleOnSelectAccessType}
                handleOnConfirmEdit={handleOnConfirmEdit}
                handleRemoveAccess={handleRemoveAccess}
            />
        </Layout>
    )
}

const DataTable = styled(Table)`
    th{
        font-weight: 400 !important;
    }
    .ant-table-expanded-row{
        background: #F0EFEF;
    }
`
const UsersListContainer = styled(Box)`
    margin-top: 30px;
    margin-left: 105px;
    margin-right: 10px;
`
const UserListTabs = styled(Tabs)`
    font-weight: 400;
    color: ${DARK_GRAY};
    text-transform: initial;
    opacity: unset;
    min-width: fit-content;

    & .ant-tabs-tab-btn{
        font-size: 16px;
      }

    & .ant-tabs-tab-active {
      font-weight: 700;
      & .ant-tabs-tab-btn{
        color: ${PRIMARY_COLOR} !important;
      }
    }

    & .ant-tabs-ink-bar{
        height: 5px !important;
        border-radius: 3px !important;
    }
`




export default UsersList;