import React, { useEffect, useState } from 'react'
import { BodyDialog, FormCntrolNewAccess, HeaderDialog, NewAccessDialog, NewAccessSelect, TextInput } from './NewAccessModal';
import { Button, Dialog, DialogActions, DialogTitle, FormControl, FormLabel, Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { css } from "@emotion/react";
import styled from '@emotion/styled';
import { Trash2 } from 'react-feather';

const EditAccessModal = ({
    open,
    onClose,
    selectedUserEdit,
    editType,
    handleOnChangeName,
    handleOnChangeEmail,
    handleOnSelectAccessType,
    handleOnConfirmEdit,
    handleRemoveAccess
}) => {
    const [width, setWidth] = useState(window.innerWidth);


    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 769;

    return (
        <EditAccessDialog open={open} fullScreen={isMobile}>
            <HeaderDialog>
                <DialogTitle sx={css` color: white;`}>
                    Editar acesso
                </DialogTitle>
                <Close sx={css`cursor: pointer;color: white;margin-right: 16px;`} onClick={() => onClose(false)} />
            </HeaderDialog>
            <BodyDialog>
                <Grid container spacing={{ xs: 2, md: 1 }} direction="row" alignItems="center" rowSpacing={editType === 2 ? 6 : 2} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {editType === 2 ?
                        <>
                            <Grid item xs={4} sm={8} md={0.7}>
                                <Typography sx={css`color: #0A0A0B;display: inline;font-size: 14px;`}>CNPJ</Typography>
                            </Grid>
                            <Grid item xs={4} sm={8} md={2.6}>
                                <Typography sx={css`color: #00205B;font-weight: bold;display: inline;`}>{selectedUserEdit?.carrierCNPJ?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={8} md={7}>
                                <Typography sx={css`color: #0A0A0B;display: inline;font-size: 14px;`}>{selectedUserEdit.carrierName}</Typography>
                            </Grid>
                        </>
                        : 
                        <Grid item xs={4} sm={8} md={12}>
                        <FormCntrolAccessType component={'fieldset'}>
                            <FormLabel component={'legend'}>Tipo de acesso</FormLabel>
                            <NewAccessSelect onChange={handleOnSelectAccessType} labelInValue placeholder='Selecione' value={selectedUserEdit?.profile?.profileId} options={[{label: "Logistics Analyst", value: 1}, {label: "Full View", value: 2}, {label: "Hiring", value: 4}]} />
                        </FormCntrolAccessType>
                        </Grid>
                    }
                    <Grid item xs={4} sm={4} md={4.5}>
                        <FormCntrolEditAccess component={'fieldset'}>
                            <FormLabel component={'legend'}>Nome</FormLabel>
                            <TextInput placeholder='Digite aqui' value={selectedUserEdit?.userName} onChange={handleOnChangeName}/>
                        </FormCntrolEditAccess>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4.5}>
                        <FormCntrolEditAccess component={'fieldset'}>
                            <FormLabel component={'legend'}>E-mail</FormLabel>
                            <TextInput placeholder='Digite aqui' value={selectedUserEdit?.userEmail} onChange={handleOnChangeEmail}/>
                        </FormCntrolEditAccess>
                    </Grid>
                    <Grid item xs={4} sm={4} md={3}>
                        <DeleteAccessButton onClick={handleRemoveAccess} color='error' variant='outlined' startIcon={<Trash2 />}> Excluir acesso</DeleteAccessButton>
                    </Grid>
                </Grid>
            </BodyDialog>
            <DialogActions>
                <Grid container justifyContent={'left'} direction="row" alignItems="center" spacing={{ xs: 2, md: 7 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={8} sm={7} xl={9} md={9} >
                        <Button color='error' onClick={() => onClose(false)} > Cancelar</Button>
                    </Grid>
                    <Grid item xs={8} sm={7} xl={9} md={3}>
                        <Button variant='contained' color='primary' onClick={handleOnConfirmEdit}>Confirmar alterações</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </EditAccessDialog>
    )
}

const EditAccessDialog = styled(Dialog)`
    & .MuiPaper-root{    
        z-index: 20;
        width: 60%;
        min-width: 60%;
        height: 35%;
        min-height: 20%;
        overflow-y: auto;
        @media only screen and (max-width: 1700px){
            height: 45% !important;
        }

        @media screen and (max-width: 768px){
            height: 60% !important;
        }
    }
`
const FormCntrolEditAccess = styled(FormControl)`
    width: 100% !important;
`
const FormCntrolAccessType = styled(FormControl)`
    width: 30% ;
    @media screen and (max-width: 768px){
        width: 100% !important;
    }
`

const DeleteAccessButton = styled(Button)`
    height: 56px;
    border-radius: 10px;
    margin-top: 23px;
`

export default EditAccessModal;