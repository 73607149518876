import { Button, FormControl, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { GRAY, PRIMARY_COLOR, RED, SECONDARY_COLOR, WHITE } from "../../theme";
import { Stack } from "@mui/system";
import styled from "@emotion/styled";
import Logo from "../../shared/img/Logo.svg";
import LoginManager from "../LoginManager";
import { useDispatch } from "react-redux";
import * as userActions from "../../store/UserStore";
import { Alert, message, notification } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../../shared/utils/Constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import bcrypt from "bcryptjs-react";
import './styleExternalLogin.css'
import AuthenticationService from "../../services/AuthenticationService";

const ExternalLogin = () => {
    const [userName, setUserName] = useState();
    const [pass, setPass] = useState();
    const [errName, setErrName] = useState(false);
    const [errPass, setErrPass] = useState(false);
    const dispatch = useDispatch();
    const [apiNotification, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const [messageApi, contextHolderM] = message.useMessage();
    const [searchParams, setSearchParams] = useSearchParams();
    

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleUseName = (e) => {
        setUserName(e.target.value)
    }

    const handlePassword = (e) => {
        setPass(e.target.value);
    }

    async function hashPassword(password) {
        const encoder = new TextEncoder();
        const data = encoder.encode(password);
        const hash = await window.crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hash)); // Converte para um array de números
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Converte números para strings hexadecimais

        return hashHex;
    }

    const handleExternalLogin = async () => {
        if (!userName) {
            setErrName(true);
        }
        if (!pass) {
            setErrPass(true);
        }
        let user;
        try {

            await hashPassword(pass).then(async hashHex => user = await LoginManager.externalAuthenticate(userName, hashHex));
            if (user?.requestError) {
                throw 'Credenciais invalidas'
            }

            if(user === undefined){
                setErrPass(true);
                throw 'Verifique se seu email e senha estao corretos!'
            }

            await dispatch(userActions.updateUser({
                ...user,
                profile: user?.profile?.profileName
            }))
            await dispatch(userActions.authenticateUser());
            await dispatch(userActions.externalUser());
            sessionStorage.setItem('externalUser', true)
        } catch (err) {
            apiNotification['error']({
                message: err,
                placement: 'topRight',
                duration: 30
            })
        } finally {
            if (!user?.requestError && user !== undefined) {
                navigate(routes.listCarriers);
            }
        }

    }

    useEffect(() => {
        if (location.state?.registerSaved) {
            messageApi.open({
                type: 'info',
                content: 'Enviamos um e-mail para confirmar seus dados!',
                duration: 10,
            });
        }
    }, location.state?.registerSaved)

    useEffect(() => {
        async function getCodeConfrimation() {
            const code = searchParams.get('code');
            if (code) {
                await AuthenticationService.emailConfirmation(code).then(resp => {
                    if (resp.data) {
                        messageApi.open({
                            type: 'success',
                            content: 'Dados confirmados e senha cadastrada com sucesso!',
                            duration: 10,
                        });
                    }
                })
            }
        }

        getCodeConfrimation();

    }, [searchParams])

    return <Grid xs={12} container sx={css`height: 100vh;`}>
        {contextHolder}
        {contextHolderM}
        <Grid item xs={12} sx={css`display: flex; margin: auto; align-items: center;`}>
            <Grid item xs={12} container lg={12} className="pl-6 pr-6 pb-12 pt-12">
                <Grid item xs={12} alignContent={"center"}>
                    <Paper elevation={3} square={false} align="center" sx={css`width: 30% !important;padding: 20px;margin-left: 35%;`}>
                        <Stack direction={"column"} spacing={3}>
                            <LogoImg src={Logo} alt="Logo Yara" align="center" />
                            <div>
                                <Typography variant="h5" sx={css`color:${PRIMARY_COLOR};font-family: YaraMax;`}>Homologação Digital</Typography>
                                <Typography variant="h5" sx={css`color:${PRIMARY_COLOR};font-family: YaraMax;`}>Login Externo</Typography>
                            </div>
                            <div>
                                {errName && <Typography variant="subtitle1" sx={css`font-size: 14px;color:${RED};font-family: YaraMax;display:inline;`}>Nome de usuario invalido.</Typography>}
                                {errPass && <Typography variant="subtitle1" sx={css`font-size: 14px;color:${RED};font-family: YaraMax;display:inline;`}>&ensp;Senha invalida.</Typography>}
                            </div>
                            <TextField error={errName} placeholder="Email" onChange={handleUseName} />
                            <FormControl>
                                <TextField
                                    error={errPass}
                                    placeholder="Senha"
                                    onChange={handlePassword}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}

                                />
                                <FormHelperText id="forget-password" sx={css`color: ${SECONDARY_COLOR};margin-left: 70%;font-weight: bold;cursor: pointer;`} onClick={() => navigate(routes.forgetPassword)}>Esqueceu sua senha?</FormHelperText>
                            </FormControl>
                            <SingupButton onClick={handleExternalLogin}>Entrar</SingupButton>
                        </Stack>
                        <br />
                        <br />
                        <FormControl component={'fieldset'} sx={css`width: 100%;`}>
                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;font-weight: bold;color: black;`}>Primeiro acesso?</FormLabel>
                            <NewAccessButton variant="outlined" onClick={() => navigate(routes.registerPassword)}>Cadastre sua senha</NewAccessButton>
                        </FormControl>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export const SingupButton = styled(Button)`
    background-color: ${PRIMARY_COLOR};
    color: white;
    border-radius: 10px;
    width: 100%;
    height: 46px;
    font-family: YaraMax;
    &.Mui-disabled {
      color: ${WHITE};
      background-color: ${GRAY}
    }
    &:hover: {
      color: ${SECONDARY_COLOR}
    }
`
export const LogoImg = styled.img`
  margin: auto;
  display: flex;
  width: 110px;
  margin-left: calc(50% - (110px / 2))!important;
`

export const NewAccessButton = styled(Button)`
    width: 100%;
    height: 45px;
    border-radius: 10px;
    margin-left: auto;
    background-color: WHITE;
    font-family: YaraMax;
    text-transform: none;
    font-weight: bold;
`

export default ExternalLogin