import { useEffect, useState } from "react";
import { ContactCarreirProfileType, InputVerifyDataTable } from "../../../shared/utils/enum";
import { cpfValidate } from "../../../shared/components/DataTableAddItem/DataTableAddItem";
import UserService from "../../../services/UserService";
import { configModalConfirm } from "../views/CarrierModalEdit";

const useLegalRepresentativeContact = (contacts, open, apiNotification, modal) => {
  const [legalRepresentativeContactsData, setLegalRepresentativeContactsData] = useState([]);
  const [deletedRepresentativeContact, setdeletedRepresentativeContact] = useState([]);

  useEffect(() => {
    if (contacts && !open)
      setLegalRepresentativeContactsData(
        contacts
          ?.filter(
            el => el.profile.profileType.profileTypeId === ContactCarreirProfileType.REPRESENTATIVE
          )
          .map(el => {
            return {
              ...el,
              personalDocument: el.informations?.userInformationDocument
            };
          })
      );
  }, [contacts, open]);

    const handleFormatCPF = cpf => {
    if (cpfValidate.test(cpf)) {
      return cpf
        .replace(/\D/g, "")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1");
    }

    return cpf;
  };

  const defaultLegalRepresentativeContactsColumns = [
    {
      title: "Nome",
      dataIndex: "userName",
      width: "30%",
      editable: true
    },
    {
      title: "E-mail",
      dataIndex: "userEmail",
      width: "30%",
      editable: true,
      inputVerify: InputVerifyDataTable.EMAIL
    },
    {
      title: "CPF",
      dataIndex: "personalDocument",
      width: "25%",
      editable: true,
      inputVerify: InputVerifyDataTable.CPF,
      render: text => handleFormatCPF(text)
    }
  ];

  const handleDeleteLegalRepresentativeContact = id => {
    const newData = legalRepresentativeContactsData.filter(item => item.id !== id);
    setdeletedRepresentativeContact([
      ...deletedRepresentativeContact,
      legalRepresentativeContactsData.find(item => item.id === id)
    ]);
    setLegalRepresentativeContactsData([...newData]);
  };

  const handleAddLegalRepresentativeContact = () => {
    if (legalRepresentativeContactsData.length > 0) {
      const hightId = Math.max(...legalRepresentativeContactsData.map(item => item.id));
      const newData = {
        id: hightId + 1,
        userName: "contato",
        userEmail: "contato@transportadora.com",
        personalDocument: "00000000",
        isNewContact: true,
        profile: {
          profileType: { profileTypeId: ContactCarreirProfileType.REPRESENTATIVE }
        }
      };
      setLegalRepresentativeContactsData([newData, ...legalRepresentativeContactsData]);
    } else {
      const newData = {
        id: 1,
        userName: "contato",
        userEmail: "contato@transportadora.com",
        personalDocument: "00000000",
        isNewContact: true,
        profile: {
          profileType: { profileTypeId: ContactCarreirProfileType.REPRESENTATIVE }
        }
      };
      setLegalRepresentativeContactsData([newData]);
    }
  };

  const handleSaveLegalRepresentativeContact = async row => {
    const emailAlreadyExists = legalRepresentativeContactsData.filter(el => el.userEmail === row.userEmail && el.userEmail?.length > 0 && el.id !== row.id);

    if (emailAlreadyExists.length > 0) {
      apiNotification['warning']({
        message: 'Atenção ao alterar!',
        description: "Esse e-mail já está vinculado a outro usuário, gerencie pelo Controle de Acessos.",
        placement: 'topRight',
        duration: 5
      });

      return;
    }

    if (row.userEmail) {
      const response = await UserService.getUserByEmail(row.userEmail);
      if (response?.data !== null && response?.data?.userActive) {
        if (response.data.id !== row.id && !row.isNewContact) {
          apiNotification['warning']({
            message: 'Atenção ao alterar!',
            description: "Esse e-mail já está vinculado a outro usuário, gerencie pelo Controle de Acessos.",
            placement: 'topRight',
            duration: 5
          });
        }

        if (response.data?.id === row?.id && !row.isNewContact && response.data?.id !== null) {
          const confrimModal = await modal.confirm(configModalConfirm);
          if (
            !confrimModal
          ) {
            return;
          }

          row.userName = response.data.userName;
          row.userEmail = response.data.userEmail;
          row.personalDocument = response.data.informations?.userInformationDocument;
        }
      }
    }
    const newData = [...legalRepresentativeContactsData];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
      edited: item.isNewContact ? false : true
    });
    setLegalRepresentativeContactsData(newData);
  };

  return {
    defaultLegalRepresentativeContactsColumns,
    legalRepresentativeContactsData,
    deletedRepresentativeContact,
    handleDeleteLegalRepresentativeContact,
    handleAddLegalRepresentativeContact,
    handleSaveLegalRepresentativeContact
  };
};

export default useLegalRepresentativeContact;
