import CarrierService from "../../services/CarrierService";
import HomologationStatusService from "../../services/HomologationStatusService";
import ProductGroupService from "../../services/ProductGroupService";
import RegionalService from "../../services/RegionalService";

class ListCarriersManager {
  async listCarriers(filters) {
    const response = await CarrierService.getCarriersList(filters);
    return response && response.data;
  }

  async loadFilters() {
    const homologationStatus = await HomologationStatusService.getHomologationStatusList();
    const prodGroups = await ProductGroupService.getProductGroupList();
    const regionals = await RegionalService.getRegionalList();
    const towers = [];

    for (let r in regionals.data) {
      for (let t in regionals.data[r].towers) {
        towers.push(regionals.data[r].towers[t])
      }
    }

    if (homologationStatus && prodGroups && regionals)
      return {
        status: homologationStatus.data,
        productgroups: prodGroups.data,
        regionals: regionals.data,
        towers: towers
      };
  }
}

export default new ListCarriersManager();
