export default class AxiosCache {
  constructor() {
    this.data = {};
    window.cacheTeste = this;
  }

  put(key, value) {
    this.data[key] = value;
  }

  get(key) {
    return this.data[key];
  }

  getKey(url, method, params) {
    return method + " " + url + stringifyQueryString(params);
  }
}

function stringifyQueryString(params) {
  if (!params) return "";
  let querystringParts = [];

  for (let p in params) {
    if (params[p] !== null && params[p] !== undefined) {
      querystringParts.push(p + "=" + params[p]);
    }
  }

  if (querystringParts.length === 0) return "";

  const sortedQueryStringParts = querystringParts.sort().join("&");

  return "?" + sortedQueryStringParts;
}
