import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Button, Typography } from "@mui/material";
import Logo from "../../shared/img/Logo.svg";
import { routes } from "../../shared/utils/Constants";
import { Navigate, useNavigate } from "react-router-dom";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import store from "../../store";
import { verifyTokenIsValid } from "../../shared/utils/RequestUtil";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { PRIMARY_COLOR, SECONDARY_COLOR, WHITE } from "../../theme";
/** *///
const Login = props => {
  const { user, location } = props;
  const navigate = useNavigate();

  const previousPath = location?.state?.from?.pathname;

  useEffect(() => {
    if (previousPath && previousPath !== routes.root)
      sessionStorage.setItem("redirect", previousPath);
  }, [previousPath]);

  verifyTokenIsValid();

  const redirectTo = () => {
    if (previousPath) return previousPath;

    return routes.listCarriers;
  };

  const handleExternalLogin = () =>{
    navigate(routes.exLogIn);
  }

  return user.isAuthenticated ? (
    <Navigate to={redirectTo()} />
  ) : (
    <AzureAD provider={authProvider} reduxStore={store}>
      {({ login, _logout, _authenticationState, _error, _accountInfo }) => {
        return (
          <Grid xs={12} container sx={css`height: 100vh;`}>
            <Grid item xs={12} sx={css`display: flex; margin: auto; align-items: center;`}>
              <Grid item xs={12} container lg={12} className="pl-6 pr-6 pb-12 pt-12">
                <Grid item xs={12}>
                  <LogoImg src={Logo} alt="Logo Yara" align="center" />
                </Grid>
                <Grid item xs={12} className="pb-6 pt-6">
                  <Typography variant="h3" align="center" sx={css`font-family: YaraMax;`}>
                    Homologação
                    <br />
                    Digital
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <YaraButton
                    variant="outlined"
                    align="center"
                    onClick={login}
                  >
                    ENTRAR COM E-MAIL YARA
                  </YaraButton>
                </Grid>
                <Grid item xs={12}>
                  <NonYaraButton
                    variant="outlined"
                    onClick={handleExternalLogin}
                  >
                    ENTRAR COM OUTRO EMAIL
                  </NonYaraButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </AzureAD>
  );
};

const LogoImg = styled.img`
  margin: auto;
  display: flex;
  width: 110px;
`
const YaraButton = styled(Button)`
  color: ${WHITE};
  font-family: YaraMax;
  background-Color: ${PRIMARY_COLOR};
  width: 20%;
  height: 56px;
  margin-left: calc(50% - 10%);
  border-radius: 10px;
  &:hover {
  color: ${WHITE};
  background-Color: ${PRIMARY_COLOR};
  }
`

const NonYaraButton = styled(Button)`
  color: ${SECONDARY_COLOR};
  font-family: YaraMax;
  width: 20%;
  height: 56px;
  margin-top: 1%;
  margin-left: calc(50% - 10%);
  border-radius: 10px;
`
const mapStateToProps = state => {
  return {
    user: state.user,
    screenDimensions: {
      height: state.window.height,
      width: state.window.width
    }
  };
};

export default connect(mapStateToProps, null)(Login);
