import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DeniedAccessIcon from "../img/DeniedAccessIcon.svg";
import { Navigate } from "react-router-dom";
import { routes } from "../utils/Constants";

const PageNotFound = () => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(null);

  const redirectToRoot = () => setRedirect(<Navigate to={routes.root} />);

  return (
    <Grid container className="pl-4 pt-8 pr-4 pb-4">
      <Grid item xs={12} className={`${classes.spacingImage} pb-8`}>
        <img src={DeniedAccessIcon} alt="Acesso Negado" className={classes.image} />
      </Grid>
      <Grid item xs={12} className="pb-5">
        <Typography variant="h3" align="center">
          Página inexistente!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" align="center" color="secondary">
          Desculpe, a página solicitada não existe no sistema.
        </Typography>
      </Grid>
      <Grid item xs={12} align="center" className="mt-5">
        <Button
          onClick={redirectToRoot}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          TENTAR NOVAMENTE
        </Button>
      </Grid>
      {redirect}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  image: {
    display: "flex",
    margin: "auto",
    width: "160px"
  },
  spacingImage: {
    paddingTop: theme.spacing(8)
  },
  button: {
    width: "250px"
  }
}));

export default PageNotFound;
