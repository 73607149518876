import React, { useState } from "react";
import Logo from "../../shared/img/Logo.svg";
import { css } from "@emotion/react";
import { FormControl, FormLabel, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { PRIMARY_COLOR } from "../../theme";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { NewAccessButton, SingupButton, salt } from "./ExternalLogin";
import { useNavigate } from "react-router-dom";
import { routes } from "../../shared/utils/Constants";
import AuthenticationService from "../../services/AuthenticationService";
import { Spin } from "antd";
import bcrypt from "bcryptjs-react";


export const verifyPassword = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/);

const RegisterPassword = () => {
    const [userName, setUserName] = useState();
    const [pass, setPass] = useState();
    const [confirmPass, setConfirmPass] = useState();
    const [errName, setErrName] = useState(false);
    const [errPass, setErrPass] = useState(false);
    const [errConfirmPass, setErrConfirmPass] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [load, setLoad] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleUseName = (e) => {
        setUserName(e.target.value)
    }

    const handlePassword = (e) => {
        setPass(e.target.value);
        if (!verifyPassword.test(e.target.value)) {
            setErrPass(true)
        } else {
            setErrPass(false)
        }
    }

    const handleConfirmPassword = (e) => {
        setConfirmPass(e.target.value);
        if (!(pass === e.target.value)) {
            setErrConfirmPass(true)
        } else {
            setErrConfirmPass(false)
        }
    }

    const verifyEmail = async () => {
        if (userName?.length > 0) await AuthenticationService.verifyEmailToPasswordRegistration(userName).then(resp => {
            if (!resp?.data) setErrName(true)
            else setErrName(false)
        })
    }

    const verifyCanSave = () => {
        if (!errName && !errPass && !errConfirmPass && userName && pass && confirmPass && pass === confirmPass) {
            return false
        }
        return true
    }

    async function hashPassword(password) {
        const encoder = new TextEncoder();
        const data = encoder.encode(password);
        const hash = await window.crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hash)); // Converte para um array de números
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Converte números para strings hexadecimais

        return hashHex;
    }


    const savePass = async () => {
        setLoad(true);

        await hashPassword(pass).then(async hashHex => {
            await AuthenticationService.registerNewPassword(userName, hashHex).finally(() => {
                setTimeout(() => {
                    navigate(routes.exLogIn, {
                        state: {
                            registerSaved: true
                        }
                    })
                }, 5000)
            })
        });

    }

    return (
        <Spin fullscreen spinning={load}>
            <Grid xs={12} container sx={css`height: 100vh;`}>
                <Grid item xs={12} sx={css`display: flex; margin: auto; align-items: center;`}>
                    <Grid item xs={12} container lg={12} className="pl-6 pr-6 pb-12 pt-12">
                        <Grid item xs={12} alignContent={"center"}>
                            <Paper elevation={3} square={false} align="center" sx={css`width: 30%;padding: 20px;margin-left: 35%;`}>
                                <Stack direction={"column"} spacing={3}>
                                    <LogoImg src={Logo} alt="Logo Yara" align="center" />
                                    <div>
                                        <Typography variant="h5" sx={css`color:${PRIMARY_COLOR};font-family: YaraMax;`}>Homologação Digital</Typography>
                                        <Typography variant="h5" sx={css`color:${PRIMARY_COLOR};font-family: YaraMax;`}>Cadastro de senha</Typography>
                                    </div>
                                    <div>
                                    </div>
                                    <TextField onBlur={verifyEmail} error={errName} helperText={errName ? 'E-mail não encontrado no nosso pré-cadastro' : ''} placeholder="Email" onChange={handleUseName} />
                                    <TextField
                                        error={errPass}
                                        placeholder="Senha"
                                        onChange={handlePassword}
                                        type={showPassword ? 'text' : 'password'}
                                        helperText={errPass ? 'Senha não está forte o suficiente. Precisa ter 8 characteres, conter pelo menos 1 letra maiuscula, 1 numero e 1 caracter especial.' : ''}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>

                                        }}
                                    />
                                    <TextField
                                        error={errConfirmPass}
                                        placeholder="Confirmar Senha"
                                        onChange={handleConfirmPassword}
                                        type={showPassword ? 'text' : 'password'}
                                        helperText={errConfirmPass ? 'Senhas não coincidem!' : ''}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>

                                        }}
                                    />
                                    <SingupButton disabled={verifyCanSave()} onClick={savePass}>Salvar</SingupButton>
                                </Stack>
                                <br />
                                <br />
                                <FormControl component={'fieldset'} sx={css`width: 100%;`}>
                                    <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;font-weight: bold;color: black;margin-bottom: 15px;`}>Já tem senha cadastrada?</FormLabel>
                                    <NewAccessButton variant="outlined" onClick={() => navigate(routes.exLogIn)}>Fazer login</NewAccessButton>
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Spin>
    )
}

const LogoImg = styled.img`
  margin: auto;
  display: flex;
  width: 110px;
  margin-left: calc(50% - (110px / 2))!important;
`


export default RegisterPassword;
